import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PageService} from "../../../pages/services/pages.service";
import {WidgetsService} from "../../../pages/services/widgets.service";
import {ToastService} from "../../../../services/toast/toast.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {GeneralsettingsService} from "../../services/generalsettings.service";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-setting-form',
  templateUrl: './setting-form.component.html',
  styleUrls: ['./setting-form.component.scss']
})
export class SettingFormComponent implements OnInit{
  @Input() setting: any;
  @Input() locales: any;
  pageFormGroup!: FormGroup;
  titleFormArray!: FormArray;

  constructor(
    private pageService: PageService,
    private GeneralsettingsService: GeneralsettingsService,
    private widgetsService: WidgetsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private toastService: ToastService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
  ) {
  }

  ngOnInit(): void {
    this.titleFormArray = this.formBuilder.array(
      this.locales.map((locale: any) => this.createTitleFormGroup(locale))
    );

    this.pageFormGroup = this.formBuilder.group({
      id: [null],
      key: [null, Validators.required],
      uniqueValue: [''],
      hasTranslation: [''],
      titleForm: this.titleFormArray,
    });

    if (this.setting) {

      if(this.setting.hasTranslation) {
        this.setting.translations.forEach((translation: any) => {
          const index = this.locales.findIndex((locale: any) => locale === translation.locale);
          if (index !== -1) {
            this.titleFormArray.at(index).patchValue(translation);
          }
        });
      }

      this.pageFormGroup.patchValue({
        id: this.setting.id,
        key: this.setting.key,
        uniqueValue: this.setting.uniqueValue,
        hasTranslation: this.setting.hasTranslation
      });
    }
  }


  createTitleFormGroup(locale: any): FormGroup {

    if(this.pageFormGroup?.get('hasTranslation')?.value) {

      return this.formBuilder.group({
        id: [''],
        value: ['', Validators.required],
        locale: [locale, Validators.required],
      });
    } else {
      return this.formBuilder.group({
        id: [''],
        value: [''],
        locale: [locale],
      });
    }
  }


  getTitleFormControl(locale: string, fieldName: string): FormControl {
    const formGroup = this.titleFormArray.controls.find(control => control.get('locale')?.value === locale) as FormGroup;
    if (formGroup) {
      const formControl = formGroup.get(fieldName);
      if (formControl instanceof FormControl) {
        return formControl;
      }
    }
    // Gérer l'erreur ici, par exemple retourner un FormControl vide ou lever une exception
    return new FormControl();
  }



  onSubmit(): void {
    if (this.pageFormGroup.valid) {
      const pageData = this.pageFormGroup.value;

      if(this.pageFormGroup?.get('id')?.value) {
        this.GeneralsettingsService.update(pageData).subscribe(
          response => {

            this.drawerRef.close();
          },
          error => {
            // Gérer les erreurs
            console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
          }
        );
      } else {
        this.GeneralsettingsService.create(pageData).subscribe(
          response => {
            this.drawerRef.close();
          },
          error => {
            // Gérer les erreurs
            console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
          }
        );
      }
    } else {
      // Le formulaire n'est pas valide, affichez un message d'erreur ou prenez d'autres mesures nécessaires
      console.error('Le formulaire est invalide. Veuillez vérifier les champs et réessayer.');
    }
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight + 2) + 'px';
  }


  getTitleFormGroup(index: any): FormGroup {
    return this.titleFormArray.at(index) as FormGroup;
  }

  isFormValidForLocale(formGroup: FormGroup): boolean {
    return formGroup.valid; // Vous pouvez ajouter des conditions supplémentaires si nécessaire
  }

  onTranslationChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;



    if (isChecked) {
      this.titleFormArray = this.formBuilder.array(
        this.locales.map((locale: any) => this.createTitleFormGroup(locale))
      );
      this.pageFormGroup.setControl('titleForm', this.titleFormArray);
      // Logique à exécuter lorsque la case à cocher est cochée
      console.log("Checkbox is checked.");
    } else {
      this.pageFormGroup.removeControl('titleForm');
      // Logique à exécuter lorsque la case à cocher est décochée
      console.log("Checkbox is unchecked.");
    }
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Would you like to delete this setting?',
        text: 'This action is irreversible and will permanently delete the setting.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {

        this.GeneralsettingsService.delete(this.setting?.id).subscribe(
          response => {

            this.drawerRef.close();
          },
          error => {
            // Gérer les erreurs
            console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
          }
        );
      }
    });


  }
}
