<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">


    <nb-action>
      <h1 class="page-title">
        <nb-icon icon="settings-2-outline"></nb-icon>

        General settings
      </h1>
    </nb-action>


      <nb-action class="action-right">
        <button nbButton status="primary" class="btn-ico-w" (click)="edit(null)">
          <nb-icon icon="plus-outline"></nb-icon>
          Create new setting
        </button>
      </nb-action>


  </nb-actions>


</nb-layout-header>


<div class="p-20">

  <nz-breadcrumb class="breadcrumbs">
    <nz-breadcrumb-item>
      <a [routerLink]="['../../']">Dashboard</a>
    </nz-breadcrumb-item>

    <nz-breadcrumb-item>
      <a [routerLink]="['../']">Settings</a>
    </nz-breadcrumb-item>

    <nz-breadcrumb-item><strong>General settings</strong></nz-breadcrumb-item>
  </nz-breadcrumb>

  <table class="table table-bordered">
    <thead>
    <tr>
      <th>Key</th>
      <th>Value (Default)</th>
      <th>Translations</th>
      <th>Action</th> <!-- Ajout de la colonne pour l'action -->
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let setting of settings">
      <tr *ngIf="setting.hasTranslation">

        <td><code>{{ setting.key }}</code></td>
        <td>{{ setting.value | slice:0:50 }}</td>
        <td>
          <ul>
            <li *ngFor="let translation of setting.translations">
              {{ translation.locale }}: {{ translation.value }}
            </li>
          </ul>
        </td>
        <!-- Ajout du lien pour l'action de modification -->
        <td>
          <button nbButton size="small" (click)="edit(setting)" style="margin-right:5px;">
            <nb-icon icon="edit-outline"></nb-icon>
          </button>
        </td>
      </tr>
      <!-- Si le setting n'a pas de translations, afficher seulement s'il a des translations ou s'il n'a pas de flag hasTranslation -->
      <tr *ngIf="!setting.hasTranslation">
        <td><code>{{ setting.key }}</code></td>
        <td>{{ setting.uniqueValue | slice:0:50 }}</td>
        <td>Unique value</td>
        <!-- Ajout du lien pour l'action de modification -->
        <td>
          <button nbButton size="small" (click)="edit(setting)" style="margin-right:5px;">
            <nb-icon icon="edit-outline"></nb-icon>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>


</div>
