import {Component, OnInit} from '@angular/core';
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";
import {EChartsOption} from "echarts/types/dist/echarts";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";

@Component({
  selector: 'app-global-dashboard',
  templateUrl: './global-dashboard.component.html',
  styleUrls: ['./global-dashboard.component.scss']
})
export class GlobalDashboardComponent extends Share implements OnInit{

  chartOption!: EChartsOption;
  chartOptionProperties!: EChartsOption;
  chartOptionLocation!: EChartsOption;
  chartOptionContactRequest!: EChartsOption;

  IS_REAL_ESTATE: any;
  ENABLE_AFTER_BEFORE: any;


  constructor(
    private configurationService: GetConfigurationService,
    deviceService: DeviceDetectorService,
  ) {
    super('Child', deviceService);
    this.refreshConfiguration();

  }

  ngOnInit(): void {
    // Appelez la fonction pour créer les options du graphique
  }


  createChartOption(): void {
    this.chartOption = {

      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      series: [
        {
          name: 'Results',
          type: 'pie',
          radius: '90%',
          data: Object.entries(this.addressCategories).map(([name, value]) => ({ value: value as number, name })),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    this.chartOptionProperties = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: this.propertiesCategoriesCount.map((category:any) => category.name)
      },
      series: [{
        name: 'Number of properties',
        type: 'bar',
        data: this.propertiesCategoriesCount.map((category:any) => parseInt(category.properties_count)),
        itemStyle: {
          // Définissez les couleurs individuelles pour chaque barre
          color: function(params: any) {
            const colors = ['#5470C6', '#91CC75', '#FAC858', '#EE6666', '#73C0DE', '#3BA272', '#FC8452', '#9A60B4'];
            return colors[params.dataIndex];
          }
        }
      }]
    };


    this.chartOptionLocation = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      series: [{
        name: 'Nombre de propriétés',
        type: 'pie',
        radius: '90%', // Ajustez la taille du camembert selon vos préférences
        data: this.locationsProperties.map((location: any, index: number) => ({
          value: parseInt(location.properties_count),
          name: location.name,
          itemStyle: {
            color: this.generateColors(this.locationsProperties.length)[index]
          }
        }))
      }]
    };


    this.chartOptionContactRequest = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      series: [{
        name: 'Contact requests',
        type: 'pie',
        radius: '90%', // Ajustez la taille du camembert selon vos préférences
        data: this.contacts_request_count.map((location: any, index: number) => ({
          value: parseInt(location.count),
          name: location.site_url,
          itemStyle: {
            color: this.generateColors(this.locationsProperties.length)[index]
          }
        }))
      }]
    };



  }

  generateColors(count: number): string[] {
    // Palette de couleurs prédéfinie
    const colors = ['#5470C6', '#91CC75', '#FAC858', '#EE6666', '#73C0DE', '#3BA272', '#FC8452', '#9A60B4'];

    // Générer les couleurs en boucle
    const generatedColors: string[] = [];
    for (let i = 0; i < count; i++) {
      generatedColors.push(colors[i % colors.length]); // Répétez les couleurs si nécessaire
    }

    return generatedColors;
  }

  public addressCategories!:any;
  public propertiesCategoriesCount!:any;
  public locationsProperties!:any;
  public contacts_request_count!:any;

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.addressCategories = datas?.data?.addressCategories;
      this.propertiesCategoriesCount = datas?.data?.propertiesCategoriesCount;
      this.locationsProperties = datas?.data?.locationsProperties;
      this.contacts_request_count = datas?.data?.contacts_request_count;
      this.IS_REAL_ESTATE = datas?.data?.IS_REAL_ESTATE;
      this.ENABLE_AFTER_BEFORE = datas?.data?.ENABLE_AFTER_BEFORE;
      this.createChartOption();


    });
  }

}
