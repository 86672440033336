<form [formGroup]="redirectFormGroup" (ngSubmit)="onSubmit()" >
  <nb-card>
    <nb-card-body>

      <div class="row">
        <div class="col-12">


          <div class="mb-3" style="margin-top: 20px;" >
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="original_url" id="original_url"
                     placeholder="Value" required>
              <label for="original_url">Original Url*</label>
            </div>
          </div>


          <div class="mb-3" style="margin-top: 20px;" >
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="redirect_url" id="redirect_url"
                     placeholder="Value" required>
              <label for="redirect_url">Redirect to*</label>
            </div>
          </div>


        </div>

      </div>

    </nb-card-body>


    <nb-card>
      <nb-card-footer>

        <div class="row">

          <div class="col-6">
            <button nbButton status="danger" class="btn-ico-w" type="button" (click)="delete()" *ngIf="id">
              <nb-icon icon="trash-2-outline"></nb-icon>
              <span>Delete</span>
            </button>
          </div>
          <div class="col-6" style="text-align:right">

            <button nbButton status="primary" class="btn-ico-w" [disabled]="!redirectFormGroup.valid">
              <nb-icon icon="save-outline"></nb-icon>
              <span>Save</span>
            </button>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>

  </nb-card>

</form>
