<nb-layout-header subheader class="action-nav">
  <nb-action>
    <nb-select [(ngModel)]="selectedGroup" placeholder="All" class="date-group-select">
      <nb-option value="">All</nb-option>
      <nb-option *ngFor="let group of events | keyvalue"
                 [value]="group.key">{{ datePipe.transform(group.key, 'dd/MM/yyyy') }} ({{group.value.length}})</nb-option>
    </nb-select>
  </nb-action>

  <nb-action style="margin-left:15px;">
    <nb-checkbox *ngIf="!selectedGroup" [(ngModel)]="showFutureEvents">Only Future Events</nb-checkbox>
  </nb-action>


  <nb-actions size="small" style="margin-left: auto">

    <nb-action class="action-right">
      <button nbButton status="primary" (click)="createEvent()" class="btn-ico-w">
        <nb-icon icon="plus-outline"></nb-icon>
        Add event
      </button>
    </nb-action>
  </nb-actions>

</nb-layout-header>


<div *ngIf="!hasEvents()" class="empty text-center p-5">
  No event found!
</div>


<div class="timeline-group">
  <div *ngIf="selectedGroup" class="timeline-group">
    <nb-card>
      <nb-card-header>{{ datePipe.transform(selectedGroup, 'dd/MM/yyyy') }}</nb-card-header>
      <nb-card-body >
        <div class="timeline-events">
          <div *ngFor="let event of events[selectedGroup]" class="timeline-event">
            <nb-card>
              <nb-card-header (click)="openEvent(event)">{{ datePipe.transform(event.date_time, 'HH:mm') }} | {{ event.title }}</nb-card-header>
              <nb-card-body class="p-0">

                <div class="property-pophover" *ngIf="event?.property?.id" (click)="openEvent(event)">
                  <div class="row g-0">
                    <div class="col-4">
                      <img src="{{ event?.property?.photo?.full_url }}" style="width:100%"/>
                    </div>

                    <div class="col-8">

                      <div style="padding:5px;">
                        <nb-tag-list size="tiny">
                          <nb-tag size="tiny" status="basic" appearance="filled" text="Pending"
                                  *ngIf="event?.property?.is_available == 0"></nb-tag>
                          <nb-tag size="tiny" status="success" appearance="filled" text="Available"
                                  *ngIf="event?.property?.is_available == 1"></nb-tag>
                          <nb-tag size="tiny" status="danger" appearance="filled" text="Sold"
                                  *ngIf="event?.property?.is_available == 2"></nb-tag>
                          <nb-tag size="tiny" status="basic" appearance="filled" text="Out of market"
                                  *ngIf="event?.property?.is_available == 3"></nb-tag>
                        </nb-tag-list>

                        <div>
                          <strong>Ref:</strong> {{event?.property?.reference}}
                        </div>

                        <div>
                          <span
                            *ngIf="event?.property?.price"><strong>Price: </strong>{{ event?.property?.price | currencyFormat }}</span>
                        </div>

                        <div>
                          <span
                            *ngIf="event?.property?.bathrooms"><strong>Location:</strong> {{ event?.property?.location?.name }}</span>
                        </div>


                        <div>
                          <span
                            *ngIf="event?.property?.bathrooms"><strong>Bed.:</strong> {{ event?.property?.bathrooms }}</span>
                        </div>

                        <div>
                          <span
                            *ngIf="event?.property?.bathrooms"><strong>Bath.:</strong> {{ event?.property?.bathrooms }}</span>
                        </div>

                      </div>

                    </div>

                  </div>
                </div>

                <div class="property-pophover border-top" *ngIf="event?.contacts?.length">
                  <div class="row">
                    <div class="col-12">
                      <h3 *ngIf="event?.contacts?.length > 1">Attendees</h3>
                      <h3 *ngIf="event?.contacts?.length <= 1">Attendee</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div *ngFor="let contact of event?.contacts">
                        <app-contact-pop-hover [contact]="contact?.address"></app-contact-pop-hover>

                      </div>
                    </div>
                  </div>
                </div>
              </nb-card-body>

            </nb-card>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>


<ng-container *ngIf="!selectedGroup">
  <div class="timeline-group">
    <ng-container *ngFor="let group of events | keyvalue" class="timeline-group">
      <nb-card *ngIf="!showFutureEvents || isFutureDate(group.key) || isToday(group.key)">
        <nb-card-header>{{ datePipe.transform(group.key, 'dd/MM/yyyy') }}</nb-card-header>
        <nb-card-body>
          <div class="timeline-events">
            <div *ngFor="let event of events[group.key]" class="timeline-event">

              <nb-card>
                <nb-card-header (click)="openEvent(event)">{{ datePipe.transform(event.date_time, 'HH:mm') }} | {{ event.title }}</nb-card-header>
                <nb-card-body class="p-0">

                  <div class="property-pophover" *ngIf="event?.property?.id" (click)="openEvent(event)">
                    <div class="row g-0">
                      <div class="col-4">
                        <img src="{{ event?.property?.photo?.full_url }}" style="width:100%"/>
                      </div>

                      <div class="col-8">

                        <div style="padding:5px;">
                          <nb-tag-list size="tiny">
                            <nb-tag size="tiny" status="basic" appearance="filled" text="Pending"
                                    *ngIf="event?.property?.is_available == 0"></nb-tag>
                            <nb-tag size="tiny" status="success" appearance="filled" text="Available"
                                    *ngIf="event?.property?.is_available == 1"></nb-tag>
                            <nb-tag size="tiny" status="danger" appearance="filled" text="Sold"
                                    *ngIf="event?.property?.is_available == 2"></nb-tag>
                            <nb-tag size="tiny" status="basic" appearance="filled" text="Out of market"
                                    *ngIf="event?.property?.is_available == 3"></nb-tag>
                          </nb-tag-list>

                          <div>
                            <strong>Ref:</strong> {{event?.property?.reference}}
                          </div>

                          <div>
                            <span
                              *ngIf="event?.property?.price"><strong>Price: </strong>{{ event?.property?.price | currencyFormat }}</span>
                          </div>

                          <div>
                            <span
                              *ngIf="event?.property?.bathrooms"><strong>Location:</strong> {{ event?.property?.location?.name }}</span>
                          </div>


                          <div>
                            <span
                              *ngIf="event?.property?.bathrooms"><strong>Bed.:</strong> {{ event?.property?.bathrooms }}</span>
                          </div>

                          <div>
                            <span
                              *ngIf="event?.property?.bathrooms"><strong>Bath.:</strong> {{ event?.property?.bathrooms }}</span>
                          </div>

                        </div>

                      </div>

                    </div>
                  </div>

                  <div class="property-pophover border-top" *ngIf="event?.contacts?.length">
                    <div class="row">
                      <div class="col-12">
                        <h3 *ngIf="event?.contacts?.length > 1">Attendees</h3>
                        <h3 *ngIf="event?.contacts?.length <= 1">Attendee</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div *ngFor="let contact of event?.contacts">
                          <app-contact-pop-hover [contact]="contact?.address"></app-contact-pop-hover>

                        </div>
                      </div>
                    </div>
                  </div>
                </nb-card-body>
              </nb-card>
            </div>
          </div>
        </nb-card-body>

      </nb-card>

    </ng-container>
  </div>
</ng-container>





