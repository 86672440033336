import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef, Optional
} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  GetConfigurationService
} from "../../../../services/getConfiguration/get-configuration.service";
import {ContactsService} from "../../services/contacts.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {NbSidebarService, NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";
import {DeviceDetectorService} from "ngx-device-detector";
import {Share} from "../../../../class/share";


export interface Location {
  name: string;
  id: string;
  area: string;
}


@Component({
  selector: 'app-modal',
  templateUrl: './modal-form-contact.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFormContactComponent extends Share implements OnInit, AfterViewInit {

  @Input() contactId: any;
  @Input() addressesCategories: any;
  @Input() genders: any;
  @Input() priorities: any;
  @Input() showOnly: any;
  @Input() PHONE_TYPES: any;
  @Input() EMAIL_TYPES: any;
  @Input() SOCIALS: any;

  datas: any;
  areas!: any[];
  isLoaded = false;
  isEstateAgent: boolean = false;

  formGroup = new FormGroup({
    categories: new FormControl('', [Validators.required]),
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl(''),

    full_address: new FormControl(''),
    notes: new FormControl(''),
    gender: new FormControl<number | null>(null),
    priority: new FormControl('', [Validators.required]),
    spoken_languages: new FormControl(''),
    nationality: new FormControl(''),
    active: new FormControl(''),

    phoneNumbers: new FormArray([]),
    emails: new FormArray([]),
    socials: new FormArray([]),


    xml_feed_url: new FormControl(''),
    dontExportProperties: new FormControl<boolean | false>(false),

  });


  constructor(
    private configurationService: GetConfigurationService,
    private propertiesService: ContactsService,
    private cdr: ChangeDetectorRef,
    private NzDrawerRef: NzDrawerRef,

    private toastrService: NbToastrService,
    private toastService: ToastService,
    private dialog: MatDialog,

    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public drawerRef: NzDrawerRef,
    deviceService: DeviceDetectorService,
    public sidebarService: NbSidebarService
  ) {
    super('Child', deviceService);

  }


  ngOnInit(): void {
    if (!this.contactId) {
      this.isLoaded = true;
    }
  }


  ngAfterViewInit(): void {


    if (this.contactId) {
      this.propertiesService.getContactDetail(this.contactId).subscribe(
        (response) => {
          this.datas = response?.data;

          this.formGroup.controls['categories'].setValue(this.datas?.categories);
          this.formGroup.controls['categories'].updateValueAndValidity();

          this.formGroup.controls['priority'].setValue(this.datas?.priority as string);
          this.formGroup.controls['priority'].updateValueAndValidity();


          this.formGroup.controls['first_name'].setValue(this.datas?.first_name);
          this.formGroup.controls['first_name'].updateValueAndValidity();

          this.formGroup.controls['last_name'].setValue(this.datas?.last_name);
          this.formGroup.controls['last_name'].updateValueAndValidity();

          this.formGroup.controls['full_address'].setValue(this.datas?.full_address);
          this.formGroup.controls['full_address'].updateValueAndValidity();

        this.formGroup.controls['notes'].setValue(this.datas?.notes);
          this.formGroup.controls['notes'].updateValueAndValidity();


          this.formGroup.controls['gender'].setValue(this.datas?.gender);
          this.formGroup.controls['gender'].updateValueAndValidity();

          this.formGroup.controls['xml_feed_url'].setValue(this.datas?.xml_feed_url);
          this.formGroup.controls['xml_feed_url'].updateValueAndValidity();


          this.formGroup.controls['dontExportProperties'].setValue(this.datas?.dontExportProperties);
          this.formGroup.controls['dontExportProperties'].updateValueAndValidity();


          const communications = this.datas?.communications || [];
          const phones = communications.filter((comm: any) => comm.communication_type === 'phone');
          const emails = communications.filter((comm: any) => comm.communication_type === 'email');
          const socials = communications.filter((comm: any) => comm.communication_type === 'social');

          // Remplissez le FormArray 'phoneNumbers' avec les phones
          phones.forEach((phone: any) => {
            const phoneGroup = new FormGroup({
              phoneNumber: new FormControl(phone.value),
              communicationPlace: new FormControl(phone.communication_place),
            });
            (this.formGroup.get('phoneNumbers') as FormArray).push(phoneGroup);
          });

          emails.forEach((email: any) => {
            const emailGroup = new FormGroup({
              emailAddress: new FormControl(email.value),
              communicationPlace: new FormControl(email.communication_place),
            });
            (this.formGroup.get('emails') as FormArray).push(emailGroup);
          });


          socials.forEach((social: any) => {
            const socialGroup = new FormGroup({
              socialValue: new FormControl(social.value),
              communicationPlace: new FormControl(social.communication_place),
            });
            (this.formGroup.get('socials') as FormArray).push(socialGroup);
          });


          this.isLoaded = true;
          this.cdr.detectChanges();

          this.formGroup.updateValueAndValidity();

          this.estateAgent();
        },
        (error) => {
          // Gérer les erreurs
        }
      );
    }


    if (this.showOnly) {
      const categoriesControl = this.formGroup.get('categories') as FormControl;

      const currentCategories = categoriesControl.value || [];

      const selectedCategory = this.addressesCategories.find((category: any) => category.key === this.showOnly);

      if (selectedCategory) {
        // Vérifiez si la catégorie existe déjà dans le contrôle 'categories'
        const categoryExists = currentCategories.some((category: any) => category.id === selectedCategory.id);

        if (!categoryExists) {
          // Ajouter la catégorie au contrôle 'categories'
          currentCategories.push(selectedCategory);

          // Mettez à jour la valeur et la validité du contrôle 'categories'
          categoriesControl.setValue(currentCategories);
          categoriesControl.updateValueAndValidity();
        }
      }

    }

  }


  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }

  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }

    this.estateAgent();
  }

  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }


  submitForm() {
    this.markFormGroupTouched(this.formGroup);

    if (this.contactId) {
      // Vérifier si le formulaire est valide avant d'effectuer d'autres actions
      if (this.formGroup.valid) {
        // Récupérez les données du formulaire
        const formData = this.formGroup.value;

        // Appel à la méthode createProperty du service PropertiesService
        this.propertiesService.updateContact(formData, this.contactId).subscribe(
          (response) => {
            this.NzDrawerRef.close(response);
          },
          (error) => {
            console.error('Erreur lors de la création de la propriété', error);
            // Gérez les erreurs ici
          }
        );
      } else {
        // Le formulaire n'est pas valide, effectuez le traitement nécessaire
      }
    } else {

      // Vérifier si le formulaire est valide avant d'effectuer d'autres actions
      if (this.formGroup.valid) {
        // Récupérez les données du formulaire
        const formData = this.formGroup.value;

        // Appel à la méthode createProperty du service PropertiesService
        this.propertiesService.createContact(formData).subscribe(
          (response) => {
            this.NzDrawerRef.close(response);

          },
          (error) => {
            console.error('Erreur lors de la création de la propriété', error);
            // Gérez les erreurs ici
          }
        );
      } else {
        // Le formulaire n'est pas valide, effectuez le traitement nécessaire
      }
    }
  }


  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }


  get phoneNumbersFormArray(): FormArray {
    return this.formGroup.get('phoneNumbers') as FormArray;
  }

  addPhoneNumber() {
    const newPhoneNumber = new FormGroup({
      phoneNumber: new FormControl('', Validators.required),
      communicationPlace: new FormControl('', Validators.required),
    });

    this.phoneNumbersFormArray.push(newPhoneNumber);
  }

  removePhoneNumber(index: number) {
    this.phoneNumbersFormArray.removeAt(index);
  }


  get emailsFormArray(): FormArray {
    return this.formGroup.get('emails') as FormArray;
  }

  addEmail() {
    const newEmail = new FormGroup({
      emailAddress: new FormControl('', [Validators.required, Validators.email]),
      communicationPlace: new FormControl('', Validators.required),
    });

    this.emailsFormArray.push(newEmail);
  }

  removeEmail(index: number) {
    this.emailsFormArray.removeAt(index);
  }


  onEmailChange(index: number, event: any): void {
    const emailsFormArray = this.formGroup.get('emails') as FormArray;

    // Mettez à jour la valeur du FormArray à l'index spécifié
    const emailFormGroup = emailsFormArray.at(index) as FormGroup;

    // Obtenir la valeur actuelle du FormArray
    const currentValues = emailFormGroup.value;

    // Mettez à jour les champs modifiés
    emailFormGroup.patchValue({
      emailAddress: event.emailAddress !== undefined ? event.emailAddress : currentValues.emailAddress,
      communicationPlace: event.communicationPlace !== undefined ? event.communicationPlace : currentValues.communicationPlace,
    });

    this.cdr.detectChanges();
  }

  onPhoneChange(index: number, event: any): void {
    const phoneNumbersFormArray = this.formGroup.get('phoneNumbers') as FormArray;

    // Mettez à jour la valeur du FormArray à l'index spécifié
    const phoneFormGroup = phoneNumbersFormArray.at(index) as FormGroup;

    // Obtenir la valeur actuelle du FormArray
    const currentValues = phoneFormGroup.value;

    // Mettez à jour les champs modifiés
    phoneFormGroup.patchValue({
      phoneNumber: event.phoneNumber !== undefined ? event.phoneNumber : currentValues.phoneNumber,
      communicationPlace: event.communicationPlace !== undefined ? event.communicationPlace : currentValues.communicationPlace,
    });

    this.cdr.detectChanges();
  }


  get socialsFormArray(): FormArray {
    return this.formGroup.get('socials') as FormArray;
  }

  addSocial() {
    const newSocial = new FormGroup({
      socialValue: new FormControl('', Validators.required),
      communicationPlace: new FormControl('', Validators.required),
    });

    this.socialsFormArray.push(newSocial);
  }

  removeSocial(index: number) {
    this.socialsFormArray.removeAt(index);
  }

  onSocialChange(index: number, event: any): void {
    const socialsFormArray = this.formGroup.get('socials') as FormArray;
    const socialFormGroup = socialsFormArray.at(index) as FormGroup;
    const currentValues = socialFormGroup.value;

    socialFormGroup.patchValue({
      socialValue: event.socialValue !== undefined ? event.socialValue : currentValues.socialValue,
      communicationPlace: event.communicationPlace !== undefined ? event.communicationPlace : currentValues.communicationPlace,
    });

    this.cdr.detectChanges();
  }



  estateAgent() {


    const categoriesArray = this.formGroup.get('categories')?.value;

    if (Array.isArray(categoriesArray)) {
      const result = categoriesArray.find((item: any) => item.key === 'estate_agencies');

      if(result) {
        this.isEstateAgent = true;
      }else {
        this.estateAgent2();
      }
    } else {
      this.isEstateAgent = false;
    }
  }


  estateAgent2() {


    const categoriesArray = this.formGroup.get('categories')?.value;

    if (Array.isArray(categoriesArray)) {
      const result = categoriesArray.find((item: any) => item.name === 'estate_agencies');

      if(result) {
        this.isEstateAgent = true;
      }else {
        this.isEstateAgent = false;
      }
    } else {
      this.isEstateAgent = false;
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Would you like to delete this contact?',
        text: 'This action is irreversible and will permanently delete the event.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.propertiesService.delete(this.contactId).subscribe(
          (response) => {
            this.drawerRef.close(true);
          }
        );
      }
    });
  }
}
