
<div class="p-20" *ngIf="IS_REAL_ESTATE">
  <div class="row">
    <div class="col-12 col-md-6">
      <nb-card>
        <nb-card-header>Properties by location</nb-card-header>
        <nb-card-body>
          <div echarts [options]="chartOptionLocation" class="demo-chart"></div>
        </nb-card-body>
      </nb-card>

    </div>
    <div class="col-12 col-md-6">
      <nb-card>
        <nb-card-header>Properties</nb-card-header>
        <nb-card-body>
          <div echarts [options]="chartOptionProperties" class="demo-chart"></div>
        </nb-card-body>
      </nb-card>
    </div>

  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <nb-card>
        <nb-card-header>Contacts</nb-card-header>
        <nb-card-body>
          <div echarts [options]="chartOption" class="demo-chart"></div>
        </nb-card-body>
      </nb-card>

    </div>
    <div class="col-12 col-md-6">
      <nb-card>
        <nb-card-header>Next widget</nb-card-header>
        <nb-card-body>
          In construction
        </nb-card-body>
      </nb-card>

    </div>

  </div>

</div>




<div class="p-20" *ngIf="!IS_REAL_ESTATE">
  <div class="row">
    <div class="col-12 col-md-6">
      <nb-card>
        <nb-card-header>Contact requests statistics</nb-card-header>
        <nb-card-body>
          <div echarts [options]="chartOptionContactRequest" class="demo-chart"></div>
        </nb-card-body>
      </nb-card>

    </div>
    <div class="col-12 col-md-6">

    </div>

  </div>



</div>
