import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-select-single',
  templateUrl: './select-single.component.html',
  styleUrls: ['./select-single.component.scss']
})
export class SelectSingleComponent {
  @Input() control: AbstractControl | null = null;
  @Input() options!: any[];

  @Input() name: string = '';
  @Input() help: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() after: string = '';
  @Input() icon: string = '';
  @Input() type: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() isLoaded: boolean = false;
  @Input() isKeyValue: boolean = false;
  @Input() isString: boolean = false;
  inputValue!: any;
  originalValue!: string;
  myControl = new FormControl();

  private controlSubscription: Subscription | undefined;

  ngOnInit(): void {
    if (this.control instanceof FormControl) {
      this.inputValue = this.control.value;
      this.controlSubscription = this.control.valueChanges.subscribe(newValue => {

        if(this.isString) {
          this.inputValue = newValue;
        } else {
          this.inputValue = +newValue;
        }

      });
    }
  }

  ngOnDestroy(): void {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

  onInputChange(): void {
    if (this.control instanceof FormControl && this.inputValue !== undefined) {
      this.control.setValue(this.inputValue);
      this.control.markAsDirty();
      this.control.markAsTouched();
      this.control.updateValueAndValidity();
      this.valueChange.emit(this.control.value);
    }
  }




  shouldShowError(): boolean {
    return !!(
      this.control &&
      this.control.invalid &&
      this.inputValue !== this.originalValue
    );
  }

  getErrorMessage(): string {
    if (this.control && this.control.errors) {
      if (this.control.errors['required']) {
        return 'This field is required.';
      } else if (this.control.errors['minlength']) {
        return `Minimum length is ${this.control.errors['minlength'].requiredLength} characters.`;
      }
    }
    return 'Invalid input.';
  }

  get isRequiredControl(): boolean {
    return !!this.control &&
      !!this.control.validator &&
      !!this.control.validator({} as FormControl)?.['required'];

  }

  convertToNumber(value: any): number | string {
    if (!isNaN(value)) {
      return Number(value);
    } else {
      return value;
    }
  }



}
