import {ChangeDetectorRef, Component, Optional} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {EventService} from "../../../../services/getConfiguration/event.service";
import {ModalFormContactComponent} from "../../../contacts/components/modal-form-contact/modal-form-contact.component";
import {ModalFormLocationComponent} from "../../components/modal-form-location/modal-form-location.component";

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent {
  locations!: any;
  searchText = '';
  searchTextArea = '';
  selectedLocation: any = null;



  constructor(
    private router: Router,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerRef: NzDrawerRef,
  ) {
    this.eventService.configurationUpdated$.subscribe(() => {
      this.refreshConfiguration(true);
    });

    this.refreshConfiguration(true);


  }

  refreshConfiguration(reset:any) {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.locations = datas?.data?.locations;

      if(reset) {
        if (this.locations && this.locations.length > 0) {
          this.selectedLocation = this.locations[0];
        }
      }


      this.cdr.detectChanges();
    });
  }

  onDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.locations, event.previousIndex, event.currentIndex);

    this.configurationService.updatePositionsLocations(this.locations).subscribe(
      response => {
        console.log('Positions mises à jour avec succès.', response);
      },
      error => {
        console.error('Erreur lors de la mise à jour des positions.', error);
        // Rétablissez l'ordre initial en cas d'erreur
        moveItemInArray(this.locations, event.currentIndex, event.previousIndex);
      }
    );
  }

  onLocationClick(location: any) {
    this.selectedLocation = location;
  }


  highlightItem() {

    if(!this.searchText) {
      return;
    }

    this.locations?.forEach((location:any) => {
      location.highlighted = location.name.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }






  sortBy(property: string) {
    this.locations.sort((a: any, b: any) => {
      const nameA = a[property].toLowerCase();
      const nameB = b[property].toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    this.configurationService.updatePositionsLocations(this.locations).subscribe(
      response => {
        console.log('Liste triée et mise à jour avec succès.', response);
      },
      error => {
        console.error('Erreur lors de la mise à jour de la liste triée.', error);
        // Rétablissez l'ordre initial en cas d'erreur
        this.locations.sort((a: any, b: any) => a.rank - b.rank);
      }
    );
  }


  addLocation(id:any) {
    let width = '800px';

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormLocationComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new location",
      nzContentParams: {
        parent_id:id
      }
    });

    modalRef.afterClose.subscribe((contactDatas: any) => {
      this.refreshConfiguration(false);
      if(contactDatas?.parent?.id) {
        this.selectedLocation = contactDatas?.parent;
      }

    });

  }

  editLocation(location:any) {
    let width = '800px';

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormLocationComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Edit " + location?.name,
      nzContentParams: {
        location:location
      }
    });

    modalRef.afterClose.subscribe((contactDatas: any) => {
      if(contactDatas) {

        if(contactDatas=='delete') {
          this.refreshConfiguration(true);
        } else {
          this.refreshConfiguration(false);

          if (contactDatas?.parent?.id) {
            this.selectedLocation = contactDatas?.parent;
          }
        }

      }
    });

  }
}
