import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PropertiesService} from "../../../properties/services/properties.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {EventService} from "../../../../services/getConfiguration/event.service";
import {ClientSearchService} from "../../services/clientSearch.service";
import {FormControl, FormGroup} from "@angular/forms";
import {ModalFormPropertyComponent} from "../../../properties/components/modal-form-property/modal-form-property.component";
import {ModalFormSearchComponent} from "../../components/modal-form-search/modal-form-search.component";
import {PropertiesComponent} from "../../../properties/pages/properties/properties.component";
import {ClientSearchPropertiesListComponent} from "../client-search-properties-list/client-search-properties-list.component";
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";
import {NbSidebarService} from "@nebular/theme";

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent extends Share implements OnInit, AfterViewInit {
  @Input() contact_id!: any;
  @Input() property_id!: any;
  @Input() isInDrawer: boolean = false;


  currentPage = 1;
  pageSize = 10;
  totalProperties = 0;
  properties: any[] = [];

  featureGroups: any[] = [];
  locales: any[] = [];
  categories!: any;
  locations!: any;
  properties_status!: any;
  genders!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;

  isLoadingResults = true;

  formGroup = new FormGroup({
    show_inactive: new FormControl(false)
  });

  displayedColumns: string[] = [
    'full_name',
    'properties_count',
    'created_at',
    'actions',
  ];

  constructor(
    private router: Router,
    private ClientSearchService: ClientSearchService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerRef: NzDrawerRef,
    deviceService: DeviceDetectorService,
    public sidebarService: NbSidebarService
  ) {
    super('Child', deviceService);
    this.refreshConfiguration();

  }

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.categories = datas?.data?.categories;
      this.locations = datas?.data?.locations;
      this.properties_status = datas?.data?.properties_status;
      this.featureGroups = datas?.data?.featureGroups;
      this.locales = datas?.data?.locales;

      this.addressesCategories = datas?.data?.addressesCategories;
      this.genders = datas?.data?.genders;
      this.priorities = datas?.data?.priorities;

      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;

      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {


      const page = +params['page'] || 1;
      this.currentPage = page;
      this.loadSearch();



    });
  }

  ngAfterViewInit(): void {

  }

  loadSearch(): void {
    this.isLoadingResults = true;
    this.ClientSearchService.get(this.currentPage, this.pageSize, this.formGroup.getRawValue(), this.contact_id, this.property_id).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.properties = response.data?.data;
        this.totalProperties = response.data?.total;
      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.loadSearch();
  }

  calculateTotalPages(): number {
    return Math.ceil(this.totalProperties / this.pageSize);
  }

  editSearch(id: any) {

    let width = '550px';
    if(this.isMobile) {
      width = '95vw';
    }
    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormSearchComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding form-search',
      nzTitle: "Client search",
      nzContentParams: {
        searchIs: id,

        categories: this.categories,
        featureGroups: this.featureGroups,
        locations: this.locations,
        properties_status: this.properties_status,
        locales: this.locales,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,

        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadSearch();
    });
  }

  openProperties(id: any) {
    this.router.navigate(['admin', 'clients-search', id]);

  }

  openPropertiesDrawer(id: any) {

    let width = '80vw';
    if(this.isMobile) {
      width = '95vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ClientSearchPropertiesListComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding form-search',
      nzTitle: "Client search",
      nzContentParams: {


        searchId:id,

        categories: this.categories,
        featureGroups: this.featureGroups,
        locations: this.locations,
        properties_status: this.properties_status,
        locales: this.locales,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,

        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadSearch();
    });

  }
}
