<form class="row" (submit)="submitForm(true)" *ngIf="formGroup">
  <nb-card>
    <nb-card-body>
      <div class="row" >
        <div class="col-12" *ngIf="!loading">

          <app-input-text [isLoaded]="true"
                          [control]="getFormControl('url')"
                          [name]="'url'"
                          [placeholder]="'Enter a url'"
                          [label]="'Url'"
                          [type]="'text'"
                          (valueChange)="onFormControlChange('url', $event)"
          ></app-input-text>

        </div>


        <div class="col-12" *ngIf="loading" >
          <nb-alert accent="warning">Warning. Do not close this sidebar!<br />Property import in progress</nb-alert>
        </div>
      </div>

    </nb-card-body>

    <nb-card-footer class="text-center">
      <button nbButton status="primary" class="btn-ico-w" [disabled]="!formGroup.valid || loading">
        <nb-icon icon="save-outline"></nb-icon>
        <span>Start import</span>
        <nb-icon icon="loader-outline" *ngIf="loading" class="rotate"></nb-icon>
      </button>
    </nb-card-footer>

  </nb-card>
</form>
