import {ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ContactsComponent} from "../../modules/contacts/pages/contacts/contacts.component";
import {GetConfigurationService} from "../../services/getConfiguration/get-configuration.service";
import {PropertiesService} from "../../modules/properties/services/properties.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {NbToastrService} from "@nebular/theme";
import {ToastService} from "../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";
import {EventService} from "../../services/getConfiguration/event.service";
import {PropertiesComponent} from "../../modules/properties/pages/properties/properties.component";
import {ModalFormContactComponent} from "../../modules/contacts/components/modal-form-contact/modal-form-contact.component";
import {ConfirmComponent} from "../confirm/confirm.component";
import {ModalFormPropertyComponent} from "../../modules/properties/components/modal-form-property/modal-form-property.component";

@Component({
  selector: 'app-app-property-link',
  templateUrl: './app-property-link.component.html',
  styleUrls: ['./app-property-link.component.scss']
})
export class AppPropertyLinkComponent  implements OnInit{
  @Input() formGroup!: FormGroup;


  @Input() property!: any;



  properties: any[] = [];
  featureGroups: any[] = [];
  locales: any[] = [];
  categories!: any;
  locations!: any;
  properties_status!: any;

  constructor(
    private configurationService: GetConfigurationService,
    private propertiesService: PropertiesService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
    private toastrService: NbToastrService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private eventService: EventService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.refreshConfiguration();

  }

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.categories = datas?.data?.categories;
      this.locations = datas?.data?.locations;
      this.properties_status = datas?.data?.properties_status;
      this.featureGroups = datas?.data?.featureGroups;
      this.locales = datas?.data?.locales;



      this.cdr.detectChanges();
    });
  }


  linkToContact($event: any) {
    let modalRef = this._NzDrawerService.create({
      nzContent: PropertiesComponent,
      nzWidth: '80vw',
      nzWrapClassName: 'nopadding',
      nzTitle: "Owners",

      nzContentParams: {
        isInDrawer:true,
      }
    });

    modalRef.afterClose.subscribe((property:any) => {
      if(property) {
        this.formGroup?.get('property_id')?.setValue(property?.id);
        this.property = property;
        this.cdr.detectChanges();
        this.toastService.showToast('Owner linked successfully', 'Success!', 'success');
      }
    });
  }

  editOwner(id: any) {
    let width = '80vw';

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormPropertyComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new contact",
      nzContentParams: {
        propertyId: id,
        categories: this.categories,
        locations: this.locations,
        properties_status: this.properties_status,
        featureGroups: this.featureGroups,
        locales: this.locales,
      }
    });

    modalRef.afterClose.subscribe((datas: any) => {
      if (datas) {
        this.formGroup?.get('property_id')?.setValue(datas?.id);
        this.property = datas;
        this.cdr.detectChanges();
      }
    });
  }



}
