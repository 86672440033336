
<div class="mb-4">
  <label form-label  [for]="name" *ngIf="label">{{ label }} <small *ngIf="isRequiredControl">*</small></label>

    <textarea
      (keyup)="autoGrowTextZone($event)"
      class="form-control"
      [id]="name"
      [placeholder]="placeholder"
      [(ngModel)]="inputValue"
      [required]="isRequiredControl"
      (input)="onInputChange()"
    ></textarea>

  <div *ngIf="shouldShowError()" class="invalid-feedback">
    {{ getErrorMessage() }}
  </div>

  <div class="form-text" *ngIf="help">
    <nb-icon icon="question-mark-circle-outline"></nb-icon> {{help}}
  </div>
</div>


