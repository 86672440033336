<h2 mat-dialog-title>{{data.title}}</h2>

<div class="content-alert">
<nb-alert [accent]="data.class" mat-dialog-content ><div [innerHTML]="data.text"></div></nb-alert>
</div>


<mat-dialog-actions align="end">
  <button nbButton mat-dialog-close class="first-btn"><nb-icon icon="close-outline"></nb-icon> {{data.no}}</button>
  <button nbButton [mat-dialog-close]="true"  status="success"><nb-icon icon="save-outline"></nb-icon> {{data.yes}}</button>
</mat-dialog-actions>

