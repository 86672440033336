import {Component, Input, Optional} from '@angular/core';

import { Router} from "@angular/router";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {ModalFormPropertyComponent} from "../../../modules/properties/components/modal-form-property/modal-form-property.component";
import {ContactDetailComponent} from "../../../modules/contacts/contact-detail/contact-detail.component";

@Component({
  selector: 'app-contact-pop-hover',
  templateUrl: './contact-pop-hover.component.html',
  styleUrls: ['./contact-pop-hover.component.scss']
})
export class ContactPopHoverComponent {
  @Input() contact!: any;

  constructor(
    @Optional() public _NzDrawerService: NzDrawerService,
    private router: Router
  ) {

  }


  openDashboard(contact: any) {
    let modalRef = this._NzDrawerService.create({
      nzContent: ContactDetailComponent,
      nzWidth: '85vw',
      nzWrapClassName: 'nopadding',
      nzTitle: "Address dashboard",
      nzContentParams: {
        contact_id: contact?.id,
        contact: contact,

      }
    });


  }

  openDashboardNewTab(contact: any) {
    window.open(`/admin/contacts/detail/${contact?.id}`, '_blank');

  }
}
