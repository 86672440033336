import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {PropertiesService} from "../../services/properties.service";
import {MatDrawer, MatSidenav} from "@angular/material/sidenav";
import {ModalComponent} from "../../../../components/modal/modal.component";
import {MatDialog} from "@angular/material/dialog";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {ModalFormPropertyComponent} from "../../components/modal-form-property/modal-form-property.component";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {SidebarFiltersComponent} from "../../components/sidebar-filters/sidebar-filters.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EventService} from "../../../../services/getConfiguration/event.service";
import {ContactsComponent} from "../../../contacts/pages/contacts/contacts.component";
import {ModalFormSearchComponent} from "../../../clientSearch/components/modal-form-search/modal-form-search.component";
import {MatSort, Sort} from "@angular/material/sort";
import {ImportPropertyComponent} from "../../components/import-property/import-property.component";
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";



@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})


export class PropertiesComponent extends Share implements OnInit, AfterViewInit {

  @Input() isInDrawer:boolean = false;
  displayedColumns: string[] = [
    'is_available',
    'photo',
    'reference',
    'mls_reference',
    'agency',
    'price',
    'location',
    'categories',
    'bedrooms',
    'bathrooms',
    'size',
    'lot_size',
    'translations',
    'updated_at',
    'actions',
  ];
  isLoadingResults = true;

  properties: any[] = [];
  featureGroups: any[] = [];
  locales: any[] = [];
  categories!: any;
  locations!: any;
  properties_status!: any;

  genders!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;
  constructor(
    deviceService: DeviceDetectorService,
    private router: Router,
    private propertiesService: PropertiesService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerRef: NzDrawerRef,
  ) {
    super('Child', deviceService);
    this.eventService.configurationUpdated$.subscribe(() => {
      this.refreshConfiguration();
    });

    // Initialisez ou récupérez la configuration ici au chargement du composant
    this.refreshConfiguration();
  }


  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.categories = datas?.data?.categories;
      this.locations = datas?.data?.locations;
      this.properties_status = datas?.data?.properties_status;
      this.featureGroups = datas?.data?.featureGroups;
      this.locales = datas?.data?.locales;

      this.addressesCategories = datas?.data?.addressesCategories;
      this.genders = datas?.data?.genders;
      this.priorities = datas?.data?.priorities;

      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;

      this.cdr.detectChanges();
    });
  }




  ngOnInit(): void {

    if(this.owner_id) {
      this.formGroup.get('owner_id')?.setValue(this.owner_id);
    }

    if(this.agency_id) {
      this.formGroup.get('agency_id')?.setValue(this.agency_id);
    }

    if(this.show_inactive) {
      this.showSold = false;
      this.toggleSold();
    }


    if (this._NzDrawerRef) {
      this.displayedColumns.unshift('select');
      this.isInDrawer = true;
    }

    this.route.queryParams.subscribe(params => {
      const page = +params['page'] || 1;
      this.currentPage = page;
      this.loadProperties();
    });
  }


  addProperty(id?: any) {


    let width = '85vw'
    if(this.isMobile) {
      width = '100vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormPropertyComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new property",
      nzContentParams: {
        propertyId: id,
        categories: this.categories,
        featureGroups: this.featureGroups,
        locations: this.locations,
        properties_status: this.properties_status,
        locales: this.locales,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        owner_id: this.owner_id,

        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,

      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadProperties();
    });
  }


  importProperty() {

    let width = '480px'
    if(this.isMobile) {
      width = '90vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ImportPropertyComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Import property",
      nzContentParams: {


      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadProperties();
    });
  }

  openDashboard(id?: any) {
    this.router.navigate(['admin', 'properties', 'dashboard', id]);
  }


  openDetailPublicProperty(ref?: any) {
    window.open(`https://www.villalingo.com/property-${ref}`, '_blank');
  }

  currentPage = 1;
  pageSize = 10;
  totalProperties = 0;
  sortBy = '';
  sortOrder = '';
  @ViewChild(MatSort) sort!: MatSort;

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.loadProperties();
  }

  calculateTotalPages(): number {
    return Math.ceil(this.totalProperties / this.pageSize);
  }

  sortData(sort: Sort) {
    const column = sort.active;
    let sortOrder = sort.direction === 'asc' ? 'asc' : 'desc';

    // Si la colonne de tri est la même que celle précédemment utilisée,
    // Inversez la direction du tri
    if (column === this.sortBy) {
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    this.sortBy = column;
    this.sortOrder = sortOrder;
    this.loadProperties();
  }

  loadProperties(): void {
    this.isLoadingResults = true;
    this.propertiesService.getProperties(this.currentPage, this.pageSize, this.formGroup.getRawValue(), this.sortBy, this.sortOrder).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.properties = response.data?.data;
        this.totalProperties = response.data?.total;
      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );
  }

  @Input() owner_id!: any;
  @Input() agency_id!: any;
  @Input() show_inactive!: any;


  formGroup = new FormGroup({
    show_inactive: new FormControl(false),
    reference: new FormControl(''),
    shared_reference: new FormControl(''),
    price: new FormControl('', [Validators.pattern(/^\d*$/)]),
    description: new FormControl(''),
    categories: new FormControl(''),
    location: new FormControl(''),
    area: new FormControl(''),
    address: new FormControl(''),
    exclusivity: new FormControl<boolean>(false),

    bedrooms: new FormControl('', [Validators.pattern(/^\d*$/)]), // Champs pour le nombre de chambres
    bathrooms: new FormControl('', [Validators.pattern(/^\d*$/)]), // Champs pour le nombre de salles de bains
    size: new FormControl('', [Validators.pattern(/^\d*$/)]), // Champs pour la taille
    lot_size: new FormControl('', [Validators.pattern(/^\d*$/)]),

    owner_id: new FormControl(''),
    agency_id: new FormControl(''),

    min_bedrooms: new FormControl('', [Validators.pattern(/^\d*$/)]),
    max_bedrooms: new FormControl('', [Validators.pattern(/^\d*$/)]),


    min_bathrooms: new FormControl('', [Validators.pattern(/^\d*$/)]),
    max_bathrooms: new FormControl('', [Validators.pattern(/^\d*$/)]),


    min_size: new FormControl('', [Validators.pattern(/^\d*$/)]),
    max_size: new FormControl('', [Validators.pattern(/^\d*$/)]),


    min_lot_size: new FormControl('', [Validators.pattern(/^\d*$/)]),
    max_lot_size: new FormControl('', [Validators.pattern(/^\d*$/)]),


    min_price: new FormControl('', [Validators.pattern(/^\d*$/)]),
    max_price: new FormControl('', [Validators.pattern(/^\d*$/)]),


  });

  showfilter() {
    let modalRef = this._NzDrawerService.create({
      nzContent: SidebarFiltersComponent,
      nzWidth: '400px',
      nzWrapClassName: 'nopadding form-search',
      nzPlacement: 'left',
      nzTitle: "Filters",
      nzContentParams: {
        formGroup: this.formGroup,
        categories: this.categories,
        locations: this.locations,
      }
    });

    modalRef.afterClose.subscribe((formGroup: any) => {
      if (formGroup) {
        this.currentPage = 1;

        this.router.navigate([], {
          queryParams: {page: this.currentPage},
          queryParamsHandling: 'merge',
        });


        this.formGroup = formGroup;
        this.formGroup.updateValueAndValidity()
        this.countFilledFields();
        this.loadProperties();
      }
    });
  }

  public nbFilters: number = 0;

  countFilledFields(): number {
    this.nbFilters = 0;

    Object.keys(this.formGroup.controls).forEach(controlName => {
      const control = this.formGroup.get(controlName);
      if (control?.value !== null && (control?.value !== '' && control?.value !== false)) {
        this.nbFilters++;
      }
    });

    return this.nbFilters;
  }

  showSold: boolean = false;

  toggleSold() {
    this.showSold = !this.showSold;
    this.formGroup.get('show_inactive')?.setValue(this.showSold);
    this.formGroup.get('show_inactive')?.updateValueAndValidity();

    this.currentPage = 1;

    this.router.navigate([], {
      queryParams: {page: this.currentPage},
      queryParamsHandling: 'merge',
    });

    this.countFilledFields();
    this.loadProperties();
  }

  hasTranslation(property: any, locale: string): boolean {
    return property.translations.some((translation: any) => translation.locale === locale);
  }

  getPropertyTranslation(property: any, locale: string): string {
    const translation = property.translations.find((t: any) => t.locale === locale);
    return translation ? translation.title : ''; // ajustez en fonction de vos besoins
  }

  select(id: any) {
    this._NzDrawerRef.close(id);
  }


  assignSearch() {

    let width = '80vw'
    if(this.isMobile) {
      width = '95vw';
    }



    let modalRef = this._NzDrawerService.create({
      nzContent: ContactsComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Owners",

      nzContentParams: {
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((contact:any) => {
      if(contact) {
        let modalRef = this._NzDrawerService.create({
          nzContent: ModalFormSearchComponent,
          nzWidth: '550px',
          nzWrapClassName: 'nopadding form-search',
          nzTitle: "Save search",

          nzContentParams: {
            contact: contact,
            formGroup: this.formGroup,
            categories: this.categories,
            featureGroups: this.featureGroups,
            locations: this.locations,
            properties_status: this.properties_status,
            locales: this.locales,
            addressesCategories: this.addressesCategories,
            genders: this.genders,
            priorities: this.priorities,
            owner_id: this.owner_id,

            PHONE_TYPES: this.PHONE_TYPES,
            SOCIALS: this.SOCIALS,
            EMAIL_TYPES: this.EMAIL_TYPES,

          }
        });

        modalRef.afterClose.subscribe((contact:any) => {
          if(contact) {

            console.log(contact)
          }
        });
      }
    });
  }



  ngAfterViewInit(): void {

  }


  @ViewChild('tableContainer') tableContainer!: ElementRef;


  isMouseDown: boolean = false;
  startX!: number;
  startScrollLeft!: number;


  onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
    this.startX = event.pageX - this.tableContainer.nativeElement.offsetLeft;
    this.startScrollLeft = this.tableContainer.nativeElement.scrollLeft;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown) return;
    const x = event.pageX - this.tableContainer.nativeElement.offsetLeft;
    const distance = x - this.startX;
    this.tableContainer.nativeElement.scrollLeft = this.startScrollLeft - distance;
  }

  onMouseUp() {
    this.isMouseDown = false;
  }
}
