import { Directive, ElementRef, Renderer2, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[autoresize]'
})
export class AutoResizeDirective implements AfterViewInit {
  @Input() content: any; // Défaut à 300px si aucun paramètre n'est fourni

  private observer!: MutationObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    // Créer un observateur de mutations pour détecter les changements dans la zone de texte
    this.observer = new MutationObserver(() => this.adjustTextareaHeight());

    // Observer les modifications dans le contenu de la zone de texte
    this.observer.observe(this.el.nativeElement, { subtree: true, childList: true });

    // Ajuster la hauteur initiale
    this.adjustTextareaHeight();
  }

  private adjustTextareaHeight(): void {
    const nativeElement: HTMLTextAreaElement = this.el.nativeElement;
    nativeElement.style.overflow = 'hidden'; // Éviter le défilement
    nativeElement.style.height = 'auto'; // Réinitialiser la hauteur à 'auto' pour obtenir la hauteur de contenu réelle

    // Ajuster la hauteur en fonction du contenu avec une hauteur maximale
    nativeElement.style.height = Math.min(nativeElement.scrollHeight) + 'px'; // Utilisation de la hauteur maximale fournie
  }
}
