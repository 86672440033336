<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">


    <nb-action>
      <h1 class="title-page" *ngIf="category?.name">{{category.name}}</h1>
      <h1 class="title-page" *ngIf="!category?.name">Registrations</h1>
    </nb-action>

    <nb-action>
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input type="text" nbInput [(ngModel)]="searchTerm" (ngModelChange)="onSearchTermChange()">
      </nb-form-field>


    </nb-action>


    <nb-action>
      <strong style="margin-right:5px;">{{totalcontacts}}</strong> registrations
    </nb-action>


    <nb-action *ngIf="registrationStatus">
      <nb-select placeholder="Select status" (selectedChange)="onSelectChange($event)">
        <nb-option value="">All</nb-option>
        <nb-option *ngFor="let status of registrationStatus[0] | keyvalue" [value]="status.value">{{ status.value }}</nb-option>
      </nb-select>

    </nb-action>


  </nb-actions>

  <nb-actions size="small" style="margin-left: auto">

    <nb-action class="action-right">
      <button nbButton status="primary" class="btn-ico-w" (click)="addContact()">
        <nb-icon icon="plus-outline"></nb-icon>
        New registration
      </button>
    </nb-action>
  </nb-actions>

</nb-layout-header>


<div [ngClass]="{'p-20': !isInDrawer}">


  <section class="table-container">


    <ng-template #noResults>
      <nb-alert accent="basic" class="empty" *ngIf="!isLoadingResults">
        <div>
          <nb-icon icon="alert-circle-outline"></nb-icon>
          No result found.</div>
      </nb-alert>
    </ng-template>

    <table mat-table [dataSource]="contacts" class="mat-elevation-z8" *ngIf="contacts.length > 0; else noResults">

      <!-- Edit Column -->
      <ng-container matColumnDef="select" *ngIf="isInDrawer" >
        <th mat-header-cell *matHeaderCellDef>Select</th>
        <td class="select-column" mat-cell *matCellDef="let property">
          <nb-checkbox status="basic" (click)="select(property)" ></nb-checkbox>
        </td>
      </ng-container>


      <!-- is available Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let property">

          <nb-tag-list>
            <nb-tag status="warning" appearance="filled" text="Pending" *ngIf="property?.status == 'pending'"></nb-tag>
            <nb-tag status="success" appearance="filled" text="Accepted" *ngIf="property?.status == 'accepted'"></nb-tag>
            <nb-tag status="danger" appearance="filled" text="Rejected" *ngIf="property?.status == 'rejected'"></nb-tag>
          </nb-tag-list>

        </td>
      </ng-container>

      <!-- is available Column -->
      <ng-container matColumnDef="registration_type">
        <th mat-header-cell *matHeaderCellDef>Registration type</th>
        <td mat-cell *matCellDef="let property">



          <ng-container *ngIf="property?.registration_type == 'in'">
            <nb-icon icon="cloud-download-outline"></nb-icon> From {{property?.related_address?.full_name}}
          </ng-container>


          <ng-container *ngIf="property?.registration_type == 'out'">
            <nb-icon icon="cloud-upload-outline"></nb-icon> To {{property?.related_address?.full_name}}
          </ng-container>

        </td>
      </ng-container>

      <!-- reference Column -->
      <ng-container matColumnDef="full_name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let property">
          <app-contact-pop-hover [contact]="property?.address"></app-contact-pop-hover>

        </td>
      </ng-container>

      <!-- reference Column -->
      <ng-container matColumnDef="related_address_id">
        <th mat-header-cell *matHeaderCellDef>With</th>
        <td mat-cell *matCellDef="let property">
          <app-contact-pop-hover [contact]="property?.related_address"></app-contact-pop-hover>

        </td>
      </ng-container>


      <!-- Price Column -->
      <ng-container matColumnDef="properties_count">
        <th mat-header-cell *matHeaderCellDef>Properties</th>
        <td mat-cell *matCellDef="let property">{{ property.registration_properties_count }}</td>
      </ng-container>


      <!-- Price Column -->
      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef>Last update</th>
        <td mat-cell *matCellDef="let property">{{ property.updated_at | customDate }}</td>
      </ng-container>


      <!-- Edit Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef  class="text-end">Actions</th>
        <td mat-cell *matCellDef="let property" class="text-end">
          <nb-button-group>
            <button nbButtonToggle outline (click)="addContact(property.id)">
              <nb-icon icon="edit-outline"></nb-icon>
            </button>

          </nb-button-group>
        </td>
      </ng-container>


      <ng-container matColumnDef="pagination-footer">
        <td mat-footer-cell *matFooterCellDef colspan="11">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </td>
      </ng-container>


      <ng-container matColumnDef="pagination">
        <th mat-header-cell *matHeaderCellDef colspan="11">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['pagination']; sticky: true"> </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr mat-footer-row *matFooterRowDef="['pagination-footer']; sticky: true" class="footer-table"></tr>

    </table>

  </section>

</div>


