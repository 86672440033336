import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  credentials = { email: '', password: '', selected_domain: '' };
  currentYear: number;
  errorMessage: string = '';
  userName: string = '';
  isLogged: boolean = false;
  isLoading: boolean = false;

  domains: { name: string, domainName: string }[] = environment.DOMAINS;
  constructor(
    private authService: AuthService,
    private router: Router) {
    this.currentYear = new Date().getFullYear();

  }

  selectedDomain: any | null = null;


  onChange(domainName: string) {
    this.selectedDomain = this.domains.find(domain => domain.name === domainName);
    console.log(this.selectedDomain);
  }

  ngOnInit() {
    // Check if the user is already authenticated
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/admin']);
    }
  }

  login() {
    if (this.selectedDomain) {
      this.isLoading = true;

      this.authService.setSelectedDomain(this.selectedDomain);

      // Call handleLogin to manage the login
      this.authService.handleLogin(this.credentials).subscribe(
        (response) => {
          console.log('Login successful!', response);
          // Redirect to another page if needed
          this.isLoading = false;
          this.isLogged = true;
          this.userName = response?.data?.name;
          setTimeout(() => {
            this.router.navigate(['/admin']);
          }, 2500);
        },
        (error) => {
          this.isLoading = false;
          console.error('Login error:', error);
          this.errorMessage = 'Login failed. Please check your credentials.';
          // Affichez la réponse d'erreur du serveur dans la console
          console.error('Server error response:', error);
        }
      );
    } else {
      console.error('Aucun domaine sélectionné.'); // Gérez le cas où selectedDomain est null
    }
  }

}
