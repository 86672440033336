import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormControl, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-translatable',
  templateUrl: './input-translatable.component.html',
  styleUrls: ['./input-translatable.component.scss']
})
export class InputTranslatableComponent {
  private _control: AbstractControl<any, any> | null | undefined;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() isLoaded: boolean = false;
  @Input() type: string = '';

  @Input() name: string = '';


  @Input()
  set control(value: AbstractControl<any, any> | null | undefined) {
    this._control = value;
  }

  get control(): AbstractControl<any, any> | null | undefined {
    return this._control;
  }

  @Input() label: string = '';
  @Input() locale!: any;

  isFormControl(control: AbstractControl<any, any> | null | undefined): control is FormControl {
    return control instanceof FormControl;
  }

  get isRequiredControl(): boolean {
    return !!this.control &&
      !!this.control.validator &&
      !!this.control.validator({} as FormControl)?.['required'];

  }

  onInputChange(): void {
    if (this.control instanceof FormControl) {
      this.control.setValue(this.control.value);
      this.control.markAsDirty();
      this.control.markAsTouched();
      this.valueChange.emit(this.control.value);
    }
  }


}
