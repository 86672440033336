import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from "../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class GeneralsettingsService {
  private baseUrl!: string;
  private apiUrl!: string;
  private publicApiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService // Inject the authentication service
  ) {
    // Get the selected domain from the authentication service
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/settings`;
    } else {
      console.error('Selected domain is not set.');
    }
  }


  create(widgetData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/create`, widgetData);
  }


  update(widgetData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/update/` + widgetData?.id, widgetData);
  }


  delete(widgetId: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/delete/` + widgetId, {});
  }

}
