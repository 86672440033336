<ng-container *ngIf="photos">
  <div class="modal-overlay" (click)="closeGallery()"></div>
  <div class="modal-gallery">
    <img [src]="photos[currentImageIndex]?.full_url" class="modal-image" loading="lazy" #gallery />
    <div class="image-info">
      <div class="image-number">{{ currentImageIndex + 1 }} / {{ photos.length }}</div>
    </div>
    <button nbButton class="prev" (click)="prevImage()"><nb-icon icon="chevron-left-outline"></nb-icon></button>
    <button nbButton class="next" (click)="nextImage()"><nb-icon icon="chevron-right-outline"></nb-icon></button>
  </div>
</ng-container>
