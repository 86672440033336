<form  *ngIf="formGroup" (submit)="submitForm(true)">


  <nb-card>
    <nb-card-body class="p-0">


        <nb-tabset>
          <nb-tab>

            <ng-template nbTabTitle>
              <span>Main</span>
            </ng-template>


            <nb-accordion multi>

              <nb-accordion-item [expanded]="false">
                <nb-accordion-item-header>
                  <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
                  Assign to <span *ngIf="contact?.full_name" style="margin-left:5px;">({{contact?.full_name}})</span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class="row" *ngIf="!contact">
                    <div class="col-12">
                      <button nbButton class="btn-ico-w" size="small" type="button" (click)="openOwners()">
                        <nb-icon icon="person-add-outline" style="color:#000!important;"></nb-icon>
                        Link to owner
                      </button>
                    </div>
                  </div>

                  <div class="row" *ngIf="contact">
                    <div class="col-12">
                      <nb-card>
                        <nb-card-body>
                          <nb-user size="giant"
                                   name="{{contact?.full_name}}">
                          </nb-user>

                          <nb-list class="mt-2 min-list">

                            <nb-list-item *ngIf="contact?.full_address">
                              <strong>Address:</strong> {{contact?.full_address}}
                            </nb-list-item>

                            <nb-list-item *ngFor="let communication of contact?.communications">
                              <ng-container *ngIf="communication.communication_type === 'phone'">
                                <strong>Phone:</strong> {{ communication.value }}
                                ({{ communication.communication_place }})
                              </ng-container>
                              <ng-container *ngIf="communication.communication_type === 'email'">
                                <strong>Email:</strong> {{ communication.value }}
                                ({{ communication.communication_place }})
                              </ng-container>
                              <ng-container *ngIf="communication.communication_type === 'social'">
                                <strong>Social:</strong> <a [href]="communication.value"
                                                            target="_blank">{{ communication.communication_place }}</a>
                              </ng-container>
                            </nb-list-item>
                          </nb-list>

                        </nb-card-body>

                        <nb-card-footer>


                          <div>
                            <nb-button-group multiple size="medium" class="btn-grp-action">
                              <button nbButton fullWidth class="btn-ico-w" size="small" type="button"
                                      (click)="editOwner(contact?.id)">
                                <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
                                Edit
                              </button>
                              <button nbButton fullWidth class="danger" class="btn-ico-w" size="small" type="button"
                                      (click)="openOwners()">
                                <nb-icon icon="refresh-outline" style="color:#000!important;"></nb-icon>
                                Change
                              </button>
                              <button nbButton fullWidth class="danger" size="small" type="button"
                                      (click)="unlinkOwners()">
                                <nb-icon icon="trash-outline" style="color:#ffffff!important;"></nb-icon>
                              </button>
                            </nb-button-group>

                          </div>


                        </nb-card-footer>
                      </nb-card>
                    </div>

                  </div>

                </nb-accordion-item-body>

              </nb-accordion-item>

            </nb-accordion>


            <div class="mt-4">
            <div class="row">
              <div class="col-12">

                <nb-card>
                  <nb-card-body  >

                  <app-html-editor
                  [control]="getFormControl('comment')"
                  [name]="'comment'"
                  [placeholder]="'Comment'"
                  [label]="'Comment'"
                  (valueChange)="onFormControlChange('comment', $event)"
                ></app-html-editor>
                  </nb-card-body>
                </nb-card>
              </div>
            </div>
            </div>


          </nb-tab>


          <nb-tab>

            <ng-template nbTabTitle>
              <span>Search criterias</span>
            </ng-template>

            <app-property-form
              [isClientSearch]="true"
              [isSearch]="true"
              [locales]="locales"
              [formGroup]="formGroup"
              [isLoaded]="true"
              [categories]="categories"
              [locations]="locations"
              [properties_status]="properties_status"
            ></app-property-form>


          </nb-tab>

        </nb-tabset>


    </nb-card-body>

    <nb-card-footer >

      <div class="row">

        <div class="col-6">
          <button nbButton status="danger" class="btn-ico-w" type="button" (click)="cancel()"  *ngIf="formGroup?.get('id')?.value">
            <nb-icon icon="trash-2-outline"></nb-icon>
            <span>Delete</span>
          </button>
        </div>

        <div class="col-6 text-end">

      <button nbButton status="primary" class="btn-ico-w" [disabled]="!formGroup.valid">
        <nb-icon icon="save-outline"></nb-icon>
        <span>Save</span>
      </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
