



<form  *ngIf="formGroup" (submit)="submitForm(true)">


  <nb-card>
    <nb-card-body >

      <nb-card>
        <nb-card-body>
          <div class="row">
            <div class="col-12 col-md-6">
              <app-select-single
                [control]="getFormControl('registration_type')"
                [isKeyValue]="true"
                [isString]="true"
                [name]="'registration_type'"
                [isLoaded]="true"
                [options]="registrations_types"
                [placeholder]="'Registration Type'"
                [label]="'Registration Type'"
                (valueChange)="onFormControlChange('registration_type', $event)"
              ></app-select-single>
            </div>

            <div class="col-12 col-md-6">
              <app-select-single
                [control]="getFormControl('status')"
                [name]="'status'"
                [isLoaded]="true"
                [isKeyValue]="true"
                [options]="registrationStatus"
                [placeholder]="'Status'"
                [label]="'Status'"
                (valueChange)="onFormControlChange('status', $event)"
              ></app-select-single>
            </div>


          </div>
        </nb-card-body>
      </nb-card>
      <div class="row">

        <div class="col-12 col-md-6">

          <nb-card>
            <nb-card-header>
              <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
              Client : <span *ngIf="contact?.full_name" style="margin-left:5px;">({{contact?.full_name}})</span>
            </nb-card-header>
            <nb-card-body>
              <div class="row" *ngIf="!contact">
                <div class="col-12">
                  <button nbButton class="btn-ico-w" size="small" type="button" (click)="openOwners()">
                    <nb-icon icon="person-add-outline" style="color:#000!important;"></nb-icon>
                    Link to contact
                  </button>
                </div>
              </div>

              <div class="row" *ngIf="contact">
                <div class="col-12">
                  <div>
                    <nb-button-group multiple size="medium" class="btn-grp-action">
                      <button nbButton fullWidth class="btn-ico-w" size="small" type="button"
                              (click)="editOwner(contact?.id)">
                        <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
                        Edit
                      </button>
                      <button nbButton fullWidth class="danger" class="btn-ico-w" size="small" type="button"
                              (click)="openOwners()">
                        <nb-icon icon="refresh-outline" style="color:#000!important;"></nb-icon>
                        Change
                      </button>
                      <button nbButton fullWidth class="danger" size="small" type="button"
                              (click)="unlinkOwners()">
                        <nb-icon icon="trash-outline" style="color:#ffffff!important;"></nb-icon>
                      </button>
                    </nb-button-group>

                  </div>
                </div>

              </div>
            </nb-card-body>
          </nb-card>



        </div>
        <div class="col-12 col-md-6">


          <nb-card>
            <nb-card-header>
              <nb-icon icon="home-outline" style="color:#000!important;"></nb-icon>
              Agency <span *ngIf="related?.full_name" style="margin-left:5px;">({{related?.full_name}})</span>
            </nb-card-header>
            <nb-card-body>
              <div class="row" *ngIf="!related">
                <div class="col-12">
                  <button nbButton class="btn-ico-w" size="small" type="button" (click)="openRelated()">
                    <nb-icon icon="person-add-outline" style="color:#000!important;"></nb-icon>
                    Link to agency
                  </button>
                </div>
              </div>

              <div class="row" *ngIf="related">
                <div class="col-12">
                  <div>
                    <nb-button-group multiple size="medium" class="btn-grp-action">
                      <button nbButton fullWidth class="btn-ico-w" size="small" type="button"
                              (click)="editRelated(related?.id)">
                        <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
                        Edit
                      </button>
                      <button nbButton fullWidth class="danger" class="btn-ico-w" size="small" type="button"
                              (click)="openRelated()">
                        <nb-icon icon="refresh-outline" style="color:#000!important;"></nb-icon>
                        Change
                      </button>
                      <button nbButton fullWidth class="danger" size="small" type="button"
                              (click)="unlinkRelated()">
                        <nb-icon icon="trash-outline" style="color:#ffffff!important;"></nb-icon>
                      </button>
                    </nb-button-group>

                  </div>

                </div>

              </div>


            </nb-card-body>
          </nb-card>

        </div>

      </div>

      <div class="row" *ngIf="related">
        <div class="col-12">
          <nb-card>
            <nb-card-header>
              Properties registered with <strong>{{related?.full_name}}</strong>
            </nb-card-header>

            <nb-card-body>
                <button nbButton class="btn-ico-w" size="small" type="button" (click)="openProperties()">
                  <nb-icon icon="person-add-outline" style="color:#000!important;"></nb-icon>
                  Link property
                </button>
            </nb-card-body>


            <nb-card-body *ngIf="!properties.length">
              <div class="empty">No linked property</div>
            </nb-card-body>


            <nb-card-body *ngIf="properties.length">

              <table class="table">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Availability</th>
                  <th scope="col">Ref.</th>
                  <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr  *ngFor="let property of properties" >
                  <th scope="row">
                    <img src="{{property?.photo?.full_url}}" *ngIf="property?.photo?.full_url" width="90" class="imageCorner"/>
                  </th>
                  <td>
                    <nb-tag-list size="tiny">
                      <nb-tag status="basic" appearance="filled" text="Pending" *ngIf="property.is_available == 0"></nb-tag>
                      <nb-tag status="success" appearance="filled" text="Available" *ngIf="property.is_available == 1"></nb-tag>
                      <nb-tag status="danger" appearance="filled" text="Sold" *ngIf="property.is_available == 2"></nb-tag>
                      <nb-tag status="basic" appearance="filled" text="Out of market" *ngIf="property.is_available == 3"></nb-tag>
                    </nb-tag-list>
                  </td>
                  <td>{{ property.reference}}</td>
                  <td>
                    <button nbButton class="btn-ico-w" size="small" type="button" (click)="removeProperty(property)">
                      <nb-icon icon="trash-2-outline" style="color:#000!important;"></nb-icon>
                      Remove
                    </button>
                  </td>
                </tr>

                </tbody>
              </table>


            </nb-card-body>
          </nb-card>
        </div>
      </div>



      <div class="mt-4">
        <div class="row">
          <div class="col-12">

            <nb-card>
              <nb-card-body  >

                <app-html-editor
                  [control]="getFormControl('comment')"
                  [name]="'comment'"
                  [placeholder]="'Comment'"
                  [label]="'Comment'"
                  (valueChange)="onFormControlChange('comment', $event)"
                ></app-html-editor>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
      </div>






    </nb-card-body>

    <nb-card-footer >

      <div class="row">

        <div class="col-12 col-md-6">
          <button nbButton status="danger" class="btn-ico-w" type="button" (click)="cancel()"  *ngIf="formGroup?.get('id')?.value">
            <nb-icon icon="trash-2-outline"></nb-icon>
            <span>Delete</span>
          </button>
        </div>

        <div class="col-12 col-md-6 text-end">

          <button nbButton status="primary" class="btn-ico-w" [disabled]="!formGroup.valid">
            <nb-icon icon="save-outline"></nb-icon>
            <span>Save</span>
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
