import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-sidebar-filters',
  templateUrl: './sidebar-filters.component.html',
  styleUrls: ['./sidebar-filters.component.scss']
})
export class SidebarFiltersComponent {

  @Input() categories: any;
  @Input() locations: any;
  datas: any;
  areas!: any[];
  isLoaded = false;

  @Input() formGroup!: FormGroup;

}
