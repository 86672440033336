<div style="padding:5px!important;">


  <nb-card>
    <nb-card-header>

      Ref.{{datas?.reference}}

      <button nbButton size="small" class="btn-ico-w" >
        <nb-icon icon="edit-outline" style="color:#000!important;"></nb-icon>
        Edit
      </button>
    </nb-card-header>
    <nb-card-body class="p-0">

      <nb-tabset>

        <nb-tab>
          <ng-template nbTabTitle>
            <span>Dashboard</span>
          </ng-template>

          <div>
          </div>
        </nb-tab>


        <nb-tab style="padding:0!important;" *ngIf="datas?.id" >
          <ng-template nbTabTitle>
            <span>In Search</span>
          </ng-template>

          <div>
            <app-client-search [isInDrawer]="true"  [property_id]="datas?.id"  ></app-client-search>
          </div>
        </nb-tab>


        <nb-tab style="padding:0!important;">
          <ng-template nbTabTitle>
            <span>Events</span>
          </ng-template>

          <div>

            <app-events-list style="height:500px; overflow:auto;" *ngIf="datas?.id" [property]="datas"></app-events-list>
          </div>
        </nb-tab>



        <nb-tab style="padding:0!important;" *ngIf="datas">
          <ng-template nbTabTitle>
            <span>Registrations</span>
          </ng-template>

          <div>
            <app-registrations [isInDrawer]="true" [property]="datas" [agency]="datas?.agency"></app-registrations>
          </div>
        </nb-tab>


      </nb-tabset>

    </nb-card-body>
  </nb-card>
</div>
