import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from "../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  private apiUrl!: string; // URL de l'API des adresses

  constructor(
    private http: HttpClient,
    private authService: AuthService // Injectez le service d'authentification
  ) {
    // Obtenez le domaine sélectionné à partir du service d'authentification
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/addresses`;
    } else {
      console.error('Selected domain is not set.');
    }
  }


  getProperties(page: number, pageSize: number, slug: string, search: string, priority: any, sortBy?: string, sortOrder?: string): Observable<any> {
    // Set up the query parameters
    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('priority', priority)
      .set('search', search.toString());

    if (sortBy && sortOrder) {
      params = params
        .set('sortBy', sortBy)
        .set('sortOrder', sortOrder);
    }


    // Make the API call with the query parameters
    if(slug) {
      return this.http.get(this.apiUrl + '/' + slug, { params });
    }
    return this.http.get(this.apiUrl, { params });
  }

  createContact(propertyData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/create`, propertyData);
  }

  updateContact(propertyData: any, contactId:any): Observable<any> {
    return this.http.put(`${this.apiUrl}/update/` + contactId, propertyData);
  }

  getContactDetail(contactId: string): Observable<any> {
    // Make the API call to get contact details
    return this.http.get(`${this.apiUrl}/get/${contactId}`);
  }



  delete(videoId: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/delete/` + videoId);
  }
}
