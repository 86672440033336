import {Component, signal, ChangeDetectorRef, ViewChild, Optional} from '@angular/core';
import {CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput} from '@fullcalendar/core';
import interactionPlugin, {EventResizeDoneArg} from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {INITIAL_EVENTS, createEventId} from "../../event-utils";
import {NbMenuItem, NbWindowService, NbWindowControlButtonsConfig, NbDialogService} from "@nebular/theme";
import {EventFormComponent} from "../../components/forms/calendar/event-form/event-form.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EventService} from "../../services/events/event.service";
import {FullCalendarComponent} from '@fullcalendar/angular';
import {HttpClient} from "@angular/common/http";
import {ModalFormContactComponent} from "../../modules/contacts/components/modal-form-contact/modal-form-contact.component";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../services/getConfiguration/get-configuration.service";
import {EventDropArg} from '@fullcalendar/core';
import {IsMobileService} from "../../services/isMobile/isMobile.service";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent  {
  Events: any[] = [];

  calendarVisible = signal(true);
  currentEvents: EventApi[] = [];

  eventsPromise!: Promise<EventInput[]>;

  isHidden = false;

  start!: any;
  end!: any;
  properties: any[] = [];
  featureGroups: any[] = [];
  locales: any[] = [];
  categories!: any;
  locations!: any;
  properties_status!: any;


  genders!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;


  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    eventOverlap: true,


    nowIndicator:true,

    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridWeek,dayGridMonth,timeGridDay,listWeek'
    },

    initialView: 'timeGridWeek',
    eventDrop: this.handleEventDrop.bind(this),
    eventResize: this.handleEventResize.bind(this),

    events: this.Events,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    firstDay: 1,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    stickyHeaderDates: false,
    stickyFooterScrollbar: false,
    contentHeight: 700,
    allDayContent: false,
    allDayText: "",
    slotMinWidth: 0,
    datesSet: this.handleDatesSet.bind(this), // Associez la fonction handleDatesSet à l'événement datesSet

    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */

    eventTimeFormat: { // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    },
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    slotLabelContent: info => {
      if (info.text === '24:00') {
        info.text = '00:00';
      }

      return info.text;
    },
  }


  items: NbMenuItem[] = [
    {
      title: "Home",
      link: '/'
    },
    {
      title: "dashboard",
      link: 'dashboard'
    }
  ];


  itemsUserMenu: NbMenuItem[] = [
    {
      title: 'Profile',
      icon: 'person-outline',
    },
    {
      title: 'Change Password',
      icon: 'lock-outline',
    },
    {
      title: 'Privacy Policy',
      icon: {icon: 'checkmark-outline', pack: 'eva'},
    },
    {
      title: 'Logout',
      icon: 'unlock-outline',
    },
  ];


  constructor(
    private changeDetector: ChangeDetectorRef,
    private windowService: NbWindowService,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private configurationService: GetConfigurationService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public _NzDrawerRef: NzDrawerRef,
    public IsMobileService: IsMobileService
  ) {
    this.refreshConfiguration();
  }


  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.categories = datas?.data?.categories;
      this.locations = datas?.data?.locations;
      this.properties_status = datas?.data?.properties_status;
      this.featureGroups = datas?.data?.featureGroups;
      this.locales = datas?.data?.locales;

      this.addressesCategories = datas?.data?.addressesCategories;
      this.genders = datas?.data?.genders;
      this.priorities = datas?.data?.priorities;

      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;

      this.cdr.detectChanges();
    });
  }

  handleCalendarToggle() {
    this.calendarVisible.update((bool) => !bool);
  }


  handleDateSelect(selectInfo: DateSelectArg) {
    this.addEvent(null, selectInfo);
  }

  addEvent(id: any, selectInfo?: any) {
    let width = '70vw';

    if(this.IsMobileService.isMobile) {
      width = '100wv';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: EventFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new contact",

      nzContentParams: {
        selectedDate: selectInfo,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      this.reload(null, null);

    });
  }

  handleEventClick(clickInfo: EventClickArg) {

    let width = '70vw';

    if(this.IsMobileService.isMobile) {
      width = '100wv';
    }


    let modalRef = this._NzDrawerService.create({
      nzContent: EventFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Event",

      nzContentParams: {
        eventId: clickInfo.event.id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      this.reload(null, null);
    });

  }

  handleEvents(events: EventApi[]) {
    //this.currentEvents.set(events);
    this.changeDetector.detectChanges();
  }


  userMenuVisible = false;

  toggleUserMenu() {
    this.userMenuVisible = !this.userMenuVisible;
  }


  MainMenuVisible = true;

  toggleMainMenu() {
    this.MainMenuVisible = !this.MainMenuVisible;
  }


  ngOnInit() {

    const currentDate = new Date();
    const startOfWeek = currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1);
    const endOfWeek = startOfWeek + 6;

    const startOfWeekDate = new Date(currentDate.setDate(startOfWeek));
    const endOfWeekDate = new Date(currentDate.setDate(endOfWeek));


    this.start = startOfWeekDate.getTime();
    this.end = endOfWeekDate.getTime();


    this.reload(startOfWeekDate.getTime(), endOfWeekDate.getTime());
  }

  @ViewChild(FullCalendarComponent) calendarComponent!: FullCalendarComponent;


  reload(start: any, end: any) {

    if (!start) {
      const calendarApi = this.calendarComponent.getApi();
      const currentView = calendarApi.view;
      start = currentView.activeStart.getTime();
      end = currentView.activeEnd.getTime();
    }

    this.loadEvents(start, end);
  }


  handleDatesSet(info: any) {

    this.loadEvents(Date.parse(info.start), Date.parse(info.end));
  }

  loadEvents(start: any, end: any) {
    this.eventService.getEventsForWeek(start, end).subscribe(
      (res: any) => {
        // Manipulez les événements récupérés comme vous le souhaitez
        console.log('Events for the week:', res);
      },
      (error: any) => {
        // Gérez les erreurs de récupération des événements
        console.error('Error fetching events for the week:', error);
      }
    );
  }

  handleEventDrop(dropInfo: EventDropArg) {
    const updatedEvent = dropInfo.event.toPlainObject();
    const newStartDate = updatedEvent['start'];


    // Appelez votre service pour mettre à jour la date de l'événement
    this.eventService.updateEventDate(updatedEvent['id'], newStartDate).subscribe(
      (response) => {
        console.log('Event updated successfully:', response);

        // Rafraîchissez vos événements après la mise à jour
        this.loadEvents(this.start, this.end);
      },
      (error) => {
        console.error('Error updating event:', error);
        // Gérez les erreurs ici
      }
    );
  }

  handleEventResize(resizeInfo: EventResizeDoneArg) {
    const updatedEvent = resizeInfo.event.toPlainObject();

    // Appelez votre service pour mettre à jour la date de l'événement
    this.eventService.resizeEvent(updatedEvent['id'], updatedEvent['end']).subscribe(
      (response) => {
        console.log('Event updated successfully:', response);

        // Rafraîchissez vos événements après la mise à jour
        this.loadEvents(this.start, this.end);
      },
      (error) => {
        console.error('Error updating event:', error);
        // Gérez les erreurs ici
      }
    );


  }

  isCurrentTime(start: Date, end: Date): boolean {
    const now = new Date();
    console.log('Now:', now);
    console.log('Start:', start);
    console.log('End:', end);
    return now >= start && now <= end;
  }
}
