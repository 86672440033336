import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../services/toast/toast.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {AfterbeforeService} from "../../services/afterbefore.service";

@Component({
  selector: 'app-after-beforee-form',
  templateUrl: './after-beforee-form.component.html',
  styleUrls: ['./after-beforee-form.component.scss']
})
export class AfterBeforeeFormComponent  implements OnInit {

  @Input() pageId: any;
  @Input() locales: any;
  pageFormGroup!: FormGroup;

  public editor!: any;

  constructor(
    private AfterbeforeService: AfterbeforeService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
  ) {
  }

  isLoaded: boolean = false;

  titleFormArray!: FormArray;

  image_before_url!: any;
  image_after_url!: any;

  ngOnInit(): void {



    const titleFormArrayControls = this.locales.map((locale: any) => this.createTitleFormGroup(locale));
    this.titleFormArray = this.formBuilder.array(titleFormArrayControls);

    this.locales.forEach((locale: any) => {

      if(!this.locale) {
        this.locale = locale;
      }

      this.titleFormArray = this.formBuilder.array(
        this.locales.map((locale: any) => this.createTitleFormGroup(locale))
      );
    });

    this.pageFormGroup = this.formBuilder.group({
      id: [null],
      page_id: [null],
      image_before: [''],
      image_after: [''],
      image_before_updated: [false], // Indicateur pour savoir si l'image a été mise à jour
      image_after_updated: [false], // Indicateur pour savoir si l'image a été mise à jour
      titleForm: this.titleFormArray,
    });
    this.loadPage();



  }

  createTitleFormGroup(locale: any): FormGroup {
    return this.formBuilder.group({
      id: [''],
      title: [''],
      locale: [locale]
    });
  }


  get titleForms(): FormGroup[] {
    return this.titleFormArray.controls as FormGroup[];
  }

  generateDropdownOptions(pages: any[], level = 0): any[] {
    const options = [];
    for (const page of pages) {
      options.push({
        id: page.id,
        title: '-'.repeat(level) + ' ' + page.title
      });
      if (page.children && page.children.length > 0) {
        console.log(page.title)
        options.push(...this.generateDropdownOptions(page.children, level + 1));
      }
    }
    return options;
  }

  public page!: any;
  dropdownPages!: any[];
  dropdownOptions!: any[];
  selectedParentId: any;

  loadPage() {
    if(this.pageId) {
      this.AfterbeforeService.getPage(this.pageId).subscribe(datas => {
        this.page = datas?.slider;
        this.dropdownOptions = this.generateDropdownOptions(datas?.dropdownPages); // Remplacez "yourPageData" par vos propres données

        this.selectedParentId = this.page.page_id;
        if (this.page) {

          // Parcourez chaque traduction de la page
          this.page.translations.forEach((translation: any) => {
            // Trouvez l'index de la traduction dans le tableau des langues
            const index = this.locales.findIndex((locale: any) => locale === translation.locale);
            if (index !== -1) {
              // Si la traduction est trouvée, patchez les valeurs dans le FormGroup correspondant
              this.titleFormArray.at(index).patchValue(translation);
            }
          });


          this.pageFormGroup.get('id')?.setValue(this.page?.id)

          this.image_before_url = this.page?.image_before_url;
          this.image_after_url = this.page?.image_after_url;

          this.isLoaded = true;

        }
      });
    } else {
      this.isLoaded = true;

      this.AfterbeforeService.listPages().subscribe(datas => {
        this.isLoaded = true;
        this.dropdownOptions = this.generateDropdownOptions(datas?.dropdownPages); // Remplacez "yourPageData" par vos propres données

      });
    }
  }


  selectedImageFile: File | undefined;

  handleFileInput(event: any, fieldName: string): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          const result = e.target.result;
          if (typeof result === 'string') { // Vérification du type
            if (fieldName === 'image_before') {
              // Convertir l'image en format jpg
              this.convertToJpg(result, fieldName);
            }

            if (fieldName === 'image_after') {
              // Convertir l'image en format jpg
              this.convertToJpg(result, fieldName);
            }
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }


  convertToJpg(base64Data: string, fieldName: string): void {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/jpeg'); // Convertir l'image en jpg
        if (fieldName === 'image_before') {
          // Stockez temporairement l'image sélectionnée
          this.selectedImageFile = this.dataURItoBlob(dataURL);
          // Mettez à jour le chemin de l'image principale dans le formulaire
          this.pageFormGroup.get('image_before')?.setValue(dataURL);
          this.pageFormGroup.get('image_before_updated')?.setValue(true);
        }

        if (fieldName === 'image_after') {
          // Stockez temporairement l'image sélectionnée
          this.selectedImageFile = this.dataURItoBlob(dataURL);
          // Mettez à jour le chemin de l'image principale dans le formulaire
          this.pageFormGroup.get('image_after')?.setValue(dataURL);
          this.pageFormGroup.get('image_after_updated')?.setValue(true);
        }
      }
    };
    img.src = base64Data;
  }

  dataURItoBlob(dataURI: string): File {
    // Diviser la chaîne de données en deux parties : le type MIME et les données réelles
    const [type, byteString] = dataURI.split(',');

    // Convertir la chaîne base64 en tableau de nombres entiers non signés
    const binary = atob(byteString);

    // Créer un tableau tampon pour stocker les octets binaires
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }

    // Créer un objet Blob à partir des données binaires
    const blob = new Blob([array], {type: type});

    // Créer un objet File à partir du Blob
    const fileName = 'image.jpg'; // Nom de fichier par défaut
    return new File([blob], fileName, {type: type});
  }



  isFormValidForLocale(formGroup: FormGroup): boolean {
    return formGroup.valid; // Vous pouvez ajouter des conditions supplémentaires si nécessaire
  }

  getTitleFormGroup(index: any): FormGroup {
    return this.titleFormArray.at(index) as FormGroup;
  }

  getTitleFormControl(locale: string, fieldName: string): FormControl {
    return this.titleFormArray.controls.find(control => control.get('locale')?.value === locale)?.get(fieldName) as FormControl;
  }


  onSubmit(): void {
    if (this.pageFormGroup.valid) {
      const pageData = this.pageFormGroup.value;

      this.AfterbeforeService.createOrUpdatePage(pageData).subscribe(
        response => {
          this.pageFormGroup.get('image_before_updated')?.setValue(false); // Marquez la grande image comme mise à jour


          this.toastService.showToast('Page updated successfully', 'Success!', 'success');
          this.drawerRef.close();

          console.log('Page créée ou mise à jour avec succès', response);
        },
        error => {
          // Gérer les erreurs
          console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
        }
      );
    } else {
      // Le formulaire n'est pas valide, affichez un message d'erreur ou prenez d'autres mesures nécessaires
      console.error('Le formulaire est invalide. Veuillez vérifier les champs et réessayer.');
    }
  }

  public locale!:any;
  selectTab($event: any): void {
    this.locale = $event.tabId;
  }



}
