<form class="row" (submit)="submitForm()">

  <div class="col-12">

    <nb-card>
      <nb-card-body class="p-0" >

        <nb-tabset>
          <nb-tab>

            <ng-template nbTabTitle>



              <nb-icon  icon="person-outline" *ngIf="formGroup.get('gender')?.value !== 3"></nb-icon>
              <nb-icon  icon="home-outline" *ngIf="formGroup.get('gender')?.value !== null && formGroup.get('gender')?.value === 3"></nb-icon>


              <span>Contact detail

              <nb-icon class="red" *ngIf="formGroup.get('first_name')?.invalid || formGroup.get('last_name')?.invalid  || formGroup.get('categories')?.invalid || formGroup.get('priority')?.invalid
" icon="alert-triangle-outline"></nb-icon>


              </span>
            </ng-template>

            <div class="row">
              <div class="col-12">



                <app-input-chips
                  [control]="getFormControl('categories')"
                  [name]="'categories'"
                  [isLoaded]="isLoaded"
                  [options]="addressesCategories"
                  [placeholder]="'Add category *'"
                  [label]="'categories'"
                  [type]="'text'"
                  (valueChange)="onFormControlChange('categories', $event)"
                ></app-input-chips>
              </div>


              <div class="col-12">

                <app-select-single
                  [control]="getFormControl('priority')"
                  [name]="'priority'"
                  [isLoaded]="isLoaded"
                  [options]="priorities"
                  [placeholder]="'Status'"
                  [label]="'Status'"
                  (valueChange)="onFormControlChange('priority', $event)"
                ></app-select-single>
              </div>

            </div>


            <div class="row">

              <div class="col-12 col-md-3">


                <app-select-single
                  [control]="getFormControl('gender')"
                  [isKeyValue]="true"
                  [name]="'gender'"
                  [isLoaded]="isLoaded"
                  [options]="genders"
                  [placeholder]="'Gender'"
                  [label]="'Gender'"
                  (valueChange)="onFormControlChange('gender', $event)"
                ></app-select-single>
              </div>



              <div class="col-12 col-md-9" *ngIf="formGroup.get('gender')?.value !== null && formGroup.get('gender')?.value === 3" >

                <app-input-text [isLoaded]="isLoaded"
                                [control]="getFormControl('first_name')"
                                [name]="'first_name'"
                                [placeholder]="'Enter a first_name'"
                                [label]="'Company name'"
                                [type]="'text'"
                                (valueChange)="onFormControlChange('first_name', $event)"
                ></app-input-text>

              </div>



              <div class="col-12 col-md-5" *ngIf="formGroup.get('gender')?.value !== null && formGroup.get('gender')?.value !== 3" >

                <app-input-text [isLoaded]="isLoaded"
                                [control]="getFormControl('first_name')"
                                [name]="'first_name'"
                                [placeholder]="'Enter a first_name'"
                                [label]="'First name'"
                                [type]="'text'"
                                (valueChange)="onFormControlChange('first_name', $event)"
                ></app-input-text>

              </div>
              <div class="col-12 col-md-4" *ngIf="formGroup.get('gender')?.value !== null && formGroup.get('gender')?.value !== 3">
                <app-input-text [isLoaded]="isLoaded"
                                [control]="getFormControl('last_name')"
                                [name]="'last_name'"
                                [placeholder]="'Enter a last_name'"
                                [label]="'Last name'"
                                [type]="'text'"
                                (valueChange)="onFormControlChange('last_name', $event)"
                ></app-input-text>
              </div>
            </div>


            <div class="row">
              <div class="col-12">
                <app-html-editor
                  [control]="getFormControl('full_address')"
                  [name]="'full_address'"
                  [placeholder]="'Address'"
                  [label]="'Address'"
                  (valueChange)="onFormControlChange('full_address', $event)"
                ></app-html-editor>
              </div>
            </div>


            <div class="row">
              <div class="col-12">
                <app-html-editor
                  [control]="getFormControl('notes')"
                  [name]="'notes'"
                  [placeholder]="'Notes'"
                  [label]="'Notes'"
                  (valueChange)="onFormControlChange('notes', $event)"
                ></app-html-editor>
              </div>
            </div>


          </nb-tab>


          <nb-tab>
            <ng-template nbTabTitle>
              <nb-icon  icon="phone-call-outline"></nb-icon>
              <span><span *ngIf="!isMobile">Communications</span> ({{datas?.communications?.length || 0}})

                <nb-icon class="red" *ngIf="formGroup.get('phoneNumbers')?.invalid || formGroup.get('emails')?.invalid || formGroup.get('socials')?.invalid" icon="alert-triangle-outline"></nb-icon>

              </span>
            </ng-template>

            <div class="col-12" [formGroup]="formGroup">
              <nb-card>
                <nb-card-header>Phones</nb-card-header>
                <nb-card-body>

                  <div formArrayName="phoneNumbers">
                    <div *ngFor="let phone of phoneNumbersFormArray.controls; let i = index" class="row" [formGroupName]="i">
                      <div class="col-12 col-md-6">
                        <app-input-text
                          [isLoaded]="isLoaded"
                          [control]="phone.get('phoneNumber')"
                          [icon]="'fa-solid fa-mobile-screen'"
                          [name]="'phone'"
                          [type]="'text'"
                          [label]="'Phone number ' + (i + 1)"
                          (valueChange)="onPhoneChange(i, $event)"
                        ></app-input-text>
                      </div>
                      <div class="col-8 col-md-4">
                        <app-select-single
                          [control]="phone.get('communicationPlace')"
                          [isKeyValue]="true"
                          [isString]="true"
                          [isLoaded]="isLoaded"
                          [options]="PHONE_TYPES"
                          [placeholder]="'Type'"
                          [label]="'Type'"
                          (valueChange)="onPhoneChange(i, $event)"
                        ></app-select-single>
                      </div>
                      <div class="col-2 col-md-2 text-center">
                        <button nbButton (click)="removePhoneNumber(i)">
                          <nb-icon icon="trash-2-outline"></nb-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <button nbButton type="button" (click)="addPhoneNumber()">
                    <nb-icon icon="plus-outline"></nb-icon> Add Phone Number
                  </button>
                </nb-card-body>
              </nb-card>
            </div>


            <div class="col-12" [formGroup]="formGroup">
              <nb-card>
                <nb-card-header>Emails</nb-card-header>
                <nb-card-body>
                  <div formArrayName="emails">
                    <div *ngFor="let email of emailsFormArray.controls; let i = index" class="row" [formGroupName]="i">
                      <div class="col-12 col-md-6">
                        <app-input-text
                          [isLoaded]="isLoaded"
                          [control]="email.get('emailAddress')"
                          [icon]="'fa-solid fa-envelope'"
                          [name]="'email'"
                          [type]="'email'"
                          [label]="'Email ' + (i + 1)"
                          (valueChange)="onEmailChange(i, $event)"
                        ></app-input-text>
                      </div>
                      <div class="col-8 col-md-2">
                        <app-select-single
                          [control]="email.get('communicationPlace')"
                          [isKeyValue]="true"
                          [isString]="true"
                          [isLoaded]="isLoaded"
                          [options]="EMAIL_TYPES"
                          [placeholder]="'Type'"
                          [label]="'Type'"
                          (valueChange)="onEmailChange(i, $event)"
                        ></app-select-single>
                      </div>
                      <!-- Ajoutez d'autres champs d'email au besoin -->
                      <div class="col-2 col-md-2 text-center">
                        <button nbButton (click)="removeEmail(i)">
                          <nb-icon icon="trash-2-outline"></nb-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <button nbButton type="button" (click)="addEmail()">
                    <nb-icon icon="plus-outline"></nb-icon> Add Email
                  </button>
                </nb-card-body>
              </nb-card>
            </div>


            <div class="col-12" [formGroup]="formGroup">
              <nb-card>
                <nb-card-header>Socials</nb-card-header>
                <nb-card-body>
                  <!-- ... autres champs du formulaire ... -->

                  <!-- FormArray pour les socials -->
                  <div formArrayName="socials">
                    <div *ngFor="let social of socialsFormArray.controls; let i = index" class="row" [formGroupName]="i">
                      <div class="col-12 col-md-6">
                        <app-input-text
                          [isLoaded]="isLoaded"
                          [control]="social.get('socialValue')"
                          [name]="'social'"
                          [type]="'text'"
                          [label]="'Social ' + (i + 1)"
                          (valueChange)="onSocialChange(i, $event)"
                        ></app-input-text>
                      </div>
                      <div class="col-8 col-md-4">
                        <app-select-single
                          [control]="social.get('communicationPlace')"
                          [isKeyValue]="true"
                          [isString]="true"
                          [isLoaded]="isLoaded"
                          [options]="SOCIALS"
                          [placeholder]="'Type'"
                          [label]="'Type'"
                          (valueChange)="onSocialChange(i, $event)"
                        ></app-select-single>
                      </div>
                      <!-- Ajoutez d'autres champs sociaux au besoin -->
                      <div class="col-2 col-md-2 text-center">
                        <button nbButton (click)="removeSocial(i)">
                          <nb-icon icon="trash-2-outline"></nb-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <button nbButton type="button" (click)="addSocial()">
                    <nb-icon icon="plus-outline"></nb-icon> Add Social
                  </button>

                </nb-card-body>
              </nb-card>
            </div>


          </nb-tab>


          <nb-tab *ngIf="isEstateAgent">


            <ng-template nbTabTitle>
              <nb-icon  icon="link-2-outline"></nb-icon>


              <span>Agency feed</span>
            </ng-template>


            <div class="row">
              <div class="col-12">
                <app-input-text [isLoaded]="isLoaded"
                                [control]="getFormControl('xml_feed_url')"
                                [name]="'xml_feed_url'"
                                [placeholder]="'https://...'"
                                [label]="'Kyero xml feed url'"
                                [type]="'text'"
                                (valueChange)="onFormControlChange('xml_feed_url', $event)"
                ></app-input-text>
              </div>

              <div class="col-12">
                <app-input-checkbox
                  [isLoaded]="isLoaded"
                  [control]="getFormControl('dontExportProperties')"
                  [name]="'dontExportProperties'"
                  [label]="'Exclude from portal exports'"
                  (valueChange)="onFormControlChange('dontExportProperties', $event)"
                ></app-input-checkbox>
              </div>

            </div>

          </nb-tab>

        </nb-tabset>

      </nb-card-body>




      <nb-card-footer >

        <div class="row">

          <div class="col-6">
            <button nbButton status="danger" class="btn-ico-w" type="button" (click)="cancel()"  *ngIf="contactId">
              <nb-icon icon="trash-2-outline"></nb-icon>
              <span>Delete</span>
            </button>
          </div>

          <div class="col-6 text-end">
            <button nbButton status="primary" class="btn-ico-w" [disabled]="!formGroup.valid">
              <nb-icon icon="save-outline"></nb-icon>
              <span *ngIf="!contactId">Add <span *ngIf="!isMobile">contact</span></span>
              <span *ngIf="contactId">Update <span *ngIf="!isMobile">contact</span></span>
            </button>
          </div>
        </div>
      </nb-card-footer>


    </nb-card>



  </div>



</form>

