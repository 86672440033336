<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">

  <div class="row">
    <div class="col-12 col-md-6">

      <nb-card>
        <nb-card-header>
          <span style="margin-right:10px;">Event</span>

        </nb-card-header>

        <nb-card-body>

          <div class="row">
            <div class="col-12 col-md-6">
              <nb-calendar  [(date)]="currentDate"
                           (dateChange)="onDateChange($event)"></nb-calendar>

            </div>

            <div class="col-12 col-md-6">

              <div class="mb-3">
                <div class="form-floating">
                  <input type="text" [ngxTimepicker]="picker" [format]="24" readonly formControlName="time"
                         class="form-control" id="floatingInputInvalid" placeholder="name@example.com"
                         value="test@example.com">
                  <label for="floatingInputInvalid">HH:MM *</label>
                </div>

                <ngx-material-timepicker #picker></ngx-material-timepicker>
              </div>

              <app-select-single
                [control]="getFormControl('duration')"
                [name]="'duration'"
                [isLoaded]="isLoaded"
                [options]="durations"
                [placeholder]="'Duration'"
                [label]="'Duration'"
                (valueChange)="onFormControlChange('duration', $event)"
              ></app-select-single>

            </div>

          </div>

        </nb-card-body>

      </nb-card>


      <app-app-contacts-list
        [formGroup]="formGroup"
        [addressesCategories]="addressesCategories"
        [genders]="genders"
        [priorities]="priorities"
        [contacts]="contacts"></app-app-contacts-list>




    </div>
    <div class="col-12 col-md-6">
      <nb-card>
        <nb-card-header>
          <span style="margin-right:10px;">Detail</span>

        </nb-card-header>

        <nb-card-body>

          <div class="row">
            <div class="col-12">

              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('title')"
                              [name]="'title'"
                              [placeholder]="'Title'"
                              [label]="'Title'"
                              [type]="'text'"
                              (valueChange)="onFormControlChange('title', $event)"
              ></app-input-text>


              <app-html-editor
                [control]="getFormControl('description')"
                [name]="'description'"
                [placeholder]="'Description'"
                [label]="'Description'"
                (valueChange)="onFormControlChange('description', $event)"
              ></app-html-editor>


            </div>

          </div>

        </nb-card-body>

      </nb-card>

      <app-app-property-link

        [property]="property"
        [formGroup]="formGroup"

      ></app-app-property-link>

      <nb-card>


        <nb-card-body >

          <div class="row">

            <div class="col-6">
              <button nbButton status="danger" class="btn-ico-w" type="button" (click)="cancel()"  *ngIf="eventId">
                <nb-icon icon="trash-2-outline"></nb-icon>
                <span>Cancel event</span>
              </button>
            </div>

            <div class="col-6 text-end">
              <button nbButton status="primary" class="btn-ico-w" [disabled]="!formGroup.valid" >
                <nb-icon icon="save-outline"></nb-icon>
                <span>Save</span>
              </button>
            </div>

          </div>



        </nb-card-body>

      </nb-card>


    </div>
  </div>


</form>
