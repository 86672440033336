// pagination.component.ts
import {Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {Share} from "../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent extends Share implements OnInit{
  @Input() isInDrawer: boolean = false;
  @Input() currentPage!: number;
  @Input() totalPages!: number;
  @Output() pageChanged = new EventEmitter<number>();
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    deviceService: DeviceDetectorService

  ) {
    super('Child', deviceService);
  }


  ngOnInit(): void {

  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  onPageChange(page: number): void {
    this.pageChanged.emit(page);

    if(!this.isInDrawer) {
      this.router.navigate([], {
        queryParams: {page: page},
        queryParamsHandling: 'merge',
      });
    }

    this.cdr.detectChanges();

  }

  showPrevNext(): boolean {
    return this.totalPages > 5;
  }

  visiblePages(): number[] {
    const maxVisiblePages = 5;
    const middlePage = Math.ceil(maxVisiblePages / 2);
    let startPage = 1;

    if (this.totalPages > maxVisiblePages) {
      if (this.currentPage > middlePage) {
        startPage = Math.min(this.currentPage - middlePage + 1, this.totalPages - maxVisiblePages + 1);
      }
    }

    return Array.from({ length: Math.min(this.totalPages, maxVisiblePages) }, (_, index) => startPage + index);
  }

  onNext(): void {
    if (this.currentPage < this.totalPages) {
      this.onPageChange(this.currentPage + 1);
    }
  }

  onPrev(): void {
    if (this.currentPage > 1) {
      this.onPageChange(this.currentPage - 1);
    }
  }

  onFirst(): void {
    this.onPageChange(1);
  }

  onLast(): void {
    this.onPageChange(this.totalPages);
  }
}
