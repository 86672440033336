import {Component, Input, OnInit, Optional} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ContactsService} from "../services/contacts.service";
import {ModalFormContactComponent} from "../components/modal-form-contact/modal-form-contact.component";
import {GetConfigurationService} from "../../../services/getConfiguration/get-configuration.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {

  @Input() contact_id!: any;
  @Input() contact!: any;
  owner_id!: any;
  agency_id!: any;
  is_owner: any = null;
  is_agency: any = null;
  is_client: any = null;


  showOnly!: any;
  genders!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;
  locations!: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private propertiesService: ContactsService,
    private configurationService: GetConfigurationService,
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public _NzDrawerRef: NzDrawerRef,
  ) {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.priorities = datas?.data?.priorities;
      this.genders = datas?.data?.genders;
      this.locations = datas?.data?.locations;
      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;
    });

  }

  ngOnInit() {
    this.loadContactRelation();
  }

  loadContactRelation() {
    this.route.paramMap.subscribe(params => {

      if(!this.contact_id) {
        this.contact_id = params.get('id');
      }

      this.loadContact();

    });
  }

  checkIfOwnerCategoryExists() {
    if (this.contact && this.contact.categories) {
      this.contact.categories.forEach((category: any) => {
        if (category.name === 'owners') {
          this.is_owner = true;
        }

        if (category.name === 'estate_agencies') {
          this.is_agency = true;
        }

        if (category.name === 'clients') {
          this.is_client = true;
        }

      });
    }
  }

  addContact() {

    let width = '800px';

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormContactComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new contact",
      nzContentParams: {
        contactId: this.owner_id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        showOnly: this.showOnly,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      if (closedId) {
        this.loadContact();
      }
    });
  }

  loadContact() {
    this.propertiesService.getContactDetail(this.contact_id).subscribe(
      (response) => {
        this.contact = response?.data;

        this.checkIfOwnerCategoryExists();
      },
      (error) => {
        // Gérer les erreurs
      }
    );
  }
}
