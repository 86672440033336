import {Component, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {ContactsService} from "../../services/contacts.service";

import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {ModalFormContactComponent} from "../../components/modal-form-contact/modal-form-contact.component";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {debounceTime, switchMap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Subject} from "rxjs";
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSort, Sort} from "@angular/material/sort";
import {NbSidebarService} from "@nebular/theme";
import {DeviceDetectorService} from "ngx-device-detector";
import {Share} from "../../../../class/share";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})

export class ContactsComponent extends Share implements OnInit {
  @Input() searchValue!: any;

  displayedColumns: string[] = ['priority', 'full_name', 'categories', 'updated_at', 'actions'];

  dataSource = ELEMENT_DATA;
  isLoadingResults = true;

  contacts: any[] = [];
  showOnly!: any;
  genders!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;
  locations!: any;

  sortBy = '';
  sortOrder = '';
  @ViewChild(MatSort) sort!: MatSort;


  isInDrawer = false;
  private searchTermChanged: Subject<string> = new Subject<string>();

  formGroup = new FormGroup({
    priority: new FormControl('', [Validators.required]),
  });


  constructor(
    private ContactsService: ContactsService,
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public _NzDrawerRef: NzDrawerRef,
    private configurationService: GetConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    deviceService: DeviceDetectorService,
    public sidebarService: NbSidebarService
    ) {
    super('Child', deviceService);
    this.configurationService.getConfiguration().subscribe(datas => {
      this.priorities = datas?.data?.priorities;
      this.genders = datas?.data?.genders;
      this.locations = datas?.data?.locations;
      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;
    });
  }


  previousSlug: string = '';
  slug: string = '';
  category!:any;

  ngOnInit(): void {

    if(this.searchValue) {
      this.showShowCheckbox = true;
      this.searchTerm = this.searchValue;
      this.isInDrawer = true;
    }
    this.searchTermChanged.pipe(debounceTime(300)).subscribe(() => {
      this.loadcontacts();
    });


    if (this._NzDrawerRef && !this.showShowCheckbox) {
      this.displayedColumns.unshift('select');
      this.isInDrawer = true;
    }


    if(this.showOnly) {
      this.slug = this.showOnly;
      this.loadcontacts();
      this.previousSlug = this.slug;

      this.configurationService.getConfiguration().subscribe(datas => {
        this.addressesCategories = datas?.data?.addressesCategories;
        this.category = this.addressesCategories.find((category: any) => category.key === this.slug);
      });

    } else {


      this.route.paramMap.pipe(
        switchMap((params: Params) => {
          const page = +params['page'] || 1;
          this.currentPage = page;
          return this.route.params;
        })
      ).subscribe((params: Params) => {
        let slug = params['slug'];

        if (slug && (slug !== this.previousSlug)
        ) {
          this.slug = params['slug'];

          this.searchTerm = '';

          this.loadcontacts();
          this.previousSlug = this.slug;

          this.configurationService.getConfiguration().subscribe(datas => {
            this.addressesCategories = datas?.data?.addressesCategories;
            this.category = this.addressesCategories.find((category: any) => category.key === this.slug);

            const indexToRemove = this.displayedColumns.indexOf('xml_feed_url');
            if (indexToRemove !== -1) {
              this.displayedColumns.splice(indexToRemove, 1);
            }

            if (this.category?.key == 'estate_agencies') {
              const indexAfter = this.displayedColumns.indexOf('full_name');

// Insérer 'xml_feed_url' après 'full_name'
              if (indexAfter !== -1) {
                this.displayedColumns.splice(indexAfter + 1, 0, 'xml_feed_url');
              }
            }
          });




        } else {
          this.loadcontacts();

        }
      });
    }
  }

  sortData(sort: Sort) {
    const column = sort.active;
    let sortOrder = sort.direction === 'asc' ? 'asc' : 'desc';

    // Si la colonne de tri est la même que celle précédemment utilisée,
    // Inversez la direction du tri
    if (column === this.sortBy) {
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    this.sortBy = column;
    this.sortOrder = sortOrder;
    this.loadcontacts();
  }

  addContact(id?: any) {

    let width = '800px';
    if(this.isInDrawer) {
      width = '800px';
    }

    if(this.isMobile) {
      width = '95vw';
    }


    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormContactComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new contact",
      nzContentParams: {
        contactId: id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        showOnly: this.showOnly,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      if (closedId) {
        this.loadcontacts();
      }
    });
  }

  currentPage = 1;
  pageSize = 10;
  totalcontacts = 0;
  onPageChanged(page: number): void {
    this.currentPage = page;
    this.loadcontacts();
  }

  calculateTotalPages(): number {
    return Math.ceil(this.totalcontacts / this.pageSize);
  }

  loadcontacts(): void {
    this.isLoadingResults = true;
    this.ContactsService.getProperties(
      this.currentPage,
      this.pageSize,
      this.slug,
      this.searchTerm,
      this.formGroup.get('priority')?.value,
      this.sortBy,
      this.sortOrder
    ).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.contacts = response.data;
        this.totalcontacts = response.total;
      },
      (error) => {
        console.error('Error fetching contacts:', error);
      }
    );
  }


  select(id: any) {
    this._NzDrawerRef.close(id);
  }

  showShowCheckbox: boolean = false;
  searchTerm: string = '';

  onSearchTermChange() {
    // Déclenche le changement après 300 ms
    this.searchTermChanged.next(this.searchTerm);
  }

  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }

  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }

  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }


  onSelectChange(event: any) {

    this.formGroup.get('priority')?.setValue(event);
    this.formGroup.get('priority')?.updateValueAndValidity();
    this.loadcontacts();
  }

  openDashboard(id: any) {
    this.router.navigate(['admin', 'contacts', 'detail', id]);
  }

  openDashboardNewTab(id: any) {
    window.open(`/admin/contacts/detail/${id}`, '_blank');

  }
}
