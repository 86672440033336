<div style="padding:5px!important;">


  <nb-card>
    <nb-card-header>{{contact?.full_name}}

      <button nbButton size="small" class="btn-ico-w" (click)="addContact()">
        <nb-icon icon="edit-outline" style="color:#000!important;"></nb-icon>
        Edit
      </button>
    </nb-card-header>
    <nb-card-body class="p-0">

      <nb-tabset>



        <nb-tab>
          <ng-template nbTabTitle>
            <span>Dashboard</span>
          </ng-template>

          <div>

          </div>
        </nb-tab>

        <nb-tab style="padding:0!important;" *ngIf="is_client || is_agency">
          <ng-template nbTabTitle>
            <span>Registrations</span>
          </ng-template>


          <div *ngIf="contact">
            <app-registrations [isInDrawer]="true" [contact]="contact" [show_inactive]="true"></app-registrations>
          </div>
        </nb-tab>

        <nb-tab style="padding:0!important;" *ngIf="is_client">
          <ng-template nbTabTitle>
            <span>Search</span>
          </ng-template>

          <div>
            <app-client-search [isInDrawer]="true" [contact_id]="contact_id" [show_inactive]="true"></app-client-search>
          </div>
        </nb-tab>


        <nb-tab style="padding:0!important;" *ngIf="is_owner">
          <ng-template nbTabTitle>
            <span>Owner of properties</span>
          </ng-template>

          <div>
            <app-properties [isInDrawer]="true" [owner_id]="contact_id" [show_inactive]="true"></app-properties>
          </div>
        </nb-tab>

        <nb-tab style="padding:0!important;" *ngIf="is_agency">
          <ng-template nbTabTitle>
            <span>Properties from xml</span>
          </ng-template>

          <div>
            {{agency_id}}
            <app-properties [isInDrawer]="true" [agency_id]="contact_id" [show_inactive]="true"></app-properties>
          </div>
        </nb-tab>


        <nb-tab style="padding:0!important;">
          <ng-template nbTabTitle>
            <span>Events</span>
          </ng-template>

          <div>

            <app-events-list style="height:500px; overflow:auto;" *ngIf="contact" [contact]="contact"></app-events-list>
          </div>
        </nb-tab>


      </nb-tabset>

    </nb-card-body>
  </nb-card>
</div>
