import {Injectable, OnInit} from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import {Router} from "@angular/router";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {GetConfigurationService} from "../getConfiguration/get-configuration.service";
import { of, BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit{
  private apiUrl!: string;
  private userToken: string | null = null;
  private loggedIn = false;
  private selectedDomain: { name: string, domainName: string } | null = null;

  private userDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userData$: Observable<any> = this.userDataSubject.asObservable();


  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    const storedToken = localStorage.getItem('userToken');
    if (storedToken) {
      this.userToken = storedToken;
      this.loggedIn = true;
    }
  }

  ngOnInit(): void {  }



  setSelectedDomain(domain: { name: string, domainName: string }) {
    if (domain?.domainName) {
      this.selectedDomain = domain;
      this.apiUrl = domain?.domainName + '/api';

      // Mettre à jour le domaine sélectionné dans localStorage
      localStorage.setItem('selectedDomain', JSON.stringify(domain));
    }
  }

  getSelectedDomain() {
    const storedDomain = localStorage.getItem('selectedDomain');
    if (storedDomain) {
      this.selectedDomain = JSON.parse(storedDomain);
    } else {
      alert('No selected domain found in localStorage');
    }
    console.log(this.selectedDomain)
    return this.selectedDomain;
  }

  login(credentials: { email: string, password: string }): Observable<any> {
    const selectedDomain = localStorage.getItem('selectedDomain') || 'default_domain';
    return this.http.post(`${this.apiUrl}/login`, credentials);
  }


  logout(): Observable<any> {
    return this.http.post(`${this.apiUrl}/logout`, {}).pipe(
      map(response => {
        this.loggedIn = false;
        this.clearUserToken();
        return response;
      }),
      catchError(error => {
        // Handle logout error if needed
        return of(error);
      })
    );
  }

  private storeUserToken(token: string): void {
    this.userToken = token;
    localStorage.setItem('userToken', token);
  }

  private clearUserToken(): void {
    this.userToken = null;
    localStorage.removeItem('userToken');
    localStorage.removeItem('selectedDomain');
  }

  isLoggedIn(): boolean {
    const storedDomain = JSON.parse(localStorage.getItem('selectedDomain') || '{}');
    this.apiUrl = `${storedDomain?.domainName}/api`;


    // Appeler checkAuthStatus pour mettre à jour loggedIn
    this.checkAuthStatus().subscribe(
      (isAuthenticated) => {
        this.loggedIn = isAuthenticated;

      },
      (error) => {
        console.error('Error checking authentication status:', error);
      }
    );

    return this.loggedIn;
  }



  getUserToken(): string {
    return this.userToken || '';
  }


  handleLogin(credentials: { email: string, password: string }): Observable<any> {
    return this.login(credentials).pipe(
      map(response => {
        if(response.data.token) {
          this.loggedIn = true;
          this.storeUserToken(response.data.token);
          this.userDataSubject.next(response.data);
        }
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
          // Gérer l'erreur d'authentification ici (par exemple, rediriger vers la page de connexion)
          console.error('Authentication failed:', error);
        }
        return throwError(error);
      })
    );
  }


  // Add a method to check user authentication status
  checkAuthStatus(): Observable<boolean> {
    return this.http.get(`${this.apiUrl}/check-auth`).pipe(
      map((response: any) => {
        // Mettre à jour l'état d'authentification loggedIn
        this.loggedIn = true;

        // Mettre à jour les données utilisateur dans userDataSubject
        this.userDataSubject.next(response.data);

        // Retourner true pour indiquer que l'authentification est réussie
        return true;
      }),
      catchError(() => {
        // En cas d'erreur, définir loggedIn sur false et effacer le jeton utilisateur
        this.loggedIn = false;
        this.clearUserToken();

        // Retourner false pour indiquer que l'authentification a échoué
        return of(false);
      })
    );
  }

  handleLogout(): Observable<any> {
    return this.logout().pipe(
      map(response => {
        this.loggedIn = false;
        this.clearUserToken();
        this.router.navigate(['/login']);
        return response;
      }),
      catchError(error => {

        this.loggedIn = false;
        this.clearUserToken();

        return of(error);
      })
    );
  }

  getUserData(): any {
    return this.userDataSubject.getValue();
  }
}
