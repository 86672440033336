<nb-card>
  <nb-card-header>Categories</nb-card-header>
  <nb-card-body>


    <nb-alert accent="basic" class="empty-0"  *ngIf="!selectedOptions.length">
      <div>
        <nb-icon icon="alert-circle-outline"></nb-icon>
        No category</div>
    </nb-alert>

    <mat-chip-list #chipList>
      <mat-chip *ngFor="let option of selectedOptions" (removed)="removeOption($event)">
        {{ option.human_name || option.name }}
        <nb-icon matChipRemove icon="trash-2-outline"></nb-icon>
      </mat-chip>

    </mat-chip-list>


  </nb-card-body>
  <nb-card-footer>




    <div class="input-group">

      <input
        [ngClass]="{'is-invalid': (!control?.valid && control?.dirty)}"
        [matAutocomplete]="auto"
        class="autocomplete form-control"
        #optionInput
        [placeholder]="placeholder"

        [required]="isRequiredControl"
        (input)="onInputChange()"
        [id]="name"
      />

    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addOption($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>


  </nb-card-footer>
</nb-card>

