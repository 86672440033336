import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: number): string {
    // Check if the value is a valid number
    if (isNaN(value) || value === null) {
      return '';
    }

    // Format the number with commas and add the Euro symbol
    const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return `${formattedValue}€`;
  }
}
