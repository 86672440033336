import {Component, Input, Output, EventEmitter } from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";
import {Location} from "../../components/modal-form-property/modal-form-property.component";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {PropertiesService} from "../../services/properties.service";

@Component({
  selector: 'app-property-form',
  templateUrl: './property-form.component.html',
})
export class PropertyFormComponent {
  @Input() formGroup!: FormGroup;
  @Input() titleForm!: FormGroup;
  @Input() isSearch: boolean = false;
  @Input() isClientSearch: boolean = false;
  @Input() isLoaded: boolean = false;
  @Input() locales: any;
  @Input() categories: any;
  @Input() properties_status: any;
  @Input() locations: any;
  @Input() areas: any;
  @Input() propertyId: any;


  constructor(private drawer: NzDrawerRef, private PropertiesService: PropertiesService) {
  }

  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }


  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }


  onFormControlChangeTranslations(controlName: string, newValue: any | null): void {
    this.updateFormControlValueTranslation(controlName, newValue);
  }
  updateFormControlValueTranslation(controlName: string, newValue: any | null): void {
    const control = this.formGroup?.get('translations.' + controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }


  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }

  locationName!:string;
  updateLocation(controlName: string, newValue: any | null): void {
    const location = this.locations.find((user: Location) => user.id === newValue);
    if (location) {
      this.locationName = location?.name
      this.areas = location?.areas;
    } else {
      this.areas = [];
    }
    this.updateFormControlValue(controlName, newValue);
  }
  search() {
    this.drawer.close(this.formGroup);
  }


  translate() {
    const pageData = this.formGroup.get('translations')?.value;

    this.PropertiesService.translate(pageData).subscribe(
      (response: any) => {
        // Parcourir chaque langue dans la réponse
        Object.keys(response).forEach((lang: string) => {


          this.formGroup?.get('translations.title_' + lang)?.setValue(response[lang].title);
          this.formGroup?.get('translations.description_' + lang)?.setValue(response[lang].description);


        });
      },
      error => {
        // Gérer les erreurs
        console.error('Une erreur s\'est produite lors de la récupération des traductions', error);
      }
    );
  }



}
