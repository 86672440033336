import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html'
})
export class InputTextComponent implements OnInit,  OnDestroy  {
  @Input() control: AbstractControl | null = null;

  @Input() name: string = '';
  @Input() help: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() after: string = '';
  @Input() icon: string = '';
  @Input() type: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() isLoaded: boolean = false;
  inputValue: string = '';
  originalValue: string = '';

  private controlSubscription: Subscription | undefined;

  ngOnInit(): void {
    if (this.control instanceof FormControl) {
      this.inputValue = this.control.value;
      this.controlSubscription = this.control.valueChanges.subscribe(newValue => {
        this.inputValue = newValue;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

  onInputChange(): void {
    if (this.control instanceof FormControl) {
      this.control.setValue(this.inputValue);
      this.control.markAsDirty();
      this.control.markAsTouched();
      this.valueChange.emit(this.control.value);
    }
  }



  shouldShowError(): boolean {
    return !!(
      this.control &&
      this.control.invalid &&
      this.inputValue !== this.originalValue
    );
  }

  getErrorMessage(): string {
    if (this.control && this.control.errors) {
      if (this.control.errors['required']) {
        return 'This field is required.';
      } else if (this.control.errors['minlength']) {
        return `Minimum length is ${this.control.errors['minlength'].requiredLength} characters.`;
      }
    }
    return 'Invalid input.';
  }

  get isRequiredControl(): boolean {
    return !!this.control &&
      !!this.control.validator &&
      !!this.control.validator({} as FormControl)?.['required'];

  }
}
