import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../services/toast/toast.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {UsersService} from "../../services/users.service";

@Component({
  selector: 'app-modal-user-form',
  templateUrl: './modal-user-form.component.html',
  styleUrls: ['./modal-user-form.component.scss']
})
export class ModalUserFormComponent  implements OnInit{
  redirectFormGroup!: FormGroup;
  @Input() id: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
    private usersService: UsersService,
  ) {
  }

  ngOnInit(): void {
    this.redirectFormGroup = this.formBuilder.group({
      id: [null],
      name: [null],
      email: [null],
      avatarUrl: [null],
      password: [null], // Ajout du champ "password"
      passwordConfirmation: [null] // Ajout du champ "password confirmation"
    }, {
      validator: this.passwordMatchValidator // Validation personnalisée pour vérifier si les mots de passe correspondent
    });

    if (this.id) {
      this.loadRedirectData(this.id);
      // Supprimez la validation des champs "password" et "passwordConfirmation" lorsque vous chargez des données existantes
      this.redirectFormGroup.get('password')?.clearValidators();
      this.redirectFormGroup.get('passwordConfirmation')?.clearValidators();
    } else {
      // Ajoutez la validation requise pour les champs "password" et "passwordConfirmation" lors de la création d'un nouvel enregistrement
      this.redirectFormGroup.get('password')?.setValidators(Validators.required);
      this.redirectFormGroup.get('passwordConfirmation')?.setValidators(Validators.required);
    }

    // Mettez à jour la validation des champs "password" et "passwordConfirmation"
    this.redirectFormGroup.get('password')?.updateValueAndValidity();
    this.redirectFormGroup.get('passwordConfirmation')?.updateValueAndValidity();
  }

// Fonction de validation personnalisée pour vérifier si les mots de passe correspondent
  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('passwordConfirmation')?.value;

    if (password !== confirmPassword) {
      formGroup.get('passwordConfirmation')?.setErrors({ passwordMismatch: true });
    } else {
      formGroup.get('passwordConfirmation')?.setErrors(null);
    }
  }


  private loadRedirectData(id: any): void {
    this.usersService.getRedirect(id).subscribe(
      (response) => {
        // Remplissez le formulaire avec les données de la redirection
        this.redirectFormGroup.patchValue(response.data);
      },
      (error) => {
        // Gérez les erreurs, par exemple affichez un message d'erreur
        this.toastService.showToast('Failed to load redirect data.', 'Error!', 'error');
      }
    );
  }

  onSubmit() {
    const formData = this.redirectFormGroup.value; // Obtenez les valeurs du formulaire

    // Vérifiez si l'ID existe, ce qui indique une mise à jour
    if (formData.id) {
      // Effectuez une mise à jour en utilisant le service de redirection
      this.usersService.updateRedirect(formData.id, formData).subscribe(
        (response) => {
          // Gérez la réponse de manière appropriée, par exemple affichez un message de succès
          this.toastService.showToast('Redirect updated successfully.', 'Success!', 'success');
          this.closeDrawer();
        },
        (error) => {
          // Gérez les erreurs, par exemple affichez un message d'erreur
          this.toastService.showToast('Failed to update redirect.', 'Error!', 'error');
        }
      );
    } else {
      // Si l'ID n'existe pas, cela signifie qu'il s'agit d'une création
      // Effectuez une création en utilisant le service de redirection
      this.usersService.createRedirect(formData).subscribe(
        (response) => {
          // Gérez la réponse de manière appropriée, par exemple affichez un message de succès
          this.toastService.showToast('Redirect created successfully.', 'Success!', 'success');
          this.closeDrawer();
        },
        (error) => {
          // Gérez les erreurs, par exemple affichez un message d'erreur
          this.toastService.showToast('Failed to create redirect.', 'Error!', 'error');
        }
      );
    }
  }

  delete() {
    const redirectId = this.redirectFormGroup.value.id; // Récupérez l'ID de la redirection depuis le formulaire

    if (redirectId) {
      // Si l'ID de la redirection existe, appelez la méthode de suppression du service
      this.usersService.deleteRedirect(redirectId).subscribe(
        (response) => {
          // Gérez la réponse de manière appropriée, par exemple affichez un message de succès
          this.toastService.showToast('Redirect deleted successfully.', 'Success!', 'success');
          this.closeDrawer();
        },
        (error) => {
          // Gérez les erreurs, par exemple affichez un message d'erreur
          this.toastService.showToast('Failed to delete redirect.', 'Error!', 'error');
        }
      );
    } else {
      // Si l'ID de la redirection n'existe pas, affichez un message d'erreur
      this.toastService.showToast('Redirect ID not found.', 'Error!', 'error');
    }
  }

  // Méthode pour fermer le drawer
  closeDrawer(): void {
    this.drawerRef.close();
  }


}
