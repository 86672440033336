import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {WidgetsService} from "../../services/widgets.service";
import {ToastService} from "../../../../services/toast/toast.service";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";
import {HttpEventType, HttpResponse} from "@angular/common/http";

@Component({
  selector: 'app-widget-form',
  templateUrl: './widget-form.component.html',
  styleUrls: ['./widget-form.component.scss'],
})
export class WidgetFormComponent implements OnInit {
  @Input() widget: any;
  @Input() locales: any;
  @Input() dropdownPages: any;
  @Input() dropdownOptions: any;
  @Input() widgets_classes: any;



  public options: Object = {
    toolbarButtons: {
      // Key represents the more button from the toolbar.
      moreText: {
        // List of buttons used in the  group.
        buttons: ['paragraphFormat', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

        // Alignment of the group in the toolbar.
        align: 'left',

        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
        buttonsVisible: 3
      },


      moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
        align: 'left',
        buttonsVisible: 3
      },

      moreRich: {
        buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
        align: 'left',
        buttonsVisible: 3
      },

      moreMisc: {
        buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'codeBeautifier', 'help'],
        align: 'right',
        buttonsVisible: 2
      }
    },

    toolbarButtonsXS: [['undo', 'redo'], ['bold', 'italic', 'underline']],


    // Additional upload params.
    imageUploadParams: {id: 'my_editor'},

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],


    imageUploadRemoteUrls: false,
    events: {
      "image.beforeUpload": function (files: any) {
        var editor: any = this;
        if (files.length) {
          // Create a File Reader.
          var reader = new FileReader();
          // Set the reader to insert images when they are loaded.
          reader.onload = function (e) {
            var result = e?.target?.result;
            editor.image.insert(result, null, null, editor.image.get());
          };
          // Read image as base64.
          reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      }
    },

    htmlRemoveTags: [],


  };


  constructor(
    private drawerRef: NzDrawerRef,
    private formBuilder: FormBuilder,
    private WidgetsService: WidgetsService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private widgetsService: WidgetsService,
  ) {

  }


  pageFormGroup!: FormGroup;
  titleFormArray!: FormArray;





  ngOnInit(): void {



    this.dropdownOptions = this.generateDropdownOptions(this.dropdownPages);
    const titleFormArrayControls = this.locales.map((locale: any) => this.createTitleFormGroup(locale));
    this.titleFormArray = this.formBuilder.array(titleFormArrayControls);

    this.locales.forEach((locale: any) => {


      this.titleFormArray = this.formBuilder.array(
        this.locales.map((locale: any) => this.createTitleFormGroup(locale))
      );
    });


    this.pageFormGroup = this.formBuilder.group({
      id: [null],
      page_id: [null],
      widget_id: [null],
      rank: [null],
      title: [null, Validators.required],
      model: [null],
      entity_id: [null],
      limit: [null],
      titleForm: this.titleFormArray,
    });


    this.loadWidget();

  }

  loadWidget() {
    if (this.widget) {

      this.pageFormGroup.get('id')?.setValue(this.widget?.id)
      this.pageFormGroup.get('page_id')?.setValue(this.widget?.page_id)
      this.pageFormGroup.get('widget_id')?.setValue(this.widget?.widget_id)
      this.pageFormGroup.get('rank')?.setValue(this.widget?.rank)
      this.pageFormGroup.get('title')?.setValue(this.widget?.title)

      this.pageFormGroup.get('model')?.setValue(this.widget?.model)
      this.pageFormGroup.get('entity_id')?.setValue(this.widget?.entity_id)
      this.pageFormGroup.get('limit')?.setValue(this.widget?.limit)


      this.widget.translations.forEach((translation: any) => {
        // Trouvez l'index de la traduction dans le tableau des langues
        const index = this.locales.findIndex((locale: any) => locale === translation.locale);
        if (index !== -1) {
          this.titleFormArray.at(index).patchValue(translation);
          const configuration = translation.configuration;
          if (configuration) {
            const configObject = JSON.parse(configuration);
            for (const [key, value] of Object.entries(configObject)) {
              console.log(`${key}: ${value}`);
              this.titleFormArray.at(index).patchValue({
                [key]: value
              });

            }

          }
        }
      });

    }
  }


  widgetFields!:any[];



  createTitleFormGroup(locale: any): FormGroup {
    const formGroup: any = {
      id: [''],
      title: [''],
      content: [''],
      locale: [locale]
    };

    const configuration = this.widget?.widget?.configuration;
    if (configuration) {
      const configObject = JSON.parse(configuration);
      this.widgetFields = configObject.fields;
      if (configObject && configObject.fields) {
        configObject.fields.forEach((field: any) => {
          formGroup[field.id] = [null];
          if (field.required) {
            formGroup[field.id].push(Validators.required);
          }
        });
      }
    }

    return this.formBuilder.group(formGroup);
  }

  getTitleFormControl(locale: string, fieldName: string): FormControl {
    const control = this.titleFormArray.controls.find(control => control.get('locale')?.value === locale)?.get(fieldName) as FormControl;

    if (control && !control.value) {
      control.patchValue(this.widget.widget.source_html);
    }

    return control;
  }

  getTitleFormGroup(index: any): FormGroup {
    return this.titleFormArray.at(index) as FormGroup;
  }


  onSubmit(): void {
    const pageData = this.pageFormGroup.value;

    this.WidgetsService.update(pageData).subscribe(
      response => {


        this.toastService.showToast('Widget updated successfully', 'Success!', 'success');
        this.drawerRef.close();
      },
      error => {
        // Gérer les erreurs
        console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
      }
    );

  }

  delete() {

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Would you like to delete this widget?',
        text: 'This action is irreversible and will permanently delete the widget.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.WidgetsService.delete(this.widget?.id).subscribe(
          response => {

            this.toastService.showToast('Widget deleted successfully', 'Success!', 'success');
            this.drawerRef.close();
          },
          error => {
            // Gérer les erreurs
            console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
          }
        );
      }
    });



  }


  generateDropdownOptions(pages: any[], level = 0): any[] {
    const options = [];
    for (const page of pages) {
      console.log(page.title)
      options.push({
        id: page.id,
        title: '-'.repeat(level) + ' ' + page.title
      });
      if (page.children && page.children.length > 0) {
        console.log(page.title)
        options.push(...this.generateDropdownOptions(page.children, level + 1));
      }
    }
    return options;
  }


  @ViewChild('contentTextarea') contentTextarea!: ElementRef;

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight + 2) + 'px';
  }



  handleFileInputChange(event: Event, fieldName: string, locales: string[], index:any): void {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    if (files && files.length > 0) {
      this.locales.forEach((locale: string, index: number) => {
        const titleValue = this.getTitleFormGroup(index).get('title')?.value; // Récupérez la valeur du champ "title" du formulaire
        const descriptionValue = this.getTitleFormGroup(index).get('description')?.value;
        const contentValue = this.getTitleFormGroup(index).get('content')?.value;

        this.uploadFile(files[0], fieldName, locale, titleValue, descriptionValue, contentValue, index);
      });
    }
  }

  updateLinkValue(event: any, fieldName: string) {
    const newValue = event.value;
    const localesFormArray = this.pageFormGroup.get('titleForm') as FormArray;
    this.locales.forEach((locale: string, index: number) => {
      const titleValue = this.getTitleFormGroup(index).get(fieldName)?.value; // Récupérez la valeur du champ "title" du formulaire


      // Mettre à jour le champ image dans le FormArray
      const imageUrlControl = this.titleFormArray.at(index).get('link');
      if (imageUrlControl) {
        imageUrlControl.setValue(newValue);
        imageUrlControl.markAsDirty();
      }

    });
  }





  uploadFile(file: File, fieldName: string, locale: string, title: any, description: any, content: any, index: number): void {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('locale', locale);

    if (title) {
      formData.append('title', title);
    }

    if (description) {
      formData.append('description', description);
    }

    if (content) {
      formData.append('content', content);
    }

    this.widgetsService.uploadFile(formData, this.widget?.id).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          console.log(`File is ${percentDone}% uploaded.`);
        } else if (event instanceof HttpResponse) {
          const imageUrl = event.body.imageUrl;

          this.updateImageSource(index, imageUrl, fieldName);
          this.toastService.showToast('File uploaded successfully', 'Success', 'success');
        }
      },
      (error) => {
        console.error('Error uploading file:', error);
        this.toastService.showToast('Error uploading file', 'Error', 'error');
      }
    );
  }

  updateImageSource(index: number, imageUrl: string, fieldName: string): void {
    const translation = this.widget.translations[index];
    if (translation) {
      const configuration = translation.configuration ? JSON.parse(translation.configuration) : {};
      configuration[fieldName] = imageUrl; // Utiliser le champ dynamique
      translation.configuration = JSON.stringify(configuration);

      // Mettre à jour le champ image dans le FormArray
      const imageUrlControl = this.titleFormArray.at(index).get(fieldName);
      if (imageUrlControl) {
        imageUrlControl.setValue(imageUrl);
        imageUrlControl.markAsDirty();
      }
    }
  }





  getTranslationConfiguration(index: number): any {
    if (this.widget.translations && this.widget.translations[index]) {
      const configuration = this.widget.translations[index].configuration;
      return configuration ? JSON.parse(configuration) : null;
    }
    return null;
  }


  translate() {
    const pageData = this.pageFormGroup.value;

    this.widgetsService.translate(pageData).subscribe(
      response => {

        // Pour chaque traduction reçue
        response.forEach((translation: any) => {
          // Trouvez l'index de la langue dans le tableau des langues supportées
          const index = this.locales.findIndex((locale: any) => locale === translation.locale);
          if (index !== -1) {
            // Si la langue est trouvée, patchez les valeurs dans le FormGroup correspondant
            this.titleFormArray.at(index).patchValue(translation);
          }
        });

        this.toastService.showToast('Page translated successfully', 'Success!', 'success');

      },
      error => {
        // Gérer les erreurs
        console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
      }
    );
  }

}
