<span class="popHover" nbPopoverPlacement="left" [nbPopover]="template" nbPopoverTrigger="click">
  <nb-user  size="medium" name="{{contact?.full_name}}"></nb-user>
</span>

<ng-template #template>

  <nb-card class="pop-over p-0 margin-bottom-0">
    <nb-card-header class="header-click" (click)="openDashboard(contact)">
      <nb-user size="medium"  name="{{contact?.full_name}}">

      </nb-user>

    </nb-card-header>

    <nb-card-body>
      <nb-list-item *ngIf="contact?.full_address">
        <strong>Address:</strong>
        <div [innerHTML]="contact?.full_address"></div>
      </nb-list-item>


      <nb-list-item *ngFor="let communication of contact?.communications">
        <ng-container *ngIf="communication.communication_type === 'phone'">
          <strong style="margin-right:5px;">
            <nb-icon icon="phone-outline" style="color:#000!important;"></nb-icon>
            Phone:</strong> {{ communication.value }} ({{ communication.communication_place }})
        </ng-container>
        <ng-container *ngIf="communication.communication_type === 'email'">
          <strong style="margin-right:5px;">
            <nb-icon icon="email-outline" style="color:#000!important;"></nb-icon>
            Email:</strong> {{ communication.value }} ({{ communication.communication_place }})
        </ng-container>
        <ng-container *ngIf="communication.communication_type === 'social'">
          <strong style="margin-right:5px;">
            <nb-icon icon="link-2-outline" style="color:#000!important;"></nb-icon>
            Social:</strong> <a [href]="communication.value" target="_blank">{{ communication.communication_place }}</a>
        </ng-container>
      </nb-list-item>

    </nb-card-body>

  </nb-card>
</ng-template>

