

<nb-alert *ngIf="!photos?.length">No photo found</nb-alert>

<div #dropListContainer class="example-container" cdkDropListGroup>

  <label class="example-box upload-input">
    <nb-icon icon="plus-circle-outline" class="icon-plus" style="color:#000!important;"></nb-icon>
    <input id="fileInput" type="file" multiple (change)="onFileChange($event)" style="display: none" />
  </label>

  <div
    *ngFor="let preview of photos; let i = index"
    cdkDropList
    [cdkDropListData]="i"
  >
    <div
      cdkDrag
      [cdkDragData]="i"
      (cdkDragEntered)="dragEntered($event)"
      (cdkDragMoved)="dragMoved($event)"
      (cdkDragDropped)="dragDropped($event)"
      class="example-box"
    >
      <img [src]="preview?.previewUrl ? preview?.previewUrl : preview?.full_url" class="image-preview" />

      <button nbButton class="btn-ico-w" size="tiny" type="button" (click)="deleteImage(preview?.id)">
        <nb-icon icon="trash-2-outline" style="color:#000!important;"></nb-icon>
      </button>

    </div>
  </div>
</div>

