import {ChangeDetectorRef, Component, Input, Optional, Output, EventEmitter} from '@angular/core';
import {ContactsComponent} from "../../modules/contacts/pages/contacts/contacts.component";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {HttpClient} from "@angular/common/http";
import {NbDateService} from "@nebular/theme";
import {DatePipe} from "@angular/common";
import {ModalFormContactComponent} from "../../modules/contacts/components/modal-form-contact/modal-form-contact.component";
import {GetConfigurationService} from "../../services/getConfiguration/get-configuration.service";

@Component({
  selector: 'app-app-contacts-list',
  templateUrl: './app-contacts-list.component.html',
  styleUrls: ['./app-contacts-list.component.scss']
})
export class AppContactsListComponent {
  @Input() contacts: any[] = [];
  @Input() formGroup!: FormGroup;
  @Output() contactsChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  genders!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;
  locations!: any;


  constructor(
    private configurationService: GetConfigurationService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public _NzDrawerRef: NzDrawerRef,
    private http: HttpClient,
    dateService: NbDateService<Date>,
    private datePipe: DatePipe
  ) {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.priorities = datas?.data?.priorities;
      this.genders = datas?.data?.genders;
      this.locations = datas?.data?.locations;
      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;
    });
  }

  linkToContact(event: any) {

    event.preventDefault();

    let modalRef = this._NzDrawerService.create({
      nzContent: ContactsComponent,
      nzWidth: '80vw',
      nzWrapClassName: 'nopadding',
      nzTitle: "All contacts",

      nzContentParams: {
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((contact: any) => {
      if (contact && !this.contactExists(contact)) {
        this.contacts.push(contact);
        this.formGroup.setControl('contactsArray', this.buildContactsArray());
      }
    });
  }

  contactExists(newContact: any): boolean {
    return this.contacts.some(contact => contact.id === newContact.id);
  }

  buildContactsArray(): FormArray {
    return this.fb.array(this.contacts.map(contact => this.fb.control(contact.id)));
  }


  editContact(id: any) {
    let width = '800px';

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormContactComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new contact",
      nzContentParams: {
        contactId: id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,


        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((contactDatas: any) => {
      console.log(contactDatas);
      if (contactDatas?.data) {
        console.log(contactDatas?.data);


        const existingIndex = this.contacts.findIndex(contact => contact.id === contactDatas?.data.id);

        if (existingIndex !== -1) {
          // Remplacer l'élément existant
          this.contacts[existingIndex] = contactDatas?.data;
          this.formGroup.setControl('contactsArray', this.buildContactsArray());

        }
      }
    });


  }

  removeContact(contact: any): void {
    const index = this.contacts.indexOf(contact);
    if (index !== -1) {
      this.contacts.splice(index, 1);
      this.formGroup.setControl('contactsArray', this.buildContactsArray());
    }
  }

}
