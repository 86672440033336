import {Component, OnInit} from '@angular/core';
import {GetConfigurationService} from "../../services/getConfiguration/get-configuration.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  IS_REAL_ESTATE: any;
  ENABLE_AFTER_BEFORE: any;
  BASE_URL: any;

  constructor(
    private configurationService: GetConfigurationService,
  ) {

  }

  ngOnInit(): void {
    this.loadConfiguration();
  }

  private loadConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.IS_REAL_ESTATE = datas?.data?.IS_REAL_ESTATE;
      this.ENABLE_AFTER_BEFORE = datas?.data?.ENABLE_AFTER_BEFORE;
      this.BASE_URL = datas?.data?.BASE_URL;

    })
  }
}
