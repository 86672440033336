import {ChangeDetectorRef, Component, Optional} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {SliderFormComponent} from "../../../sliders/components/slider-form/slider-form.component";
import {AfterbeforeService} from "../../services/afterbefore.service";
import {AfterBeforeeFormComponent} from "../../form/after-beforee-form/after-beforee-form.component";

@Component({
  selector: 'app-after-before',
  templateUrl: './after-before.component.html',
  styleUrls: ['./after-before.component.scss']
})
export class AfterBeforeComponent {
  pages!: any;
  locales!: any;

  id: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private AfterbeforeService: AfterbeforeService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    deviceService: DeviceDetectorService,
  ) {
    this.refreshConfiguration();
  }

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.locales = datas?.data?.locales;
      this.cdr.detectChanges();
    });
  }



  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.loadPages();

  }

  loadPages() {
    this.AfterbeforeService.listPages().subscribe(datas => {
      this.pages = datas?.pages;
    });
  }

  drop(event: CdkDragDrop<any[]>) {

    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);

    this.AfterbeforeService.updateNodes(this.pages).subscribe(
      () => {
        console.log('Nodes updated successfully.');
      },
      (error) => {
        console.error('Error updating nodes:', error);
      }
    );


  }


  // Déclarez une propriété pour stocker les éléments étendus
  expandedItems: Set<any> = new Set();

// Méthode pour vérifier si un élément est étendu
  isExpanded(item: any): boolean {
    return this.expandedItems.has(item);
  }



  onNestedItemDropped(event: any): void {
    // Manipuler les données déposées depuis le composant enfant ici
    console.log('Item dropped in nested component:', event);
  }

  addPage(id:any) {


    let width = '85vw'


    let modalRef = this._NzDrawerService.create({
      nzContent: AfterBeforeeFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create / Update",
      nzContentParams: {

        locales : this.locales,
        pageId : id,

      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadPages();
    });
  }


}
