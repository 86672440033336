<ng-container   *ngIf="!isLoaded">
  <nz-skeleton [nzActive]="true"  [nzParagraph]="{ rows: 0 }" class="skeleton-1"></nz-skeleton>
  <nz-skeleton [nzActive]="true"  [nzParagraph]="{ rows: 0 }" class="skeleton-1"></nz-skeleton>
</ng-container>

<div class="mb-3"  *ngIf="isLoaded">
  <div class="input-group">

    <span class="input-group-text" *ngIf="icon"><i class="{{icon}}"></i></span>
    <div class="form-floating">
      <input
        [type]="type"
        class="form-control"
        [ngClass]="{'is-invalid': (!control?.valid && control?.dirty)}"
        [id]="name"
        [placeholder]="placeholder"
        [(ngModel)]="inputValue"
        [required]="isRequiredControl"
        value="{{inputValue}}"
        (input)="onInputChange()"
      >


      <label [for]="name">{{ label }} <small *ngIf="isRequiredControl">*</small></label>

      <div *ngIf="shouldShowError()" class="invalid-feedback">
        {{ getErrorMessage() }}
      </div>


    </div>
    <span class="input-group-text" *ngIf="after">{{after}}</span>
  </div>
  <div class="form-text" *ngIf="help">
    <nb-icon icon="question-mark-circle-outline"></nb-icon>
    {{help}}
  </div>
</div>
