import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from "rxjs/operators";
import {throwError} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class ClientSearchService {
  private apiUrl!: string; // URL de l'API de recherche de propriétés

  constructor(
    private http: HttpClient,
    private authService: AuthService // Injectez le service d'authentification
  ) {
    // Obtenez le domaine sélectionné à partir du service d'authentification
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/propertiesSearch`;
    } else {
      console.error('Selected domain is not set.');
    }
  }

  get(page: number, pageSize: number, formGroup:any, contact_id:any, property_id:any): Observable<any> {
    const params = formGroup;

    let contact = '';
    if(contact_id) {
      contact = contact_id;
    }

    let property = '';
    if(property_id) {
      property = property_id;
    }

    return this.http.post(this.apiUrl + '?page=' + page.toString() + '&pageSize=' + pageSize.toString() + '&contact=' + contact.toString() +  '&property=' + property.toString(), { params });
  }

  createOrUpdate(formData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/createOrUpdate`, formData);
  }

  getDetail(searchId: string): Observable<any> {
    // Make the API call to get property details
    return this.http.get(`${this.apiUrl}/get/${searchId}`);
  }


  delete(videoId: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/delete/` + videoId);
  }


  deleteProperties(id: any, properties: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/properties/delete-properties/` + id, { 'properties' : properties });
  }

  getProperties(id:number, page: number, pageSize: number): Observable<any> {
    return this.http.get(this.apiUrl + '/properties/' + id + '?page=' + page.toString() + '&pageSize=' + pageSize.toString());
  }


  pinProperties(id: any, properties: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/properties/pin-properties/` + id, { 'properties' : properties });
  }

  unPinProperties(id: any, pinId: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/properties/unpin-properties/` + id + '/' + pinId, {});
  }

}
