import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {AuthService} from "../../../../services/auth/auth.service";

@Component({
  selector: 'app-widget-choose',
  templateUrl: './widget-choose.component.html',
  styleUrls: ['./widget-choose.component.scss']
})
export class WidgetChooseComponent {
  @Input() widgets: any;
  @Input() template: any;
  widgetForm: FormGroup;


  constructor(
    private drawerRef: NzDrawerRef,
    private formBuilder: FormBuilder
  ) {
    this.widgetForm = this.formBuilder.group({
      widgetId: [null, Validators.required],
      isHtml: ['dynamic']
    });



  }

  selectWidget(id: any): void {
    this.widgetForm.get('widgetId')?.setValue(id);
    this.widgetForm.get('widgetId')?.updateValueAndValidity();
  }

  onSubmit(): void {
    const formData = this.widgetForm.value;
    this.drawerRef.close(formData);
  }

  isButtonDisabled(): boolean {
    // Vérifier si aucun widget n'est sélectionné
    return this.widgets.every((widget: any) => !widget.selected);
  }
}
