<form [formGroup]="widgetForm" (ngSubmit)="onSubmit()">
  <nb-card>
    <nb-card-body>


      <!-- Formulaire de sélection -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Select Widget type</mat-label>
        <mat-select formControlName="isHtml">
          <mat-option value="dynamic">Dynamic</mat-option>
          <mat-option value="static">Static</mat-option>
        </mat-select>
      </mat-form-field>

      <nb-alert outline="basic">Dynamic: Represents a widget generated from database content.<br/>Static: Represents static HTML content</nb-alert>

      <div class="row">
        <div *ngFor="let widget of widgets" class="col-6">
          <nb-card>
            <nb-card-body>
              <img [src]="widget.preview_image" alt="Preview Image" width="100%"
                   (click)="selectWidget(widget?.id)"
                   [class.selected]="this.widgetForm?.get('widgetId')?.value == widget?.id">
            </nb-card-body>
          </nb-card>
        </div>
      </div>
    </nb-card-body>

    <nb-card-footer>
      <button nbButton status="primary" class="btn-ico-w" style="float:right" [disabled]="!widgetForm.valid">
        <nb-icon icon="save-outline"></nb-icon>
        <span>Save</span>
      </button>
    </nb-card-footer>
  </nb-card>
</form>
