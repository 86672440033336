// http-interceptor.service.ts

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpLoaderService} from "./http-loader.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private httpLoaderService: HttpLoaderService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.httpLoaderService.showLoader(); // Affichez le spinner avant la requête

    return next.handle(request).pipe(
      finalize(() => {
        this.httpLoaderService.hideLoader(); // Masquez le spinner après que la requête est terminée (qu'elle réussisse ou échoue)
      })
    );
  }
}
