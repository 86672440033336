
<nb-card>
  <nb-card-body class="p-0">
    <nb-accordion multi>
      <nb-accordion-item [expanded]="true">
        <nb-accordion-item-header>
          <nb-icon icon="alert-triangle-outline" class="red-color"
                   *ngIf="!isLoaded && (formGroup?.get('categories')?.invalid || formGroup?.get('reference')?.invalid || formGroup?.get('price')?.invalid)"></nb-icon>
          <nb-icon icon="checkmark-circle-2-outline" class="green-color"
                   *ngIf="!isLoaded && (formGroup?.get('categories')?.valid && formGroup?.get('reference')?.valid && formGroup?.get('price')?.valid)"></nb-icon>
          Main informations
        </nb-accordion-item-header>
        <nb-accordion-item-body>





          <div class="row" *ngIf="propertyId">
            <div class="col-12">

              <app-select-single
                [control]="getFormControl('is_available')"
                [name]="'is_available'"
                [isLoaded]="isLoaded"
                [options]="properties_status"
                [placeholder]="'Status'"
                [label]="'Status'"
                (valueChange)="onFormControlChange('is_available', $event)"
              ></app-select-single>
            </div>
          </div>



          <div class="row">
            <div class="col-12">

              <app-input-chips
                [control]="getFormControl('categories')"
                [name]="'categories'"
                [isLoaded]="isLoaded"
                [options]="categories"
                [placeholder]="'Type a category name'"
                [label]="'categories'"
                (valueChange)="onFormControlChange('categories', $event)"
              ></app-input-chips>
            </div>
          </div>


          <div class="row">
            <div class="col-12">

              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('reference')"
                              [name]="'reference'"
                              [placeholder]="'Enter a reference'"
                              [label]="'Reference'"
                              [type]="'text'"
                              (valueChange)="onFormControlChange('reference', $event)"
              ></app-input-text>

            </div>
            <div class="col-12" *ngIf="isSearch && !isClientSearch">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('shared_reference')"
                              [name]="'shared_reference'"
                              [placeholder]="'Enter a value'"
                              [label]="'Shared reference'"
                              [type]="'text'"
                              [help]="'If the property is from another agent'"
                              (valueChange)="onFormControlChange('shared_reference', $event)"
              ></app-input-text>
            </div>
          </div>


          <div class="row" *ngIf="!isSearch">
            <div class="col-12">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('price')"
                              [name]="'price'"
                              [placeholder]="'Price'"
                              [type]="'number'"
                              [label]="'Price'"
                              (valueChange)="onFormControlChange('price', $event)"
              ></app-input-text>
              <app-input-checkbox
                [isLoaded]="isLoaded"
                [control]="getFormControl('exclusivity')"
                [name]="'exclusivity'"
                [label]="'Exclusive property'"
                (valueChange)="onFormControlChange('exclusivity', $event)"
              ></app-input-checkbox>
            </div>
          </div>


          <div class="row" *ngIf="isSearch">
            <div class="col-12">
              <h3><i class="fa-solid fa-handshake"></i> Price</h3>
            </div>

            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('min_price')"
                              [name]="'min_price'"
                              [type]="'number'"
                              [label]="'Min'"
                              (valueChange)="onFormControlChange('min_price', $event)"
              ></app-input-text>

            </div>
            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('max_price')"
                              [name]="'max_price'"
                              [type]="'number'"
                              [label]="'Max'"
                              (valueChange)="onFormControlChange('max_price', $event)"
              ></app-input-text>
            </div>
          </div>

        </nb-accordion-item-body>
      </nb-accordion-item>


      <nb-accordion-item  [expanded]="isSearch">
        <nb-accordion-item-header>
          <nb-icon icon="alert-triangle-outline" class="red-color"
                   *ngIf="!isSearch && (formGroup?.get('location')?.invalid)"></nb-icon>
          <nb-icon icon="checkmark-circle-2-outline" class="green-color"
                   *ngIf="!isSearch && (formGroup?.get('location')?.valid)"></nb-icon>
          Location
        </nb-accordion-item-header>
        <nb-accordion-item-body>

          <div class="row">
            <div [ngClass]="{'col-12': isSearch, 'col-12 col-md-6': !isSearch}">
              <app-input-autocomplete
                [control]="getFormControl('location')"
                [name]="'location'"
                [options]="locations"
                [placeholder]="'Location'"
                [label]="'Location'"
                [type]="'text'"
                (valueChange)="updateLocation('location', $event)"
              ></app-input-autocomplete>
            </div>

            <div [ngClass]="{'col-12': isSearch, 'col-12 col-md-6': !isSearch}" *ngIf="areas && formGroup.get('location')?.value">
              <app-input-autocomplete
                [control]="getFormControl('area')"
                [name]="'area'"
                [options]="areas"
                [placeholder]="'Area of ' + locationName"
                [label]="'Area'"
                [type]="'text'"
                (valueChange)="onFormControlChange('area', $event)"
              ></app-input-autocomplete>
            </div>
          </div>

          <div class="row" *ngIf="!isSearch">
            <div class="col-12">
              <app-html-editor
                [control]="getFormControl('address')"
                [name]="'address'"
                [placeholder]="'Address'"
                [label]="'Address'"
                (valueChange)="onFormControlChange('address', $event)"
              ></app-html-editor>
            </div>


          </div>


        </nb-accordion-item-body>
      </nb-accordion-item>


      <nb-accordion-item   *ngIf="!isSearch">
        <nb-accordion-item-header>
          <nb-icon *ngIf="!isSearch" icon="checkmark-circle-2-outline" class="green-color"></nb-icon>
          Property details
        </nb-accordion-item-header>
        <nb-accordion-item-body>


          <div class="row">

            <div [ngClass]="{'col-12': isSearch, 'col-12 col-md-6': !isSearch}">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('bedrooms')"
                              [icon]="'fa-solid fa-bed'"
                              [name]="'bedrooms'"
                              [type]="'number'"
                              [label]="'Number of bedrooms'"
                              (valueChange)="onFormControlChange('bedrooms', $event)"
              ></app-input-text>

            </div>
            <div [ngClass]="{'col-12': isSearch, 'col-12 col-md-6': !isSearch}">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('bathrooms')"
                              [icon]="'fa-solid fa-shower'"
                              [name]="'bathrooms'"
                              [type]="'number'"
                              [label]="'Number of bathrooms'"
                              (valueChange)="onFormControlChange('bathrooms', $event)"
              ></app-input-text>
            </div>
          </div>


          <div class="row">

            <div [ngClass]="{'col-12': isSearch, 'col-12 col-md-6': !isSearch}">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('size')"
                              [name]="'size'"
                              [label]="'Build size'"
                              [type]="'number'"
                              [after]="'m²'"
                              (valueChange)="onFormControlChange('size', $event)"
              ></app-input-text>

            </div>
            <div [ngClass]="{'col-12': isSearch, 'col-12 col-md-6': !isSearch}">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('lot_size')"
                              [name]="'lot_size'"
                              [label]="'Plot size'"
                              [type]="'number'"
                              [after]="'m²'"
                              (valueChange)="onFormControlChange('lot_size', $event)"
              ></app-input-text>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>


      <!-- search -->
      <nb-accordion-item [expanded]="true"  *ngIf="isSearch">
        <nb-accordion-item-header>
          <nb-icon *ngIf="!isSearch" icon="checkmark-circle-2-outline" class="green-color"></nb-icon>
          Property details
        </nb-accordion-item-header>
        <nb-accordion-item-body>


          <div class="row">
            <div class="col-12">
              <h3><i class="fa-solid fa-bed"></i> Bedrooms</h3>
            </div>

            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('min_bedrooms')"
                              [name]="'min_bedrooms'"
                              [type]="'number'"
                              [label]="'Min'"
                              (valueChange)="onFormControlChange('min_bedrooms', $event)"
              ></app-input-text>

            </div>
            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('max_bedrooms')"
                              [name]="'max_bedrooms'"
                              [type]="'number'"
                              [label]="'Max'"
                              (valueChange)="onFormControlChange('max_bedrooms', $event)"
              ></app-input-text>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <h3><i class="fa-solid fa-bath"></i>  Bathrooms</h3>
            </div>

            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('min_bathrooms')"
                              [name]="'min_bathrooms'"
                              [type]="'number'"
                              [label]="'Min'"
                              (valueChange)="onFormControlChange('min_bathrooms', $event)"
              ></app-input-text>

            </div>
            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('max_bathrooms')"
                              [name]="'max_bathrooms'"
                              [type]="'number'"
                              [label]="'Max'"
                              (valueChange)="onFormControlChange('max_bathrooms', $event)"
              ></app-input-text>
            </div>
          </div>



          <div class="row">
            <div class="col-12">
              <h3><i class="fa-solid fa-house"></i> Size (m²)</h3>
            </div>

            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('min_size')"
                              [name]="'min_size'"
                              [type]="'number'"
                              [label]="'Min'"
                              (valueChange)="onFormControlChange('min_size', $event)"
              ></app-input-text>

            </div>
            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('max_size')"
                              [name]="'max_size'"
                              [type]="'number'"
                              [label]="'Max'"
                              (valueChange)="onFormControlChange('max_size', $event)"
              ></app-input-text>
            </div>
          </div>



          <div class="row">
            <div class="col-12">
              <h3><i class="fa-solid fa-tree"></i> Plot size (m²)</h3>
            </div>

            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('min_lot_size')"
                              [name]="'min_lot_size'"
                              [type]="'number'"
                              [label]="'Min'"
                              (valueChange)="onFormControlChange('min_lot_size', $event)"
              ></app-input-text>

            </div>
            <div class="col-12 col-md-6">
              <app-input-text [isLoaded]="isLoaded"
                              [control]="getFormControl('max_lot_size')"
                              [name]="'max_lot_size'"
                              [type]="'number'"
                              [label]="'Max'"
                              (valueChange)="onFormControlChange('max_lot_size', $event)"
              ></app-input-text>
            </div>
          </div>



        </nb-accordion-item-body>
      </nb-accordion-item>


      <nb-accordion-item *ngIf="!isSearch">



        <nb-accordion-item-header>
          <nb-icon icon="alert-triangle-outline" class="red-color"
                   *ngIf="formGroup?.get('translations')?.invalid"></nb-icon>
          <nb-icon icon="checkmark-circle-2-outline" class="green-color"
                   *ngIf="formGroup?.get('translations')?.valid"></nb-icon>

          Description
        </nb-accordion-item-header>



        <nb-accordion-item-body>


          <div class="row">
            <div class="col-12 col-md-8">
              <button nbButton status="primary" size="tiny" class="btn-ico-w" (click)="translate()" type="button">
                <nb-icon icon="globe-outline"></nb-icon>
                <span>Automatic translation</span>
              </button>

            </div>
          </div>

          <nb-tabset fullWidth>
            <nb-tab *ngFor="let tab of locales; let i = index" >
              <ng-template nbTabTitle>
                <img src="/assets/images/flags/{{tab}}.svg" width="30"/>
                <nb-badge text="!" status="danger" position="top right"  *ngIf="formGroup?.get('translations.title_' + tab)?.invalid || formGroup?.get('translations.description_' + tab)?.invalid"></nb-badge>
              </ng-template>


              <app-input-translatable
                [locale]="tab"
                [isLoaded]="isLoaded"
                [control]="formGroup?.get('translations.title_' + tab)"
                [type]="'text'"
                [label]="'Title'"
                (valueChange)="onFormControlChangeTranslations('title_' + tab, $event)"
              ></app-input-translatable>

              <app-editor-translatable
                [locale]="tab"
                [isLoaded]="isLoaded"
                [control]="formGroup?.get('translations.description_' + tab)"
                [type]="'text'"
                [label]="'Description'"
                (valueChange)="onFormControlChangeTranslations('description_' + tab, $event)"
              ></app-editor-translatable>




            </nb-tab>
          </nb-tabset>




        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>


  </nb-card-body>
  <nb-card-footer style="text-align:right;" >


    <button nbButton status="primary" class="btn-ico-w" [disabled]="!formGroup.valid" *ngIf="!isSearch">
      <nb-icon icon="save-outline"></nb-icon>
      <span *ngIf="!propertyId">Add property</span>
      <span *ngIf="propertyId">Update property</span>
    </button>


    <button nbButton status="primary" type="button" (click)="search()" class="btn-ico-w"  [disabled]="!formGroup.valid" *ngIf="isSearch && !isClientSearch">
      <nb-icon icon="search-outline"></nb-icon>
      <span>Search</span>
    </button>




  </nb-card-footer>
</nb-card>
