<nb-layout>
  <nb-layout-header fixed>


    <nb-actions size="small">
      <nb-action>
        <img src="https://www.villalingo.com/templates/villalingo/images/logo.svg" height="45" />
      </nb-action>
    </nb-actions>
  </nb-layout-header>

  <!-- Contenu principal -->
  <nb-layout-column>


    <div class="container">
      <router-outlet></router-outlet>





    </div>
  </nb-layout-column>
</nb-layout>
