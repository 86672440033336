import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PropertiesService} from "../../../properties/services/properties.service";
import {ContactRequestsService} from "../../services/contactRequests.service";
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-contact-requests',
  templateUrl: './contact-requests.component.html',
  styleUrls: ['./contact-requests.component.scss']
})
export class ContactRequestsComponent  extends Share  implements OnInit, AfterViewInit {
  isLoadingResults = true;
  currentPage = 1;
  pageSize = 10;
  totalProperties = 0;
  isInDrawer = false;
  properties: any[] = [];


  displayedColumns: string[] = [
    'site_url',
    'name',
    'phone',

    'message',
    'created_at',
  ];

  constructor(
    deviceService: DeviceDetectorService,
    private contactRequestsService: ContactRequestsService,
  ) {
    super('Child', deviceService);

  }

  ngOnInit(): void {
    this.loadContacts();
  }

  ngAfterViewInit(): void {
  }

  loadContacts(): void {
    this.isLoadingResults = true;
    this.contactRequestsService.getProperties(this.currentPage, this.pageSize).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.properties = response.data?.data;
        this.totalProperties = response.data?.total;
      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );
  }

  calculateTotalPages(): number {
    return Math.ceil(this.totalProperties / this.pageSize);
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.loadContacts();
  }


  @ViewChild('tableContainer') tableContainer!: ElementRef;


  isMouseDown: boolean = false;
  startX!: number;
  startScrollLeft!: number;


  onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
    this.startX = event.pageX - this.tableContainer.nativeElement.offsetLeft;
    this.startScrollLeft = this.tableContainer.nativeElement.scrollLeft;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown) return;
    const x = event.pageX - this.tableContainer.nativeElement.offsetLeft;
    const distance = x - this.startX;
    this.tableContainer.nativeElement.scrollLeft = this.startScrollLeft - distance;
  }

  onMouseUp() {
    this.isMouseDown = false;
  }

  addProperty(id?: any) {


  }
}
