<ng-container   *ngIf="!isLoaded">
  <nz-skeleton [nzActive]="true"  [nzParagraph]="{ rows: 0 }" class="skeleton-2"></nz-skeleton>
</ng-container>


  <div class="form-check" *ngIf="isLoaded">
  <label class="form-check-label">

  <input class="form-check-input" type="checkbox"

         [ngClass]="{'is-invalid': (!control?.valid && control?.dirty)}"
         [id]="name"
         [(ngModel)]="inputValue"
         [required]="isRequiredControl"
         (change)="onInputChange()" />
    {{ label }}
    <div *ngIf="shouldShowError()" class="invalid-feedback">
      {{ getErrorMessage() }}
    </div>

  </label>
</div>
