<div class="mb-4" >
  <div class="input-group">
    <input
      [ngClass]="{'is-invalid': (!control?.valid && control?.dirty)}"
      [matAutocomplete]="auto"
      class="autocomplete form-control"
      [formControl]="myControl"
      [placeholder]="placeholder"
      [required]="isRequiredControl"
      [id]="name"
    />

    <button class="remove-btn" type="button" *ngIf="myControl.value" matSuffix nbButton outline>
      <nb-icon icon="close-outline" (click)="clearValue()"></nb-icon>
    </button>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
