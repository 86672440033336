import {Component, Input, OnInit, Output, EventEmitter, Optional, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import {PropertiesService} from "../../services/properties.service";
import {SidebarFeatureFormComponent} from "../sidebar-feature-form/sidebar-feature-form.component";
import {SidebarFeatureGroupFormComponent} from "../sidebar-feature-group-form/sidebar-feature-group-form.component";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";

interface Feature {
  id: number;
  name: string;
}

interface FeatureGroup {
  id: number;
  name: string;
  features: Feature[];
}

@Component({
  selector: 'app-sidebar-features',
  templateUrl: './sidebar-features.component.html',
  styleUrls: ['./sidebar-features.component.scss']
})
export class SidebarFeaturesComponent {
  @Input() propertyId: any;
  @Input() checkedFeatures: any;
  @Input() locales: any;
  @Input() featureGroups: any;
  searchQuery!: string ;
  form!: FormGroup;

  constructor(
    private _sidebar: NzDrawerRef,
    private _router: Router,
    private http: HttpClient,
    private configurationService: GetConfigurationService,
    private drawerService: NzDrawerService,
    private _propertyService: PropertiesService,
    private formBuilder: FormBuilder,
    @Optional() public _NzDrawerService: NzDrawerService,
    private cdr: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});

    this.featureGroups.forEach((group: FeatureGroup) => {
      group.features.forEach((feature: Feature) => {
        // Initialiser les valeurs de formulaire en fonction des fonctionnalités cochées
        const isChecked = this.checkedFeatures.some((cf: Feature) => cf.id === feature.id);
        this.form.addControl(`feature_${feature.id}`, new FormControl(isChecked));
      });
    });
  }


  updateFeature() {
    const selectedFeatures = [];
    for (const group of this.featureGroups) {
      for (const feature of group.features) {
        const control = this.form.get(`feature_${feature.id}`);
        if (control?.value) {
          selectedFeatures.push(feature.id);
        }
      }
    }
    this._propertyService.updateFeatures(selectedFeatures, this.propertyId).subscribe(
      (updatedProperty: any) => {

      },
      (error) => {
        console.error('Une erreur s\'est produite lors de la mise à jour de la propriété:', error);
      }
    );
  }

  addFeature(group: any, feature = null) {
    let modalRef = this._NzDrawerService.create({
      nzContent: SidebarFeatureFormComponent,
      nzWidth: '500px',
      nzWrapClassName: 'nopadding',
      nzTitle: "Feature",

      nzContentParams: {
        feature: feature !== null ? feature : null,
        propertyId: this.propertyId,
        group: group,
        locales: this.locales,
      }
    });

    modalRef.afterClose.subscribe((updatedFeature: any) => {
      if (updatedFeature) {
        this.updateFeatureInGroup(group, updatedFeature);
      }
      this.configurationService.getConfiguration().subscribe(datas => {
        this.featureGroups = datas?.data?.featureGroups;
      });
      this.cdr.detectChanges();
    });
  }


  addFeatureGroup(group: null, feature = null) {
    let modalRef = this._NzDrawerService.create({
      nzContent: SidebarFeatureGroupFormComponent,
      nzWidth: '500px',
      nzWrapClassName: 'nopadding',
      nzTitle: "Group of feature",

      nzContentParams: {
        propertyId: this.propertyId,
        group: group,
        locales: this.locales,
      }
    });

    modalRef.afterClose.subscribe((updatedFeature: any) => {

      this.configurationService.getConfiguration().subscribe(datas => {
        this.featureGroups = datas?.data?.featureGroups;
      });

      this.cdr.detectChanges();
    });
  }



  private updateFeatureInGroup(group: any, updatedFeature: any) {
    const updatedFeatureId = +updatedFeature.id;

    const index = group.features.findIndex((f: any) => f.id === updatedFeatureId);

    if (index !== -1) {
      group.features[index] = updatedFeature;
    } else {
      if (!group.features) {
        group.features = [];
      }
      group.features.push(updatedFeature);
    }

    this.featureGroups.forEach((group: FeatureGroup) => {
      group.features.forEach((feature: Feature) => {
        const isChecked = this.checkedFeatures.some((cf: Feature) => cf.id === feature.id);
        this.form.addControl(`feature_${feature.id}`, new FormControl(isChecked));
      });
    });

  }


}
