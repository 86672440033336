import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {Share} from "../../../../class/share";
import {ActivatedRoute, Router} from "@angular/router";
import {SlidersService} from "../../services/sliders.service";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {PagesFormComponent} from "../../../pages/components/pages-form/pages-form.component";
import {SliderFormComponent} from "../../components/slider-form/slider-form.component";

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss']
})
export class SlidersComponent   extends Share  implements OnInit {
  pages!: any;
  locales!: any;

  id: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private slidersService: SlidersService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    deviceService: DeviceDetectorService,
  ) {
    super('Child', deviceService);
    this.refreshConfiguration();
  }

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.locales = datas?.data?.locales;


      this.cdr.detectChanges();
    });
  }



  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.loadPages();

  }

  loadPages() {
    this.slidersService.listPages(this.id).subscribe(datas => {
      this.pages = datas?.sliders;
    });
  }

  drop(event: CdkDragDrop<any[]>) {

    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);

    this.slidersService.updateNodes(this.pages).subscribe(
      () => {
        console.log('Nodes updated successfully.');
      },
      (error) => {
        console.error('Error updating nodes:', error);
      }
    );


  }


  // Déclarez une propriété pour stocker les éléments étendus
  expandedItems: Set<any> = new Set();

// Méthode pour vérifier si un élément est étendu
  isExpanded(item: any): boolean {
    return this.expandedItems.has(item);
  }



  onNestedItemDropped(event: any): void {
    // Manipuler les données déposées depuis le composant enfant ici
    console.log('Item dropped in nested component:', event);
  }

  addPage(id:any) {


    let width = '85vw'
    if(this.isMobile) {
      width = '100vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: SliderFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new slider",
      nzContentParams: {

        locales : this.locales,
        pageId : id,

      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadPages();
    });
  }


}
