import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PropertiesService} from "../../services/properties.service";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-sidebar-feature-form',
  templateUrl: './sidebar-feature-form.component.html',
  styleUrls: ['./sidebar-feature-form.component.scss']
})
export class SidebarFeatureFormComponent {
  @Input() propertyId!: any;
  @Input() group!: any;
  @Input() feature!: any;
  @Input() locales!: string[];

  @Output() propertyUpdated = new EventEmitter<any>();

  titleForm!: FormGroup;
  localeValues: { [key: string]: string } = {};
  constructor(

    private formBuilder: FormBuilder,
    private _PropertiesService: PropertiesService,
    private _NzDrawerRef: NzDrawerRef,
    private toastrService: NbToastrService,
    private toastService: ToastService,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    // Initialiser le formulaire avec la validation "required"
    this.titleForm = this.formBuilder.group({ });

    // Ajouter un champ pour chaque locale avec la validation "required"
    this.locales.forEach(locale => {
      const fieldName = `title_${locale}`;
      this.titleForm.addControl(fieldName, this.formBuilder.control('', Validators.required));
    });

    // Remplir les champs avec les valeurs du JSON
    if (this.feature) {
      // Si la fonctionnalité existe (c'est une mise à jour), remplissez les champs
      const translations = this.feature.translations;

      translations.forEach((translation: any) => {
        const locale = translation.locale;
        const fieldName = `title_${locale}`;
        const translationValue = translation.name;

        this.titleForm.get(fieldName)?.patchValue(translationValue);
      });
    }
  }



  onSubmit() {

    if (this.titleForm.invalid) {
      // Marquer tous les champs comme touchés pour afficher les messages d'erreur
      this.markFormGroupTouched(this.titleForm);
      return;
    }


    const localeValues: Record<string, string> = {}; // Déclarer le type de localeValues

    this.locales.forEach(locale => {
      localeValues[locale] = this.titleForm.get(`title_${locale}`)?.value;
    });

    const resultObject = {
      ...localeValues
    };

    const formValue = {
      id: this.feature?.id ?? null,
      titles: resultObject,
      groupId: this.group?.id,
    };


    this._PropertiesService.createOrUpdateFeature(formValue).subscribe(
      (response) => {
        this._NzDrawerRef.close(response?.data)},
      (error) => {
        console.error('Erreur lors de la mise à jour du groupe de fonctionnalités:', error);
      }
    );
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  confirmDeleteFeature() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Delete ?',
        text: 'Are you sure you want to delete the feature <strong>' + this.feature?.name + '</strong> ?',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info:any) => {
      if(info) {
        this._PropertiesService.deleteFeature(this.feature?.id).subscribe(
          (response) => {
            const featureIdControlName = `title_${this.feature?.id}`;
            this.titleForm.removeControl(featureIdControlName);
            this.titleForm.updateValueAndValidity();
            this.toastService.showToast(this.feature?.name + ' deleted successfully', 'Success!', 'success');
            this._NzDrawerRef.close()
          },
          (error) => {
            console.error('Erreur lors de la mise à jour du groupe de fonctionnalités:', error);
          }
        );
      }
    });

    return dialogRef;

  }
}
