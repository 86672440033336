// app-routing.module.ts
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CalendarComponent} from "./pages/calendar/calendar.component";
import {LoginComponent} from "./pages/login/login.component";
import {PropertiesComponent} from "./modules/properties/pages/properties/properties.component";
import {AuthGuard} from "./guards/auth/auth.guard";
import {LogoutComponent} from "./pages/logout/logout.component";
import {AdminComponent} from "./admin/admin.component";
import {ContactsComponent} from "./modules/contacts/pages/contacts/contacts.component";
import {ContactDetailComponent} from "./modules/contacts/contact-detail/contact-detail.component";
import {ClientSearchComponent} from "./modules/clientSearch/pages/client-search/client-search.component";
import {ClientSearchPropertiesListComponent} from "./modules/clientSearch/pages/client-search-properties-list/client-search-properties-list.component";
import {SettingsComponent} from "./modules/settings/settings.component";
import {LocationsComponent} from "./modules/locations/pages/locations/locations.component";
import {RegistrationsComponent} from "./modules/registrations/pages/registrations/registrations.component";
import {DashboardComponent} from "./modules/properties/pages/dashboard/dashboard.component";
import {PublicDetailPropertyComponent} from "./public_modules/properties/pages/public-detail-property/public-detail-property.component";
import {FrontComponent} from "./front/front.component";
import {GlobalDashboardComponent} from "./modules/dashboard/pages/global-dashboard/global-dashboard.component";
import {PagesComponent} from "./modules/pages/pages/pages.component";
import {GeneralSettingsComponent} from "./modules/genetalSettings/pages/general-settings/general-settings.component";
import {SlidersComponent} from "./modules/sliders/pages/sliders/sliders.component";
import {AfterBeforeComponent} from "./modules/afterBefore/pages/after-before/after-before.component";
import {ContactRequestsComponent} from "./modules/contactRequests/pages/contact-requests/contact-requests.component";
import {RedirectsComponent} from "./modules/redirects/pages/redirects/redirects.component";
import {UsersComponent} from "./modules/users/pages/users/users.component";

const routes: Routes = [

  { path: '', component: LoginComponent },

  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },


  {
    path: 'properties',
    component: FrontComponent,
    children: [
      { path: ':id', component: PublicDetailPropertyComponent },
    ],
  },

  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', component: GlobalDashboardComponent, canActivate: [AuthGuard] },

      { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },

      { path: 'properties', component: PropertiesComponent, canActivate: [AuthGuard] },
      { path: 'properties/dashboard/:id', component: DashboardComponent, canActivate: [AuthGuard] },


      { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard] },
      { path: 'contacts/:slug', component: ContactsComponent, canActivate: [AuthGuard] },
      { path: 'contacts/detail/:id', component: ContactDetailComponent, canActivate: [AuthGuard] },

      { path: 'clients-search', component: ClientSearchComponent, canActivate: [AuthGuard] },
      { path: 'clients-search/:id', component: ClientSearchPropertiesListComponent, canActivate: [AuthGuard] },


      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
      { path: 'settings/locations', component: LocationsComponent, canActivate: [AuthGuard] },
      { path: 'settings/general-settings', component: GeneralSettingsComponent, canActivate: [AuthGuard] },
      { path: 'settings/redirects', component: RedirectsComponent, canActivate: [AuthGuard] },

      { path: 'settings/users', component: UsersComponent, canActivate: [AuthGuard] },

      { path: 'registration', component: RegistrationsComponent, canActivate: [AuthGuard] },


      { path: 'refresh', component: PagesComponent, canActivate: [AuthGuard] },
      { path: 'pages', component: PagesComponent, canActivate: [AuthGuard] },
      { path: 'pages/:id', component: PagesComponent, canActivate: [AuthGuard] },


      { path: 'sliders', component: SlidersComponent, canActivate: [AuthGuard] },

      { path: 'after-before', component: AfterBeforeComponent, canActivate: [AuthGuard] },


      { path: 'contact-requests', component: ContactRequestsComponent, canActivate: [AuthGuard] },


    ],
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
