<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">

    <nb-action *ngIf="!isMobile">
      <strong class="mr-5">{{totalProperties}}</strong> requests
    </nb-action>



  </nb-actions>

  <nb-actions size="small" style="margin-left: auto">

    <nb-action class="action-right">
      <button nbButton status="primary" class="btn-ico-w" (click)="addProperty(null)">
        <nb-icon icon="plus-outline"></nb-icon>
        Add new redirect
      </button>
    </nb-action>
  </nb-actions>

</nb-layout-header>


<div [ngClass]="{'p-20': !isInDrawer}">

  <section class="table-container"   #tableContainer   (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"  (mouseup)="onMouseUp()">

    <ng-template #noResults>
      <nb-alert accent="basic" class="empty" *ngIf="!isLoadingResults">
        <div>
          <nb-icon icon="alert-circle-outline"></nb-icon>
          No result found.
        </div>
      </nb-alert>
    </ng-template>

    <ng-container *ngIf="properties && properties.length > 0; else noResults">

    <table matSort mat-table [dataSource]="properties" class="mat-elevation-z8">

      <!-- reference Column -->
      <ng-container matColumnDef="original_url">
        <th mat-header-cell *matHeaderCellDef>Original Url</th>
        <td mat-cell *matCellDef="let property">{{ property.original_url}}</td>
      </ng-container>




      <ng-container matColumnDef="redirect_url">
        <th mat-header-cell *matHeaderCellDef>Redirect to</th>
        <td mat-cell *matCellDef="let property">{{ property.redirect_url}}</td>
      </ng-container>





      <!-- Price Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Created At</th>
        <td mat-cell *matCellDef="let property">{{ property.created_at | customDate }}</td>
      </ng-container>


      <!-- Edit Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let property">
          <button nbButton outline (click)="addProperty(property.id)">
            <nb-icon icon="edit-outline"></nb-icon>
          </button>
        </td>
      </ng-container>



      <ng-container matColumnDef="pagination-footer">
        <td mat-footer-cell *matFooterCellDef colspan="13">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </td>
      </ng-container>


      <ng-container matColumnDef="pagination">
        <th mat-header-cell *matHeaderCellDef colspan="15">
          <app-pagination
            [isInDrawer]="false"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>



        </th>


      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['pagination']; sticky: true"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr mat-footer-row *matFooterRowDef="['pagination-footer']; sticky: true" class="footer-table"></tr>

    </table>

    </ng-container>

  </section>

</div>


