<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">


    <nb-action>
      <h1 class="page-title">
        <nb-icon icon="settings-2-outline"></nb-icon>

        Settings</h1>
    </nb-action>


  </nb-actions>


</nb-layout-header>


<div class="p-20">


  <nz-breadcrumb class="breadcrumbs">
    <nz-breadcrumb-item>
      <a [routerLink]="['../']">Dashboard</a>
    </nz-breadcrumb-item>

    <nz-breadcrumb-item><strong>Settings</strong></nz-breadcrumb-item>
  </nz-breadcrumb>



  <section>

    <div class="row">

      <div class="col-4">
        <nb-card class="card-setting">

          <nb-card-header class="text-start">
            <h5>Settings</h5>
          </nb-card-header>

          <nb-card-body>
            <div class="row">
              <div class="col-4" >
                <nb-icon icon="settings-2-outline"></nb-icon>

              </div>
              <div class="col-8">
                <a [routerLink]="['./general-settings']" nbButton size="medium">Manage Settings</a>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>

      <div class="col-4" *ngIf="IS_REAL_ESTATE">
        <nb-card class="card-setting">

          <nb-card-header class="text-start">
            <h5>Locations</h5>
          </nb-card-header>

          <nb-card-body>
            <div class="row">
              <div class="col-4" >
                <nb-icon icon="map-outline"></nb-icon>

              </div>
              <div class="col-8">
                <a [routerLink]="['./locations']" nbButton size="medium">Manage locations</a>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>

      <div class="col-4" *ngIf="IS_REAL_ESTATE">
        <nb-card class="card-setting">

          <nb-card-header class="text-start">
            <h5>Export</h5>
          </nb-card-header>

          <nb-card-body>
            <div class="row">
              <div class="col-4" >
                <nb-icon icon="code-outline"></nb-icon>

              </div>
              <div class="col-8">
                <a href="http://www.villalingo.com/export/kyero.xml" target="_blank" nbButton size="medium">Kyero export</a>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>


      <div class="col-4">
        <nb-card class="card-setting">

          <nb-card-header class="text-start">
            <h5>Logs viewer</h5>
          </nb-card-header>

          <nb-card-body>
            <div class="row">
              <div class="col-4" >
                <nb-icon icon="activity-outline"></nb-icon>

              </div>
              <div class="col-8">
                <a href="{{BASE_URL}}/log-viewer" target="_blank" nbButton size="medium">View logs files</a>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>


      <div class="col-4">
        <nb-card class="card-setting">

          <nb-card-header class="text-start">
            <h5>Redirects</h5>
          </nb-card-header>

          <nb-card-body>
            <div class="row">
              <div class="col-4" >
                <nb-icon icon="flag-outline"></nb-icon>

              </div>
              <div class="col-8">
                <a [routerLink]="['./redirects']" nbButton size="medium">Redirects</a>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>


      <div class="col-4">
        <nb-card class="card-setting">

          <nb-card-header class="text-start">
            <h5>Users</h5>
          </nb-card-header>

          <nb-card-body>
            <div class="row">
              <div class="col-4" >
                <nb-icon icon="people-outline"></nb-icon>

              </div>
              <div class="col-8">
                <a [routerLink]="['./users']" nbButton size="medium">Manage users</a>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>


    </div>
  </section>
</div>
