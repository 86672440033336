import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  HostListener
} from '@angular/core';
import 'hammerjs';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewInit {
  @ViewChild('gallery') galleryElement!: ElementRef;
  @Input() photos!: any;
  currentImageIndex = 0;
  touchStartX!: number;
  touchEndX!: number;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.galleryElement) {
      const mc = new Hammer.Manager(this.galleryElement.nativeElement);
      const swipe = new Hammer.Swipe();

      mc.add(swipe);

      mc.on('swipeleft', () => {
        this.nextImage();
      });

      mc.on('swiperight', () => {
        this.prevImage();
      });

      // Ajoutez des écouteurs d'événements de souris pour détecter le swipe avec la souris
      this.galleryElement.nativeElement.addEventListener('mousedown', this.onTouchStart.bind(this));
      this.galleryElement.nativeElement.addEventListener('mousemove', this.onTouchMove.bind(this));
      this.galleryElement.nativeElement.addEventListener('mouseup', this.onTouchEnd.bind(this));
    }
  }

  nextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.photos.length;
  }

  prevImage() {
    this.currentImageIndex = (this.currentImageIndex - 1 + this.photos.length) % this.photos.length;
  }

  onTouchStart(event: TouchEvent | MouseEvent) {
    if (event instanceof TouchEvent) {
      this.touchStartX = event.touches[0].clientX;
    } else if (event instanceof MouseEvent) {
      this.touchStartX = event.clientX;
    }
  }

  onTouchMove(event: TouchEvent | MouseEvent) {
    if (event instanceof TouchEvent) {
      this.touchEndX = event.touches[0].clientX;
    } else if (event instanceof MouseEvent) {
      this.touchEndX = event.clientX;
    }
  }

  onTouchEnd() {
    const swipeThreshold = 50; // Seuil de détection du swipe
    const deltaX = this.touchEndX - this.touchStartX;

    if (deltaX > swipeThreshold) {
      this.prevImage(); // Swipe vers la droite, passe à l'image précédente
    } else if (deltaX < -swipeThreshold) {
      this.nextImage(); // Swipe vers la gauche, passe à l'image suivante
    }
  }
  @Output() galleryClosed = new EventEmitter<void>();

  closeGallery() {
    this.galleryClosed.emit();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeGallery();
    } else if (event.key === 'ArrowLeft') {
      this.prevImage();
    } else if (event.key === 'ArrowRight') {
      this.nextImage();
    }
  }
}
