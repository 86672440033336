import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToastService} from "../../../../services/toast/toast.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {RedirectsService} from "../../services/redirects.service";

@Component({
  selector: 'app-modal-redirect-form',
  templateUrl: './modal-redirect-form.component.html',
  styleUrls: ['./modal-redirect-form.component.scss']
})
export class ModalRedirectFormComponent implements OnInit{
  redirectFormGroup!: FormGroup;
  @Input() id: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
    private redirectsService: RedirectsService,
  ) {
  }

  ngOnInit(): void {
    this.redirectFormGroup = this.formBuilder.group({
      id: [null],
      original_url: [null],
      redirect_url: [null],
    });

    if (this.id) {
      this.loadRedirectData(this.id);
    }
  }


  private loadRedirectData(id: any): void {
    this.redirectsService.getRedirect(id).subscribe(
      (response) => {
        // Remplissez le formulaire avec les données de la redirection
        this.redirectFormGroup.patchValue(response.data);
      },
      (error) => {
        // Gérez les erreurs, par exemple affichez un message d'erreur
        this.toastService.showToast('Failed to load redirect data.', 'Error!', 'error');
      }
    );
  }

  onSubmit() {
    const formData = this.redirectFormGroup.value; // Obtenez les valeurs du formulaire

    // Vérifiez si l'ID existe, ce qui indique une mise à jour
    if (formData.id) {
      // Effectuez une mise à jour en utilisant le service de redirection
      this.redirectsService.updateRedirect(formData.id, formData).subscribe(
        (response) => {
          // Gérez la réponse de manière appropriée, par exemple affichez un message de succès
          this.toastService.showToast('Redirect updated successfully.', 'Success!', 'success');
          this.closeDrawer();
        },
        (error) => {
          // Gérez les erreurs, par exemple affichez un message d'erreur
          this.toastService.showToast('Failed to update redirect.', 'Error!', 'error');
        }
      );
    } else {
      // Si l'ID n'existe pas, cela signifie qu'il s'agit d'une création
      // Effectuez une création en utilisant le service de redirection
      this.redirectsService.createRedirect(formData).subscribe(
        (response) => {
          // Gérez la réponse de manière appropriée, par exemple affichez un message de succès
          this.toastService.showToast('Redirect created successfully.', 'Success!', 'success');
          this.closeDrawer();
        },
        (error) => {
          // Gérez les erreurs, par exemple affichez un message d'erreur
          this.toastService.showToast('Failed to create redirect.', 'Error!', 'error');
        }
      );
    }
  }

  delete() {
    const redirectId = this.redirectFormGroup.value.id; // Récupérez l'ID de la redirection depuis le formulaire

    if (redirectId) {
      // Si l'ID de la redirection existe, appelez la méthode de suppression du service
      this.redirectsService.deleteRedirect(redirectId).subscribe(
        (response) => {
          // Gérez la réponse de manière appropriée, par exemple affichez un message de succès
          this.toastService.showToast('Redirect deleted successfully.', 'Success!', 'success');
          this.closeDrawer();
        },
        (error) => {
          // Gérez les erreurs, par exemple affichez un message d'erreur
          this.toastService.showToast('Failed to delete redirect.', 'Error!', 'error');
        }
      );
    } else {
      // Si l'ID de la redirection n'existe pas, affichez un message d'erreur
      this.toastService.showToast('Redirect ID not found.', 'Error!', 'error');
    }
  }

  // Méthode pour fermer le drawer
  closeDrawer(): void {
    this.drawerRef.close();
  }


}
