<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">


    <nb-action>
      <h1 class="page-title">
        <nb-icon icon="map-outline"></nb-icon>

        Locations</h1>
    </nb-action>


  </nb-actions>


</nb-layout-header>


<div class="p-20">


  <nz-breadcrumb class="breadcrumbs">
    <nz-breadcrumb-item>
      <a [routerLink]="['../../']">Dashboard</a>
    </nz-breadcrumb-item>

    <nz-breadcrumb-item>
      <a [routerLink]="['../']">Settings</a>
    </nz-breadcrumb-item>

    <nz-breadcrumb-item><strong>Locations</strong></nz-breadcrumb-item>
  </nz-breadcrumb>



  <section class="table-container">

    <div class="row">

      <div class="col-4">



        <nb-card size="large">
          <nb-card-header>

            Towns
            <input nbInput [(ngModel)]="searchText" (input)="highlightItem()" placeholder="Search">

            <button nbButton status="primary" class="btn-ico-w" style="float:right" (click)="addLocation(null)" >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>


          </nb-card-header>
          <nb-list cdkDropList (cdkDropListDropped)="onDrop($event)">
            <nb-list-item class="item-drag-drop-location" *ngFor="let location of locations" cdkDrag
                          [ngClass]="{ 'highlighted': location.highlighted, 'selected-location': location === selectedLocation }"
                          (click)="onLocationClick(location)">




              {{ location.name }} ({{ location.areas?.length }})

              <button nbButton status="primary" size="tiny" class="btn-ico-w" style="float:right" (click)="editLocation(location)" >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>


            </nb-list-item>
          </nb-list>

          <nb-card-footer>
            <button nbButton (click)="sortBy('name')">
              <nb-icon icon="refresh-outline"></nb-icon>
              Reorder by Name
            </button>
          </nb-card-footer>
        </nb-card>

      </div>


      <div class="col-8">


        <nb-card size="large" *ngIf="selectedLocation">
          <nb-card-header>
            Areas for {{selectedLocation?.name}}

            <button nbButton status="primary" class="btn-ico-w" style="float:right" (click)="addLocation(selectedLocation?.id)" >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>

          </nb-card-header>

          <div class="empty" *ngIf="!selectedLocation.areas?.length">
            No area for {{selectedLocation?.name}}
          </div>

          <nb-list>




            <nb-list-item class="item-block" *ngFor="let area of selectedLocation.areas"  [ngClass]="{ 'highlighted': area.highlighted }" >
              {{ area.name }}

              <button nbButton status="primary" size="tiny" class="btn-ico-w" style="float:right" (click)="editLocation(area)" >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>

            </nb-list-item>
          </nb-list>
        </nb-card>
      </div>

      </div>
  </section>
