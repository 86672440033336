import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from "rxjs/operators";
import {throwError} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private baseUrl!: string;
  private apiUrl!: string;
  private publicApiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.baseUrl = `${selectedDomain.domainName}/api`;
      this.apiUrl = `${this.baseUrl}/usersAccount`;
    } else {
      console.error('Selected domain is not set.');
    }
  }

  get(page: number, pageSize: number): Observable<any> {
    return this.http.post(this.apiUrl + '?page=' + page.toString() + '&pageSize=' + pageSize.toString(), {  });
  }

  // Méthode pour créer une redirection
  createRedirect(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/create`, data);
  }

  // Méthode pour mettre à jour une redirection
  updateRedirect(id: any, data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/update/${id}`, data);
  }

  // Méthode pour supprimer une redirection
  deleteRedirect(id: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/delete/${id}`, {});
  }


  getRedirect(id: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/get/${id}`);
  }

}
