import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
  ChangeDetectorRef
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {MatChipEvent, MatChipInputEvent} from '@angular/material/chips'; // Importer MatChipInputEvent
import { COMMA, ENTER } from '@angular/cdk/keycodes'; // Importer les codes clés pour les séparateurs
import { NbTagInputDirective } from '@nebular/theme';
import {Observable} from "rxjs";

import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';
import {MatIconModule} from '@angular/material/icon';
import {NgFor, AsyncPipe} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {Subscription} from "rxjs";
import {
  NzSkeletonAvatarShape,
  NzSkeletonAvatarSize,
  NzSkeletonButtonShape,
  NzSkeletonButtonSize,
  NzSkeletonInputSize
} from 'ng-zorro-antd/skeleton';


interface Option {
  id: number;
  name: string;
}

@Component({
  selector: 'app-input-chips',
  templateUrl: './input-chips.component.html'
})

export class InputChipsComponent  {
  @Input() control: AbstractControl | null = null;
  @Input() options!: any[];
  @Input() name: string = '';
  @Input() help: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() after: string = '';
  @Input() icon: string = '';
  @Input() type: string = '';
  @Input() isLoaded: boolean = false;
  @Output() valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild('optionInput') optionInput!: ElementRef;

  inputValue: any = '';

  optionCtrl = new FormControl();
  selectedOptions: any[] = [];
  filteredOptions: Observable<Option[]>;
  private controlSubscription: Subscription | undefined;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => (value ? this._filter(value) : this.options.slice()))
    );
  }

  ngOnInit(): void {

    if(this.control?.value) {
      this.selectedOptions = this.control?.value;
    }


    if (this.control instanceof FormControl) {
      this.inputValue = this.control.value;
      this.controlSubscription = this.control.valueChanges.subscribe((newValue) => {
        this.inputValue = newValue;
        this.updateSelectedOptions();
      });

    }

  }

  ngOnDestroy(): void {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

  private _filter(value: string): Option[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  private removedOptionIndex: number = -1;

  addOption(event: MatAutocompleteSelectedEvent): void {
    const selectedName = event.option.value;
    this.selectedOptions.push(selectedName);
    this.valueChange.emit(this.selectedOptions);
    this.optionCtrl.setValue(null);

    this.filteredOptions = this.filteredOptions.pipe(
      map((options) => options.filter((option) => option.name !== selectedName.name))
    );
    this.syncOptionsToinputValue();
  }

  removeOption(event: MatChipEvent): void {
    const removedOptionName = event?.chip?.value;

    if (removedOptionName) {
      this.removedOptionIndex = this.options.findIndex((option) => (option.human_name || option.name) === removedOptionName);

      this.selectedOptions = this.selectedOptions.filter((option) => (option.human_name || option.name) !== removedOptionName);
      this.valueChange.emit(this.selectedOptions);
      this.cdr.detectChanges();
    }

    this.syncOptionsToinputValue();
  }

  syncOptionsToinputValue() {
    this.optionInput.nativeElement.value = '';
    this.inputValue = this.selectedOptions.map((option) => option.id);
  }

  onInputChange() {
    this.shouldShowError();
    if (this.control instanceof FormControl) {
      this.control.setValue(this.inputValue);
      this.control.markAsDirty();
      this.control.markAsTouched();
      this.valueChange.emit(this.control.value);
    }
  }

  shouldShowError(): boolean {
    return !!(
      this.control &&
      this.control.invalid
    );
  }

  getErrorMessage(): string {
    if (this.control && this.control.errors) {
      if (this.control.errors['required']) {
        return 'This field is required.';
      } else if (this.control.errors['minlength']) {
        return `Minimum length is ${this.control.errors['minlength'].requiredLength} characters.`;
      }
    }
    return 'Invalid input.';
  }

  get isRequiredControl(): boolean {
    return !!this.control &&
      !!this.control.validator &&
      !!this.control.validator({} as FormControl)?.['required'];
  }


  private updateSelectedOptions() {
    this.selectedOptions = this.control?.value
  }
}
