import {AfterViewInit, ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";
import {ActivatedRoute, Router} from "@angular/router";
import {PropertiesService} from "../../../properties/services/properties.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {EventService} from "../../../../services/getConfiguration/event.service";
import {SettingFormComponent} from "../../components/setting-form/setting-form.component";

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent  extends Share implements OnInit, AfterViewInit  {

  settings!:any;
  locales!:any;
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  constructor(
    deviceService: DeviceDetectorService,
    private router: Router,
    private propertiesService: PropertiesService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerRef: NzDrawerRef,
  ) {
    super('Child', deviceService);
    this.eventService.configurationUpdated$.subscribe(() => {
      this.refreshConfiguration();
    });

    // Initialisez ou récupérez la configuration ici au chargement du composant
    this.refreshConfiguration();
  }


  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.settings = datas?.data?.settings;
      this.locales = datas?.data?.locales;

      this.cdr.detectChanges();
    });
  }

  edit(setting: any) {
    let width = '850px'
    if(this.isMobile) {
      width = '100vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: SettingFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Edit setting : " + setting?.key,
      nzContentParams: {
        setting:setting,
        locales:this.locales,
      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.configurationService.clearCache();
      this.refreshConfiguration();
    });
  }
}
