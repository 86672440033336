// logout.component.ts
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent {
  constructor(private _router: Router, private authService: AuthService, private router: Router) {
    this.authService.handleLogout().subscribe(
      (response) => {
        // Redirect to the login page after logout
        this.reload();

        return false;

      },
      (error) => {
        console.error('Logout error:', error);
        // Handle logout errors here
      }
    );
  }

  reload() {
    window.location.href = '/login';
  }

}
