<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">

  <nb-card>


    <nb-card-body >
  <div>

    <app-input-text [isLoaded]="isLoaded"
                    [control]="getFormControl('title')"
                    [name]="'title'"
                    [placeholder]="'Enter a title'"
                    [label]="'Title'"
                    [type]="'text'"
                    (valueChange)="onFormControlChange('title', $event)"
    ></app-input-text>
  </div>
  <div>
    <app-input-text [isLoaded]="isLoaded"
                    [control]="getFormControl('url')"
                    [name]="'url'"
                    [placeholder]="'Enter a url'"
                    [label]="'url'"
                    [type]="'text'"
                    (valueChange)="onFormControlChange('url', $event)"
    ></app-input-text>
  </div>
  <div>
    <app-html-editor
      [control]="getFormControl('description')"
      [name]="'description'"
      [placeholder]="'description'"
      [label]="'Description'"
      (valueChange)="onFormControlChange('description', $event)"
    ></app-html-editor>
  </div>
    </nb-card-body>

    <nb-card-footer style="text-align:right;" >
      <button nbButton status="primary" class="btn-ico-w" [disabled]="!formGroup.valid" >
        <nb-icon icon="save-outline"></nb-icon>
        <span *ngIf="!video">Add</span>
        <span *ngIf="video" >Update</span>
      </button>
    </nb-card-footer>

  </nb-card>
</form>

