<div>
  <nb-button-group size="small">
    <button  nbButton [attr.disabled]="!showPrevNext() || currentPage <= 1 ? true : null" (click)="onFirst()"><<</button>
    <button  nbButton [attr.disabled]="!showPrevNext() || currentPage <= 1 ? true : null" (click)="onPrev()"><</button>

    <button nbButton *ngFor="let page of visiblePages()" (click)="onPageChange(page)" [ngClass]="currentPage === page ? 'primary' : 'basic'" [attr.disabled]="currentPage === page ? true : null">
      {{ page }}
    </button>

    <button  nbButton [attr.disabled]="!showPrevNext() || currentPage >= totalPages ? true : null" (click)="onNext()">></button>

    <button  nbButton [attr.disabled]="!showPrevNext() || currentPage >= totalPages ? true : null" (click)="onLast()">>></button>
  </nb-button-group>
</div>
