import {ChangeDetectorRef, Component, Optional} from '@angular/core';
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {PropertiesService} from "../../services/properties.service";
import {NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";
import {EventService} from "../../../../services/getConfiguration/event.service";

@Component({
  selector: 'app-import-property',
  templateUrl: './import-property.component.html',
  styleUrls: ['./import-property.component.scss']
})
export class ImportPropertyComponent {

  formGroup = new FormGroup({
    url: new FormControl('', [Validators.required]),
  });


  constructor(
    private configurationService: GetConfigurationService,
    private propertiesService: PropertiesService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
    private toastrService: NbToastrService,
    private toastService: ToastService,

  ) {

  }
  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }

  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }


  onFormControlChangeTranslations(controlName: string, newValue: any | null): void {
    this.updateFormControlValueTranslation(controlName, newValue);
  }
  updateFormControlValueTranslation(controlName: string, newValue: any | null): void {
    const control = this.formGroup?.get('translations.' + controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }


  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }

  loading: boolean = false;

  submitForm(message:any) {
    this.markFormGroupTouched(this.formGroup);

    this.loading = true;

    if (this.formGroup.valid) {
      const formData = this.formGroup.value;

      this.propertiesService.importProperty(formData).subscribe(
        (response) => {
          this.toastService.showToast('Property imported successfully', 'Success!', 'success');
          this.loading = false;
          this.drawerRef.close();
        },
        (error) => {
          this.loading = false;
          this.toastService.showToast('Oups !', 'Error!', 'danger');
          this.cdr.detectChanges();
        }
      );
    }
  }


  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }


}
