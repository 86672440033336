// toast.service.ts
import { Injectable } from '@angular/core';
import { NbToastrService, NbGlobalPhysicalPosition } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastrService: NbToastrService) {}

  showToast(message: string, title: string, status: string): void {
    const iconConfig = { icon: 'bell-outline', pack: 'eva', status };
    this.toastrService.show(message, title, { ...iconConfig, position: NbGlobalPhysicalPosition.TOP_RIGHT });
  }


  showToastBottomLeft(message: string, title: string, status: string): void {
    const iconConfig = { icon: 'bell-outline', pack: 'eva', status };
    this.toastrService.show(message, title, { ...iconConfig, position: NbGlobalPhysicalPosition.BOTTOM_LEFT });
  }
}
