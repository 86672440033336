// http-loader.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpLoaderService {
  private requestCount = 0;
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  showLoader(): void {
    this.requestCount++;
    this.isLoadingSubject.next(true);
  }

  hideLoader(): void {
    this.requestCount--;
    if (this.requestCount <= 0) {
      this.requestCount = 0;
      this.isLoadingSubject.next(false);
    }
  }
}
