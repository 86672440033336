import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PropertiesService} from "../../services/properties.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {EventService} from "../../../../services/getConfiguration/event.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{


  propertyId!:any;
  datas!:any;
  constructor(
    private router: Router,
    private propertiesService: PropertiesService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerRef: NzDrawerRef,
  ) {


  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {

      this.propertyId = params.get('id');
      this.getProperty();

    });
  }


  getProperty() {
    if (this.propertyId) {
      this.propertiesService.getPropertyDetails(this.propertyId).subscribe(
        (response) => {
          this.datas = response?.data;
        });
    }
  }
}
