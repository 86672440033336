import {Component, Input, OnInit} from '@angular/core';
import {EventService} from "../../../../services/events/event.service";
import { DatePipe } from '@angular/common';
import {EventFormComponent} from "../../../../components/forms/calendar/event-form/event-form.component";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {

  @Input() contact!:any;
  @Input() property!:any;
  events: { [key: string]: any[] } = {};

  categories!: any;
  locations!: any;
  properties_status!: any;
  selectedGroup: string | null = null;

  showFutureEvents!:any;
  filteredGroups!: string[];

  genders!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;
  featureGroups: any[] = [];
  locales: any[] = [];


  constructor(
    private eventService : EventService,
    public datePipe: DatePipe,
    public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,

  ) {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.categories = datas?.data?.categories;
      this.locations = datas?.data?.locations;
      this.properties_status = datas?.data?.properties_status;
      this.featureGroups = datas?.data?.featureGroups;
      this.locales = datas?.data?.locales;

      this.addressesCategories = datas?.data?.addressesCategories;
      this.genders = datas?.data?.genders;
      this.priorities = datas?.data?.priorities;

      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;

    });
  }
  ngOnInit(): void {

    if(this.contact?.id) {
      this.getContact();
    }

    if(this.property?.id) {
      this.getByProperty();
    }


  }

  getContact() {
    this.eventService.getByContact(this.contact?.id).subscribe(
      (response) => {
        this.events = response?.data
      },
      (error) => {
        // Gérer les erreurs ici
        console.error(error);
      }
    );
  }

  getByProperty() {
    this.eventService.getByProperty(this.property?.id).subscribe(
      (response) => {
        this.events = response?.data
      },
      (error) => {
        // Gérer les erreurs ici
        console.error(error);
      }
    );
  }


  isFutureDate(date: string): boolean {
    const currentDate = new Date();
    const eventDate = new Date(date);
    return eventDate >= currentDate;
  }

  openEvent(event: any) {
    let width = '80vw';
    let modalRef = this._NzDrawerService.create({
      nzContent: EventFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Event",

      nzContentParams: {
        eventId: event.id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {

    });
  }

  createEvent() {
    let width = '80vw';
    let modalRef = this._NzDrawerService.create({
      nzContent: EventFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Event",

      nzContentParams: {
        contact: this.contact,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
        selectedDate:new Date(),
        currentDate:new Date()
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {

    });
  }

  hasEvents(): boolean {
    return Object.values(this.events).some(events => events.length > 0);
  }

  isToday(date: string): boolean {
    const currentDate = new Date();
    const eventDate = new Date(date);
    return eventDate.getFullYear() === currentDate.getFullYear() &&
      eventDate.getMonth() === currentDate.getMonth() &&
      eventDate.getDate() === currentDate.getDate();
  }
}
