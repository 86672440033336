<form class="row" (submit)="submitForm()">

  <div class="col-12">

    <nb-card>

      <nb-card-header>
        Detail
      </nb-card-header>

      <nb-card-body >


        <div class="row">
          <div class="col-12">
            <app-input-text [isLoaded]="isLoaded"
                            [control]="getFormControl('name')"
                            [name]="'name'"
                            [placeholder]="'Enter a location'"
                            [label]="'Name'"
                            [type]="'text'"
                            (valueChange)="onFormControlChange('name', $event)"
            ></app-input-text>
          </div>

          <div class="col-12">
            <app-html-editor
              [control]="getFormControl('alternative_names')"
              [name]="'alternative_names'"
              [placeholder]="'Alternative names'"
              [label]="'Alternative names'"
              (valueChange)="onFormControlChange('alternative_names', $event)"
            ></app-html-editor>
          </div>
        </div>


      </nb-card-body>



      <nb-card-footer >

        <div class="row">

          <div class="col-6">
            <button nbButton status="danger" class="btn-ico-w" type="button" (click)="delete()"  *ngIf="formGroup?.get('id')?.value">
              <nb-icon icon="trash-2-outline"></nb-icon>
              <span>Delete</span>
            </button>
          </div>

          <div class="col-6 text-end">
            <button nbButton status="primary" class="btn-ico-w" [disabled]="!formGroup.valid" >
              <nb-icon icon="save-outline"></nb-icon>
              <span>Save</span>
            </button>
          </div>
        </div>
      </nb-card-footer>

    </nb-card>

  </div>
</form>
