import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from "rxjs/operators";
import {throwError} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  private baseUrl!: string;
  private apiUrl!: string;
  private publicApiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService // Injectez le service d'authentification
  ) {
    // Obtenez le domaine sélectionné à partir du service d'authentification
    const selectedDomain = "https://www.villalingo.com";
    if (selectedDomain ) {
      this.baseUrl = `${selectedDomain}/api`;
      this.apiUrl = `${this.baseUrl}/properties`;
      this.publicApiUrl = `${this.baseUrl}/public/properties`;
    } else {
      console.error('Selected domain is not set.');
    }
  }

  getProperties(page: number, pageSize: number, formGroup: any, sortBy?: string, sortOrder?: string): Observable<any> {
    let params = formGroup;

    if (sortBy && sortOrder) {
      params = { ...params, sortBy: sortBy, sortOrder: sortOrder };
    }

    return this.http.post(this.apiUrl + '?page=' + page.toString() + '&pageSize=' + pageSize.toString(), { params });
  }


  createProperty(propertyData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/create`, propertyData);
  }


  importProperty(propertyData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/loadPropertyFromKyero`, propertyData);
  }

  updateProperty(propertyData: any, propertyId:number): Observable<any> {
    return this.http.put(`${this.apiUrl}/update/` + propertyId, propertyData);
  }

  getPropertyDetails(propertyId: string): Observable<any> {
    // Make the API call to get property details
    return this.http.get(`${this.apiUrl}/${propertyId}`);
  }

  getNewReference(propertyId: string): Observable<any> {
    // Make the API call to get property details
    return this.http.get(`${this.apiUrl}/getNewReference`);
  }


  uploadImages(formData: FormData): Observable<any> {
    // Upload images using the provided FormData
    return this.http.post(`${this.baseUrl}/photos/upload`, formData);
  }


  updateRank(formData: FormData): Observable<any> {
    // Upload images using the provided FormData
    return this.http.post(`${this.baseUrl}/photos/update-ranks`, formData);
  }

  removeImages(formData: FormData): Observable<any> {
    // Upload images using the provided FormData
    return this.http.post(`${this.baseUrl}/photos/delete`, formData);
  }

  deletePhoto(photoId: any, propertyId: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/photos/delete`, { photoId, propertyId, 'reference_table' : 'properties' });
  }



  /* Videos */
  addVideo(formValue: any, propertyId:any): Observable<any> {
    let url = `${this.baseUrl}/videos/create`;
    const formData: FormData = new FormData();

    if (formValue.id) {
      url = `${this.baseUrl}/videos/update`;
      formData.append('id', formValue.id);
    }


    formData.append('property_id', propertyId);
    formData.append('url', formValue.url);
    formData.append('title', formValue.title);
    formData.append('description', formValue.description);

    return this.http.post(url, formData).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateVideoPositions(videos: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/videos/update-ranks`, { videos });
  }

  delete(videoId: any, propertyId: any): Observable<any> {
    const options = {
      body: { videoId, propertyId }
    };
    return this.http.delete<any>(`${this.baseUrl}/videos/destroy`, options);
  }



  updateFeatures(features: any, propertyId:any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/updateFeatures/` + propertyId, { features });
  }


  createOrUpdateFeature(datas: any) {
    const url = `${this.baseUrl}/features/createOrUpdate`;

    return this.http.put(url, datas).pipe(
      tap((response: any) => {
        console.log('Le groupe de fonctionnalités a été mis à jour avec succès:', response);
      }),
      catchError((error) => {
        console.error('Une erreur s\'est produite lors de la mise à jour du groupe de fonctionnalités:', error);
        return throwError(error);
      })
    );
  }

  createOrUpdateGroup(datas: any) {
    const url = `${this.baseUrl}/featuresGroup/createOrUpdate`;

    return this.http.put(url, datas).pipe(
      tap((response: any) => {
        console.log('Le groupe de fonctionnalités a été mis à jour avec succès:', response);
      }),
      catchError((error) => {
        console.error('Une erreur s\'est produite lors de la mise à jour du groupe de fonctionnalités:', error);
        return throwError(error);
      })
    );
  }

  deleteFeature(id: number): Observable<any> {
    const options = {
      body: { id }
    };
    return this.http.delete<any>(`${this.baseUrl}/features/destroy`, options);
  }


  deleteFeatureGroup(id: any): Observable<any> {
    const options = {
      body: { id }
    };
    return this.http.delete<any>(`${this.baseUrl}/featuresGroup/destroy`, options);
  }

  getPropertyDetailsForPublic(propertyId: string): Observable<any> {
    return this.http.get(`${this.publicApiUrl}/get/${propertyId}`);
  }


  translate(pageData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/translate`, pageData);
  }

}
