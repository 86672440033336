<form [formGroup]="redirectFormGroup" (ngSubmit)="onSubmit()" autocomplete="one-time-code">
<nb-card>
    <nb-card-body>

      <div class="row">
        <div class="col-12">

          <h6 style="font-weight:400;">User</h6>

          <div class="mb-3" style="margin-top: 20px;" >
            <div class="form-floating">
              <input type="text" class="form-control" autofill="off"  formControlName="name" id="name" autocomplete="one-time-code"
                     placeholder="Value" required>
              <label for="name">Name*</label>
            </div>
          </div>

          <div class="mb-3" style="margin-top: 20px;" >
            <div class="form-floating">
              <input type="text" class="form-control" autofill="off"  formControlName="email" id="email" autocomplete="one-time-code"
                     placeholder="Value" required>
              <label for="email">Email*</label>
            </div>
          </div>


          <div class="mb-3" style="margin-top: 20px;" >
            <div class="form-floating">
              <input type="text" class="form-control" autofill="off"  formControlName="avatarUrl" id="avatarUrl" autocomplete="one-time-code"
                     placeholder="Value" >
              <label for="avatarUrl">Avatar Url</label>
            </div>
          </div>


          <h6 style="font-weight:400;">Password</h6>
          <div class="mb-3" style="margin-top: 20px;">
            <div class="form-floating">
              <input type="password" class="form-control" autocomplete="one-time-code" autofill="off"  formControlName="password" id="password" placeholder="Password" [required]="!id">
              <label for="password">Password{{ !id ? '*' : '' }}</label>
            </div>
          </div>

          <div class="mb-3" style="margin-top: 20px;">
            <div class="form-floating">
              <input type="password" class="form-control" autofill="off"  autocomplete="one-time-code" formControlName="passwordConfirmation" id="passwordConfirmation" placeholder="Password Confirmation" [required]="!id">
              <label for="passwordConfirmation">Password Confirmation{{ !id ? '*' : '' }}</label>
            </div>
          </div>


        </div>

      </div>

    </nb-card-body>


    <nb-card>
      <nb-card-footer>

        <div class="row">

          <div class="col-6">
            <button nbButton status="danger" class="btn-ico-w" type="button" (click)="delete()" *ngIf="id">
              <nb-icon icon="trash-2-outline"></nb-icon>
              <span>Delete</span>
            </button>
          </div>
          <div class="col-6" style="text-align:right">

            <button nbButton status="primary" class="btn-ico-w" [disabled]="!redirectFormGroup.valid">
              <nb-icon icon="save-outline"></nb-icon>
              <span>Save</span>
            </button>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>

  </nb-card>

</form>
