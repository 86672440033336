import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AppComponent } from './app.component';
import {
  NB_TIME_PICKER_CONFIG,
  NbAccordionModule,
  NbActionsModule, NbAlertComponent, NbAlertModule,
  NbAutocompleteModule, NbBadgeComponent, NbBadgeModule,
  NbButtonGroupModule,
  NbButtonModule, NbCalendarModule,
  NbCardModule,
  NbCheckboxModule, NbContextMenuModule,
  NbDialogModule,
  NbFormFieldModule, NbIconComponent,
  NbIconModule,
  NbInputModule,
  NbLayoutComponent,
  NbLayoutModule, NbListModule,
  NbMenuModule, NbPopoverModule, NbSearchModule, NbSelectModule,
  NbSidebarModule, NbSpinnerModule,
  NbTableModule,
  NbTabsetModule,
  NbTagModule,
  NbThemeModule,
  NbTimepickerModule, NbToastComponent, NbToastrModule, NbToastrService,
  NbToggleModule, NbTooltipModule, NbTreeGridModule,
  NbUserModule,
  NbWindowModule
} from "@nebular/theme";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { EventFormComponent } from './components/forms/calendar/event-form/event-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatDialogModule} from "@angular/material/dialog";
import {NbEvaIconsModule} from "@nebular/eva-icons";
import { AppRoutingModule } from './app-routing.module';
import { CalendarComponent } from './pages/calendar/calendar.component';
import {MatTableModule} from "@angular/material/table";
import { LoginComponent } from './pages/login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { LogoutComponent } from './pages/logout/logout.component';
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import { AdminComponent } from './admin/admin.component';
import {MatMenuModule} from "@angular/material/menu";
import { ModalComponent } from './components/modal/modal.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import {CommonModule, registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {SharedDatasService} from "./services/sharedDatas/shared-datas.service";
import {GetConfigurationService} from "./services/getConfiguration/get-configuration.service";
import { SpinnerComponent } from './components/spinner/spinner.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatChipsModule} from "@angular/material/chips";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {NzSkeletonModule} from "ng-zorro-antd/skeleton";
import {NgxEditorModule} from "ngx-editor";
import {PropertiesComponent} from "./modules/properties/pages/properties/properties.component";
import {CurrencyFormatPipe} from "./pipes/currency-format.pipe";
import {SqmFormatPipe} from "./pipes/sqm-format.pipe";
import {ModalFormPropertyComponent} from "./modules/properties/components/modal-form-property/modal-form-property.component";
import {InputTextComponent} from "./components/form-elements/input-text/input-text.component";
import {HtmlEditorComponent} from "./components/form-elements/html-editor/html-editor.component";
import {InputChipsComponent} from "./components/form-elements/input-chips/input-chips.component";
import {InputAutocompleteComponent} from "./components/form-elements/input-autocomplete/input-autocomplete.component";
import {InputCheckboxComponent} from "./components/form-elements/input-checkbox/input-checkbox.component";
import {PaginationComponent} from "./components/pagination/pagination.component";
import {ContactsComponent} from "./modules/contacts/pages/contacts/contacts.component";
import {ModalFormContactComponent} from "./modules/contacts/components/modal-form-contact/modal-form-contact.component";
import {ToastService} from "./services/toast/toast.service";
import { ConfirmComponent } from './components/confirm/confirm.component';
import { PropertyFormComponent } from './modules/properties/forms/property-form/property-form.component';
import {HttpInterceptorService} from "./interceptors/http-interceptor.service";
import {HttpLoaderService} from "./interceptors/http-loader.service";
import {NzMessageModule} from "ng-zorro-antd/message";
import { SidebarFiltersComponent } from './modules/properties/components/sidebar-filters/sidebar-filters.component';
import { SelectSingleComponent } from './components/form-elements/select-single/select-single.component';
import {MatSelectModule} from "@angular/material/select";
import { ImagesUploadComponent } from './modules/properties/components/images-upload/images-upload.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { ThreeDFormComponent } from './modules/properties/forms/three-d-form/three-d-form.component';
import { SidebarFeaturesComponent } from './modules/properties/components/sidebar-features/sidebar-features.component';
import {FilterPipe} from "./modules/properties/pipe/filter.pipe";
import { SidebarFeatureFormComponent } from './modules/properties/components/sidebar-feature-form/sidebar-feature-form.component';
import { SidebarFeatureGroupFormComponent } from './modules/properties/components/sidebar-feature-group-form/sidebar-feature-group-form.component';
import { InputTranslatableComponent } from './components/form-elements/input-translatable/input-translatable.component';
import { EditorTranslatableComponent } from './components/form-elements/editor-translatable/editor-translatable.component';
import {NzMenuModule} from "ng-zorro-antd/menu";
import {DatePipe} from "./pipes/date.pipe";
import { ContactPopHoverComponent } from './components/pop-hover/contact-pop-hover/contact-pop-hover.component';
import {NgxMaterialTimepickerComponent, NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import { AppContactsListComponent } from './components/app-contacts-list/app-contacts-list.component';
import { AppPropertyLinkComponent } from './components/app-property-link/app-property-link.component';
import { ContactDetailComponent } from './modules/contacts/contact-detail/contact-detail.component';
import { EventsListComponent } from './modules/events/pages/events-list/events-list.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import { ModalFormSearchComponent } from './modules/clientSearch/components/modal-form-search/modal-form-search.component';
import { ClientSearchComponent } from './modules/clientSearch/pages/client-search/client-search.component';
import { ClientSearchPropertiesListComponent } from './modules/clientSearch/pages/client-search-properties-list/client-search-properties-list.component';
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";
import {SettingsComponent} from "./modules/settings/settings.component";
import {LocationsComponent} from "./modules/locations/pages/locations/locations.component";
import { ModalFormLocationComponent } from './modules/locations/components/modal-form-location/modal-form-location.component';
import { RegistrationsComponent } from './modules/registrations/pages/registrations/registrations.component';
import { ModalRegistrationFormComponent } from './modules/registrations/components/modal-registration-form/modal-registration-form.component';
import { DashboardComponent } from './modules/properties/pages/dashboard/dashboard.component';
import { PublicDetailPropertyComponent } from './public_modules/properties/pages/public-detail-property/public-detail-property.component';
import { FrontComponent } from './front/front.component';
import {CarouselComponent, CarouselModule} from "ngx-owl-carousel-o";
import { GalleryComponent } from './components/gallery/gallery.component';
import {MatTreeModule} from "@angular/material/tree";
import {MatSortModule} from "@angular/material/sort";
import { ImportPropertyComponent } from './modules/properties/components/import-property/import-property.component';
import {DeviceDetectorService} from "ngx-device-detector";
import { GlobalDashboardComponent } from './modules/dashboard/pages/global-dashboard/global-dashboard.component';
import {NgxEchartsModule} from "ngx-echarts";
import {PagesComponent} from './modules/pages/pages/pages.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import { PagesFormComponent } from './modules/pages/components/pages-form/pages-form.component';
import { WidgetChooseComponent } from './modules/pages/components/widget-choose/widget-choose.component';
import { WidgetFormComponent } from './modules/pages/components/widget-form/widget-form.component';
import { EditorWidgetComponent } from './modules/pages/components/editor-widget/editor-widget.component';
import { GeneralSettingsComponent } from './modules/genetalSettings/pages/general-settings/general-settings.component';
import { SettingFormComponent } from './modules/genetalSettings/components/setting-form/setting-form.component';
import {AutoResizeDirective} from "./modules/pages/directives/autoResizeDirective.directive";
import { SlidersComponent } from './modules/sliders/pages/sliders/sliders.component';
import { SliderFormComponent } from './modules/sliders/components/slider-form/slider-form.component';
import { AfterBeforeComponent } from './modules/afterBefore/pages/after-before/after-before.component';
import { AfterBeforeeFormComponent } from './modules/afterBefore/form/after-beforee-form/after-beforee-form.component';
import {EditorModule} from "@tinymce/tinymce-angular";
import {AngularEditorModule} from "@kolkov/angular-editor";
import { ContactRequestsComponent } from './modules/contactRequests/pages/contact-requests/contact-requests.component';
import { RedirectsComponent } from './modules/redirects/pages/redirects/redirects.component';
import { ModalRedirectFormComponent } from './modules/redirects/components/modal-redirect-form/modal-redirect-form.component';
import { UsersComponent } from './modules/users/pages/users/users.component';
import { ModalUserFormComponent } from './modules/users/components/modal-user-form/modal-user-form.component';



registerLocaleData(en);

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    EventFormComponent,
    CalendarComponent,
    LoginComponent,
    LogoutComponent,
    AdminComponent,
    ModalComponent,
    SpinnerComponent,

    PropertiesComponent,
    ModalFormPropertyComponent,


    ContactsComponent,
    ModalFormContactComponent,


    CurrencyFormatPipe,
    SqmFormatPipe,
    FilterPipe,
    DatePipe,


    InputTextComponent,
    HtmlEditorComponent,
    InputChipsComponent,
    InputAutocompleteComponent,
    InputCheckboxComponent,
    PaginationComponent,
    ConfirmComponent,
    PropertyFormComponent,
    SidebarFiltersComponent,
    SelectSingleComponent,
    ImagesUploadComponent,
    ThreeDFormComponent,
    SidebarFeaturesComponent,
    SidebarFeatureFormComponent,
    SidebarFeatureGroupFormComponent,
    InputTranslatableComponent,
    EditorTranslatableComponent,
    ContactPopHoverComponent,
    AppContactsListComponent,
    AppPropertyLinkComponent,
    ContactDetailComponent,
    EventsListComponent,
    ModalFormSearchComponent,
    ClientSearchComponent,
    ClientSearchPropertiesListComponent,
    LocationsComponent,
    SettingsComponent,
    ModalFormLocationComponent,
    RegistrationsComponent,
    ModalRegistrationFormComponent,
    DashboardComponent,
    PublicDetailPropertyComponent,
    FrontComponent,
    GalleryComponent,
    ImportPropertyComponent,
    GlobalDashboardComponent,
    PagesComponent,
    PagesFormComponent,
    WidgetChooseComponent,
    WidgetFormComponent,
    EditorWidgetComponent,
    GeneralSettingsComponent,
    SettingFormComponent,

    AutoResizeDirective,
      SlidersComponent,
      SliderFormComponent,
      AfterBeforeComponent,
      AfterBeforeeFormComponent,
      ContactRequestsComponent,
      RedirectsComponent,
      ModalRedirectFormComponent,
      UsersComponent,
      ModalUserFormComponent,


  ],
  imports: [
    AppRoutingModule,
    RouterModule.forRoot([]),
    BrowserModule,
    FullCalendarModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,


    MatSortModule,

    CommonModule,
    ReactiveFormsModule,
    FormsModule,


    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDialogModule,
    MatMenuModule,
    MatSelectModule,

    NbThemeModule.forRoot(),
    NbLayoutModule,
    NbSidebarModule.forRoot(),
    NbButtonModule,
    NbMenuModule.forRoot(),
    NbTabsetModule,
    NbWindowModule.forRoot(),
    NbDialogModule.forRoot(),
    BrowserAnimationsModule,
    NbCardModule,
    NbIconModule,
    NbEvaIconsModule,
    NbInputModule,
    NbTimepickerModule,
    NbUserModule,
    NbActionsModule,
    NbTableModule,
    NbFormFieldModule,
    NbToggleModule,
    NbTagModule,
    NbAccordionModule,
    NbTagModule,
    NbAutocompleteModule,
    NbButtonGroupModule,
    NbCheckboxModule,
    NbListModule,
    NbToastrModule.forRoot(),
    NzDrawerModule,
    NzSkeletonModule,
    NzMessageModule,
    NzMenuModule,
    NbAlertModule,
    NbSpinnerModule,
    NbBadgeModule,
    NbSelectModule,
    NbPopoverModule,
    NbCalendarModule,
    NbTableModule,
    NbSearchModule,
    NbContextMenuModule,

    NbTooltipModule,

    NzBreadCrumbModule,
    NgxMaterialTimepickerModule,

    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    NbTreeGridModule,

    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,


    CarouselModule,
    HammerModule,


    AngularEditorModule,

    DragDropModule,



    MatToolbarModule,


    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),

  ],
  providers: [
    {
      provide: NB_TIME_PICKER_CONFIG,
      useValue: {}
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: NZ_I18N, useValue: en_US },

    HttpLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },

    GetConfigurationService,
    SharedDatasService,

    NbLayoutComponent,


    NbToastrService,
    NbToastComponent,
    NbAlertComponent,
    NbBadgeComponent,
    ToastService,
    NgxMaterialTimepickerComponent,

    AppContactsListComponent,
    NbIconComponent,

    DeviceDetectorService,

    CarouselComponent,

  ],
  exports: [
    ModalComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
