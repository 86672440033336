

<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">

    <nb-action>
      <h1 class="title-page">Client search</h1>
    </nb-action>

    <nb-action>
      <strong class="mr-5">{{totalProperties}}</strong> Active searches
    </nb-action>

  </nb-actions>



</nb-layout-header>


<div [ngClass]="{'p-20': !isInDrawer}">

  <section class="table-container">

    <ng-template #noResults>
      <nb-alert accent="basic" class="empty" *ngIf="!isLoadingResults">
        <div>
          <nb-icon icon="alert-circle-outline"></nb-icon>
          No result found.</div>
      </nb-alert>
    </ng-template>

    <table mat-table [dataSource]="properties" class="mat-elevation-z8" *ngIf="properties.length > 0; else noResults">

      <!-- shared reference Column -->
      <ng-container matColumnDef="full_name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let property">
          <app-contact-pop-hover [contact]="property?.address"></app-contact-pop-hover>
        </td>
      </ng-container>



      <!-- Price Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Created At</th>
        <td mat-cell *matCellDef="let property">{{ property.created_at | customDate }}</td>
      </ng-container>

      <!-- Price Column -->
      <ng-container matColumnDef="properties_count">
        <th mat-header-cell *matHeaderCellDef>Results</th>
        <td mat-cell *matCellDef="let property">

          <button   size="small" nbButton status="success"   (click)="openProperties(property.id)" *ngIf="!isInDrawer">
            <nb-icon style="color:#fff;" icon="home-outline"></nb-icon>
            {{ property.properties_count }}
            {{ property.properties_count === 1 ? 'property' : (property.properties_count === 0 ? 'no property' : 'properties') }}
          </button>


          <button   size="small" nbButton status="success"   (click)="openPropertiesDrawer(property.id)" *ngIf="isInDrawer">
            <nb-icon style="color:#fff;" icon="home-outline"></nb-icon>
            {{ property.properties_count }}
            {{ property.properties_count === 1 ? 'property' : (property.properties_count === 0 ? 'no property' : 'properties') }}
          </button>

        </td>
      </ng-container>


      <!-- Edit Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let property">
          <button nbButton outline (click)="editSearch(property.id)" >
            <nb-icon icon="edit-outline"></nb-icon>
          </button>
        </td>
      </ng-container>


      <ng-container matColumnDef="pagination-footer">
        <td mat-footer-cell *matFooterCellDef colspan="13">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </td>
      </ng-container>


      <ng-container matColumnDef="pagination">
        <th mat-header-cell *matHeaderCellDef colspan="13">
          <app-pagination
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['pagination']; sticky: true"> </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr mat-footer-row *matFooterRowDef="['pagination-footer']; sticky: true" class="footer-table"></tr>

    </table>



  </section>

</div>


