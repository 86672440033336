<nb-layout>
  <nb-layout-column >


<div class="container text-center">
  <nb-card class="card-login"  [nbSpinner]="isLogged || isLoading" nbSpinnerStatus="success">
    <nb-card-header>Login to
      <nb-icon icon="shield-outline" style="color:#000!important;"></nb-icon>
      Workspace
    </nb-card-header>
    <nb-card-body>
      <nb-alert *ngIf="isLogged" accent="success">Welcome {{userName}}, you are connected to the system. Please wait..</nb-alert>

      <form (ngSubmit)="login()" class="login-form" *ngIf="!isLogged">

        <nb-alert *ngIf="errorMessage" accent="danger">{{ errorMessage }}</nb-alert>


        <div class="form-floating mb-3">
          <input type="email" name="email"
                 [(ngModel)]="credentials.email" required
                 class="form-control" id="floatingInput" placeholder="name@example.com">
          <label for="floatingInput">Email address</label>
        </div>

        <div class="form-floating mb-3">
          <input type="password" name="password" [(ngModel)]="credentials.password" required class="form-control"
                 id="floatingInputP" placeholder="name@example.com">
          <label for="floatingInputP">Password</label>
        </div>

        <div class="mb-3">
          <select
            #selectElement
            (change)="onChange(selectElement.value)"
            placeholder="Select a domain name" required class="form-select form-select-lg">
            <option value="" disabled selected>Choice domain name</option>
            <option *ngFor="let domain of domains" [value]="domain.name">{{ domain.name }}</option>
          </select>
        </div>



        <button nbButton class="btn-ico-w" size="medium" fullWidth type="submit" status="primary">
          <nb-icon icon="plus-outline" style="color:#fff!important;"></nb-icon>
          Login
        </button>

      </form>
    </nb-card-body>
    <nb-card-footer><small>© {{ currentYear }}, By Anthony Brioit</small></nb-card-footer>
  </nb-card>


</div>
</nb-layout-column>
</nb-layout>
