import {AfterViewInit, Component, ElementRef, OnInit, Optional, ViewChild} from '@angular/core';
import {Share} from "../../../../class/share";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalRedirectFormComponent} from "../../../redirects/components/modal-redirect-form/modal-redirect-form.component";
import {UsersService} from "../../services/users.service";
import {ModalUserFormComponent} from "../../components/modal-user-form/modal-user-form.component";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent  extends Share  implements OnInit, AfterViewInit {
  isLoadingResults = true;
  currentPage = 1;
  pageSize = 10;
  totalProperties = 0;
  isInDrawer = false;
  properties: any[] = [];


  displayedColumns: string[] = [
    'name',
    'created_at',
    'actions',
  ];

  constructor(
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public _NzDrawerRef: NzDrawerRef,
    private router: Router,
    deviceService: DeviceDetectorService,
    private usersService: UsersService,
  ) {
    super('Child', deviceService);

  }

  ngOnInit(): void {
    this.loadContacts();
  }

  ngAfterViewInit(): void {
  }

  loadContacts(): void {
    this.isLoadingResults = true;
    this.usersService.get(this.currentPage, this.pageSize).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.properties = response.data?.data;
        this.totalProperties = response.data?.total;
      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );
  }

  calculateTotalPages(): number {
    return Math.ceil(this.totalProperties / this.pageSize);
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.loadContacts();
  }


  @ViewChild('tableContainer') tableContainer!: ElementRef;


  isMouseDown: boolean = false;
  startX!: number;
  startScrollLeft!: number;


  onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
    this.startX = event.pageX - this.tableContainer.nativeElement.offsetLeft;
    this.startScrollLeft = this.tableContainer.nativeElement.scrollLeft;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown) return;
    const x = event.pageX - this.tableContainer.nativeElement.offsetLeft;
    const distance = x - this.startX;
    this.tableContainer.nativeElement.scrollLeft = this.startScrollLeft - distance;
  }

  onMouseUp() {
    this.isMouseDown = false;
  }

  addProperty(id?: any) {

    let width = '940px'
    if(this.isMobile) {
      width = '100vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalUserFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create redirect",
      nzContentParams: {
        id: id,
      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadContacts();
    });

  }
}
