import { IsMobileService } from "../services/isMobile/isMobile.service";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";

export class Share {
  constructor(
    public name: string,
    public deviceService: DeviceDetectorService
  ) {
    this.checkIsMobile();

  }

  public isMobile: boolean | undefined;
  deviceInfo: DeviceInfo | null = null;

  checkIsMobile() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService?.getDeviceInfo();
    const isMobile = this.deviceService?.isMobile();
    const isTablet = this.deviceService?.isTablet();
    const isDesktopDevice = this.deviceService?.isDesktop();
    console.log(this.deviceInfo);
    console.log(isMobile);
    console.log(isTablet);
    console.log(isDesktopDevice);

    this.isMobile = isMobile;
  }


}
