<div  *ngIf="isLoaded">
<ng-container *ngIf="isFormControl(control)">

<div class="has-validation mb-3">
  <div class="form-floating is-invalid">
    <input

      *ngIf="control"

      [ngClass]="{'is-invalid': (!control?.valid && control?.dirty)}"
      [formControl]="control"
      [required]="isRequiredControl"
      (input)="onInputChange()"

      [type]="type"

      class="form-control" id="floatingInputGroup1"
           placeholder="Title">
    <label [for]="name">{{ label }} <small *ngIf="isRequiredControl">*</small></label>
  </div>


</div>
</ng-container>
</div>
