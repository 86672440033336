// event.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private apiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/events`;
    } else {
      console.error('Selected domain is not set.');
    }
  }


  getEventsForWeek(start: string = '', end: string = ''): Observable<any[]> {
    const url = `${this.apiUrl}/week?start=${start}&end=${end}`;
    return this.http.get<any[]>(url);
  }


  addOrUpdateEvent(data: any): Observable<any> {
    const url = `${this.apiUrl}/addOrUpdate`;
    return this.http.put<any>(url, data);
  }

  updateEventDate(eventId: string, newStartDate: Date): Observable<any> {
    const updatedEvent = { id: eventId, start: newStartDate };

    return this.http.put(this.apiUrl + '/updateStart', updatedEvent);
  }

  resizeEvent(eventId: string, newEndDate: Date): Observable<any> {
    const updatedEvent = { id: eventId, end: newEndDate };

    return this.http.put(this.apiUrl + '/resizeEvent', updatedEvent);
  }


  getEvent(eventId: string): Observable<any> {
    return this.http.get(this.apiUrl + '/get/' + eventId);
  }

  deleteEvent(eventId: string): Observable<any> {
    return this.http.delete(this.apiUrl + '/delete/' + eventId);
  }

  getByContact(eventId: string): Observable<any> {
    return this.http.get(this.apiUrl + '/getByContact/' + eventId);
  }


  getByProperty(eventId: string): Observable<any> {
    return this.http.get(this.apiUrl + '/getByProperty/' + eventId);
  }

}
