import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService} from "../auth/auth.service";
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetCurrentPropertyImportService {
  private apiUrl!: string; // URL de l'API pour obtenir l'import de propriété actuel

  constructor(
    private http: HttpClient,
    private authService: AuthService // Injectez le service d'authentification
  ) {
    // Obtenez le domaine sélectionné à partir du service d'authentification
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/getCurrentPropertyImport`;
    } else {
      console.error('Selected domain is not set.');
    }
  }

  // Méthode pour appeler l'URL et obtenir la réponse
  getCurrentPropertyImport(): Observable<any> {
    return this.http.get(this.apiUrl).pipe(
      catchError(error => {
        console.error('Error loading current property import:', error);
        return []; // Utilisez 'of' pour retourner un observable avec une valeur par défaut
      })
    );
  }
}
