import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from "rxjs/operators";
import {throwError} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private apiUrl!: string; // URL de l'API des emplacements

  constructor(
    private http: HttpClient,
    private authService: AuthService // Injectez le service d'authentification
  ) {
    // Obtenez le domaine sélectionné à partir du service d'authentification
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/settings/locations`;
    } else {
      console.error('Selected domain is not set.');
    }
  }

  get(page: number, pageSize: number, formGroup:any): Observable<any> {
    const params = formGroup;
    return this.http.post(this.apiUrl + '?page=' + page.toString() + '&pageSize=' + pageSize.toString(), { params });
  }

  createOrUpdate(formData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/createOrUpdate`, formData);
  }

  getDetail(searchId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/get/${searchId}`);
  }


  delete(id: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/delete/` + id);
  }


  deleteProperties(id: any, properties: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/properties/delete-properties/` + id, { 'properties' : properties });
  }

  getProperties(id:number, page: number, pageSize: number): Observable<any> {
    return this.http.get(this.apiUrl + '/properties/' + id + '?page=' + page.toString() + '&pageSize=' + pageSize.toString());
  }


  pinProperties(id: any, properties: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/properties/pin-properties/` + id, { 'properties' : properties });
  }

  unPinProperties(id: any, pinId: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/properties/unpin-properties/` + id + '/' + pinId, {});
  }

}
