import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private configurationUpdatedSource = new Subject<void>();

  configurationUpdated$ = this.configurationUpdatedSource.asObservable();

  emitConfigurationUpdated() {
    this.configurationUpdatedSource.next();
  }
}
