<nb-layout-header subheader class="action-nav">




  <nb-actions size="small">

    <nb-action>
      <h1 class="title-page">Results for <strong>{{search?.address?.full_name}}</strong></h1>
    </nb-action>


    <nb-action>
      <strong class="mr-5">{{totalProperties}}</strong> results
    </nb-action>
  </nb-actions>


  <nb-action class="action-right">


    <button nbButton status="primary" class="btn-ico-w" (click)="editSearch(searchId)">
      <nb-icon icon="edit-2-outline"></nb-icon>
      Edit search
    </button>
  </nb-action>

</nb-layout-header>


<div [ngClass]="{'p-20': !isInDrawer}">

  <nz-breadcrumb class="breadcrumbs" *nfIf="!isInDrawer">
    <nz-breadcrumb-item>
      <a [routerLink]="['../../']">Dashboard</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="['../']">Client search</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item><strong>{{search?.address?.full_name}}</strong></nz-breadcrumb-item>
  </nz-breadcrumb>


  <section class="table-container">


    <ng-template #noResults>
      <nb-alert accent="basic" class="empty" *ngIf="!isLoadingResults">
        <div>
          <nb-icon icon="alert-circle-outline"></nb-icon>
          No result found.
        </div>
      </nb-alert>
    </ng-template>

    <table mat-table [dataSource]="properties" class="mat-elevation-z8" *ngIf="properties.length > 0; else noResults">


      <!-- Edit Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>

          <nb-checkbox
            status="basic"
            [checked]="isBulkCheckboxChecked"
            (change)="selectBulk()"
          ></nb-checkbox>
        </th>
        <td class="select-column" mat-cell *matCellDef="let property">
          <nb-checkbox
            status="basic"
            [checked]="isSelected(search.id, property.id)"
            (change)="select(search.id, property.id)"
          ></nb-checkbox>
        </td>
      </ng-container>


      <!-- Edit Column -->
      <ng-container matColumnDef="pin">
        <th mat-header-cell *matHeaderCellDef>Pin</th>
        <td class="select-column"  mat-cell *matCellDef="let property">

          <ng-container *ngIf="property?.pivot?.isChecked == 1">

            <button nbTooltip="Unpin this property"   nbTooltipIcon="flash-off-outline" nbTooltipStatus="warning" nbButton
               status="warning"  size="small"  (click)="unPin(property.id)" >
              <nb-icon icon="flash-off-outline"></nb-icon> Unpin
            </button>

          </ng-container>

        </td>
      </ng-container>


      <!-- is available Column -->
      <ng-container matColumnDef="is_available">
        <th mat-header-cell *matHeaderCellDef>Availability</th>
        <td mat-cell *matCellDef="let property">

          <nb-tag-list>
            <nb-tag status="basic" appearance="filled" text="Pending" *ngIf="property.is_available == 0"></nb-tag>
            <nb-tag status="success" appearance="filled" text="Available" *ngIf="property.is_available == 1"></nb-tag>
            <nb-tag status="danger" appearance="filled" text="Sold" *ngIf="property.is_available == 2"></nb-tag>
            <nb-tag status="basic" appearance="filled" text="Out of market" *ngIf="property.is_available == 3"></nb-tag>
          </nb-tag-list>

        </td>
      </ng-container>

      <!-- Photo Column -->
      <ng-container matColumnDef="photo" sticky>
        <th mat-header-cell *matHeaderCellDef>Photo</th>
        <td mat-cell *matCellDef="let element">
          <img src="{{element.photo?.full_url}}" *ngIf="element.photo?.full_url" width="90" class="imageCorner"/>

          <span *ngIf="!element.photo?.full_url">No photo</span>

        </td>
      </ng-container>


      <!-- reference Column -->
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef>Ref</th>
        <td mat-cell *matCellDef="let property">{{ property.reference}}</td>
      </ng-container>


      <!-- shared reference Column -->
      <ng-container matColumnDef="mls_reference">
        <th mat-header-cell *matHeaderCellDef>Shared Ref.</th>
        <td mat-cell *matCellDef="let property">
          <span>{{ property.mls_reference}}</span>

        </td>
      </ng-container>

      <!-- shared reference Column -->
      <ng-container matColumnDef="agency">
        <th mat-header-cell *matHeaderCellDef>Agency</th>
        <td mat-cell *matCellDef="let property">
          <app-contact-pop-hover [contact]="property.agency"></app-contact-pop-hover>
        </td>
      </ng-container>


      <!-- Price Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>Price</th>
        <td mat-cell *matCellDef="let property">{{ property.price | currencyFormat }}</td>
      </ng-container>


      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let property">{{ property.location?.name }}</td>
      </ng-container>


      <!-- bedrooms Column -->
      <ng-container matColumnDef="bedrooms">
        <th mat-header-cell *matHeaderCellDef>Bedrooms</th>
        <td mat-cell *matCellDef="let property">

          <span *ngIf="property.bedrooms">{{ property.bedrooms }}</span>
          <nb-icon icon="close-outline" *ngIf="!property.bedrooms"></nb-icon>

        </td>
      </ng-container>


      <!-- bedrooms Column -->
      <ng-container matColumnDef="bathrooms">
        <th mat-header-cell *matHeaderCellDef>Bathrooms</th>
        <td mat-cell *matCellDef="let property">

          <span *ngIf="property.bathrooms">{{ property.bathrooms }}</span>
          <nb-icon icon="close-outline" *ngIf="!property.bathrooms"></nb-icon>

        </td>
      </ng-container>


      <!-- Location Column -->
      <ng-container matColumnDef="categories">
        <th mat-header-cell *matHeaderCellDef>Categories</th>
        <td mat-cell *matCellDef="let property">
          <ng-container *ngIf="property.categories && property.categories.length > 0">
            <span *ngFor="let category of property.categories; let last = last">
              {{ category.name }}{{ !last ? ', ' : '' }}
            </span>
          </ng-container>
          <ng-container *ngIf="!property.categories || property.categories.length === 0">No Categories</ng-container>
        </td>


      </ng-container>


      <!-- M² Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let property">{{ property.size | sqmFormatPipe }}</td>
      </ng-container>


      <!-- M² Column -->
      <ng-container matColumnDef="lot_size">
        <th mat-header-cell *matHeaderCellDef>Plot</th>
        <td mat-cell *matCellDef="let property">{{ property.lot_size | sqmFormatPipe }}</td>
      </ng-container>


      <!-- Price Column -->
      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef>Last update</th>
        <td mat-cell *matCellDef="let property">{{ property.updated_at | customDate }}</td>
      </ng-container>


      <!-- Edit Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let property">

          <button size="small" nbButton outline (click)="openDetailPublicProperty(property.id)" style="margin-right:5px;">
            <nb-icon icon="eye-outline"></nb-icon>
          </button>


          <button nbButton size="small" *ngIf="!isInDrawer" outline (click)="openDashboard(property.id)" style="margin-right:5px;">
            <nb-icon icon="home-outline"></nb-icon>
          </button>

          <button nbButton size="small" outline (click)="addProperty(property.id)">
            <nb-icon icon="edit-outline"></nb-icon>
          </button>
        </td>
      </ng-container>


      <ng-container matColumnDef="pagination-footer">


        <td mat-footer-cell *matFooterCellDef colspan="13">


          <div class="flex-container">

            <button [disabled]="isDeleteButtonDisabled()" nbButton class="mr-5" status="danger" size="small"
                    (click)="delete()">
              <nb-icon icon="trash-2-outline"></nb-icon>
            </button>


            <button  nbTooltip="Pin all checked properties"   nbTooltipIcon="flash-off-outline" nbTooltipStatus="warning" nbButton
              [disabled]="isDeleteButtonDisabled()" nbButton class="mr-5" status="warning" size="small" (click)="pin()">
              <nb-icon icon="flash-outline"></nb-icon>

              Pin

            </button>


            <app-pagination
              [isInDrawer]="isInDrawer"
              [currentPage]="currentPage"
              [totalPages]="calculateTotalPages()"
              (pageChanged)="onPageChanged($event)"
            ></app-pagination>
          </div>
        </td>


      </ng-container>


      <ng-container matColumnDef="pagination">
        <th mat-header-cell *matHeaderCellDef colspan="13">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['pagination']; sticky: true"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'isPined': row?.pivot?.isChecked == 1}" ></tr>

      <tr mat-footer-row *matFooterRowDef="['pagination-footer']; sticky: true" class="footer-table"></tr>

    </table>


  </section>


</div>


