<form [formGroup]="pageFormGroup" (ngSubmit)="onSubmit()">

  <nb-card>
    <nb-card-body>


      <div>
        <div class="mb-3" style="margin-bottom: 20px;">
          <div class="form-floating">
            <input type="text" class="form-control" formControlName="title" placeholder="Internal title">
            <label>Internal title*</label>
          </div>
        </div>
      </div>


      <div>
        <div *ngIf="widgets_classes && !widget?.isHtml && widget?.widget?.hasModel">
          <mat-form-field appearance="fill" class="full_width" style="width:100%!important;">
            <mat-label>Select Model</mat-label>
            <mat-select formControlName="model">
              <mat-option *ngFor="let clas of widgets_classes" [value]="clas">{{ clas }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div *ngIf="widget?.widget?.hasModel">
          <mat-form-field appearance="fill" class="full_width" style="width:100%!important;">
            <mat-label>Select page</mat-label>
            <mat-select formControlName="entity_id">
              <mat-option *ngFor="let clas of dropdownOptions" [value]="clas.id">{{ clas.title }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div *ngIf="widget?.widget?.hasChildrens">
          <div class="mb-3" style="margin-bottom: 20px;">
            <div class="form-floating">
              <input type="number" class="form-control" formControlName="limit" placeholder="Limit">
              <label>Limit*</label>
            </div>
          </div>
        </div>
      </div>




      <div class="row">
        <div class="col-12 col-md-8">
          <button nbButton status="primary" size="tiny" class="btn-ico-w" (click)="translate()" type="button">
            <nb-icon icon="globe-outline"></nb-icon>
            <span>Automatic translation</span>
          </button>

        </div>
      </div>



      <nb-tabset>



        <nb-tab *ngFor="let locale of locales; let i = index" tabTitle="{{ locale }}">


          <div *ngIf="!widget?.isHtml">
            <div [formGroup]="getTitleFormGroup(i)">
              <div class="mb-3" *ngFor="let field of widgetFields">

                <div *ngIf="field.type !== 'checkbox' && field.type !== 'editor' && field.type !== 'file' && field.type !== 'link'" class="form-floating">
                  <input type="text" id="{{ field.id }}" class="form-control" [formControlName]="field.id"
                         [placeholder]="field.name">
                  <label for="{{ field.id }}">{{ field.name }}<span *ngIf="field.required" class="text-danger">*</span></label>
                </div>


                <div *ngIf="field.type == 'checkbox'" >
                  <input type="checkbox" id="{{ field.id }}" [formControlName]="field.id" style="margin-right:5px;">
                  <label for="{{ field.id }}" >{{ field.name }}<span *ngIf="field.required" class="text-danger">*</span></label>
                </div>



                <app-editor-widget [locale]="locale"  [options]="options" *ngIf="field.type === 'editor'"
                                   [formControl]="getTitleFormControl(locale, 'content')"></app-editor-widget>

                <div *ngIf="field.type === 'file'">
                  <div class="row">
                    <div class="col-3">
                      <label for="fileInput{{i}}" style="cursor: pointer;">
                        <img [src]="getTranslationConfiguration(i)?.[field.id]" alt="Image" class="img-fluid"
                             *ngIf="getTranslationConfiguration(i)?.[field.id]; else noImage">
                      </label>
                      <ng-template #noImage>
                        <span>No image available</span>
                      </ng-template>
                    </div>
                    <div class="col-9">
                      <label for="{{ field.id }}">{{ field.name }}<span *ngIf="field.required" class="text-danger">*</span></label>
                      <input
                        id="fileInput{{i}}"
                        type="file"
                        class="form-control"
                        (change)="handleFileInputChange($event, field.id, locale, i)"/>
                    </div>
                  </div>
                </div>


                <div *ngIf="field.type === 'link'">
                  <mat-form-field appearance="fill" class="full_width" style="width:100%!important;">
                    <mat-label>{{ field.name }}<span *ngIf="field.required" class="text-danger">*</span></mat-label>
                    <mat-select id="{{ field.id }}" [formControlName]="field.id" (selectionChange)="updateLinkValue($event, field.id)">
                      <mat-option [value]="null">Select</mat-option> <!-- Option vide -->
                      <mat-option *ngFor="let clas of dropdownOptions" [value]="clas.id">{{ clas.title }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>


                <div class="text-danger"
                     *ngIf="getTitleFormGroup(i).get(field.id)?.invalid && getTitleFormGroup(i).get(field.id)?.touched">
                  {{ field.name }} is required
                </div>
                <small *ngIf="field.help">{{ field.help }}</small>
              </div>
            </div>
          </div>



          <div [formGroup]="getTitleFormGroup(i)" *ngIf="widget?.isHtml">
            <app-editor-widget [locale]="locale" [formControl]="getTitleFormControl(locale, 'content')"></app-editor-widget>
          </div>
        </nb-tab>
      </nb-tabset>

    </nb-card-body>


    <nb-card-footer>

      <div>
        <button nbButton status="danger" class="btn-ico-w" type="button" (click)="delete()">
          <nb-icon icon="trash-2-outline"></nb-icon>
          <span>Delete</span>
        </button>

        <button nbButton status="primary" class="btn-ico-w" style="float:right" [disabled]="!pageFormGroup.valid">
          <nb-icon icon="save-outline"></nb-icon>
          <span>Save</span>
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
