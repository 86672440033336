import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PropertiesService} from "../../../../modules/properties/services/properties.service";
import {ActivatedRoute} from "@angular/router";
import { OwlOptions } from 'ngx-owl-carousel-o';
import {DomSanitizer} from "@angular/platform-browser";
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-public-detail-property',
  templateUrl: './public-detail-property.component.html',
  styleUrls: ['./public-detail-property.component.scss']
})
export class PublicDetailPropertyComponent  implements OnInit, AfterViewInit{
  datas!: any;
  propertyId!: any;




  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  constructor(
    public propertiesService:PropertiesService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.route.paramMap.subscribe(params => {
      this.propertyId = params.get('id');
      this.getProperty();
    });
  }

  getProperty() {
      this.propertiesService.getPropertyDetailsForPublic(this.propertyId).subscribe(
        (response) => {
          this.datas = response?.data;

        });
  }

  showGallery = false;
  selectedImageIndex = 0;

  openGallery(index: number) {
    this.selectedImageIndex = index;
    this.showGallery = true;
  }


  closeGallery() {
    this.showGallery = false;
  }


  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  captureScreen() {
    const element = document.getElementById('element-to-capture');

    if (element) {
      html2canvas(element).then(canvas => {
        // Convertir le canvas en image
        const imgData = canvas.toDataURL('image/png');

        // Créer un élément image
        const img = new Image();
        img.src = imgData;

        // Ouvrir l'image dans une nouvelle fenêtre ou l'enregistrer
        const w = window.open('');
        if (w) {
          w.document.write('<img src="' + img.src + '" width="100%"/>');
        } else {
          console.error("La fenêtre n'a pas pu être ouverte.");
        }
      });
    } else {
      console.error("L'élément à capturer n'a pas été trouvé.");
    }
  }


}
