import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { AbstractControl, FormControl } from "@angular/forms";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html'
})
export class InputCheckboxComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: AbstractControl | null = null;
  @Input() name: string = '';
  @Input() label: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() isLoaded: boolean = false;
  inputValue!: any;
  originalValue: string = '';

  private controlSubscription: Subscription | undefined;

  onInputChange() {
    this.shouldShowError();
    if (this.control instanceof FormControl) {
      this.control.setValue(this.inputValue);
      this.control.markAsDirty();
      this.control.markAsTouched();
      this.valueChange.emit(this.control.value);
    }
  }

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    if (this.isLoaded) {
      if (this.inputValue == 1) {
        this.inputValue = true;
      } else {
        this.inputValue = false;
      }

      this.originalValue = this.inputValue;

      if (this.control instanceof FormControl) {
        if(this.control.value == 1 || this.control.value == '1') {
          this.inputValue = this.control.value;
          this.control?.setValue(true);
          this.control?.updateValueAndValidity();
        }

      }
    }
  }

  shouldShowError(): boolean {
    return !!(
      this.control &&
      this.control.invalid &&
      this.inputValue !== this.originalValue
    );
  }

  getErrorMessage(): string {
    if (this.control && this.control.errors) {
      if (this.control.errors['required']) {
        return 'This field is required.';
      } else if (this.control.errors['minlength']) {
        return `Minimum length is ${this.control.errors['minlength'].requiredLength} characters.`;
      }
    }
    return 'Invalid input.';
  }

  get isRequiredControl(): boolean {
    return !!this.control &&
      !!this.control.validator &&
      !!this.control.validator({} as FormControl)?.['required'];
  }

  ngOnDestroy(): void {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }
}
