
<div class="toolbar">
  <button (click)="format('bold')" type="button"><b>B</b></button>
  <button (click)="format('italic')" type="button"><i>I</i></button>
  <button (click)="format('underline')" type="button"><u>U</u></button>
  <button (click)="format('h1')" type="button">H1</button>
  <button (click)="format('h2')" type="button">H2</button>
  <button (click)="format('h3')" type="button">H3</button>
  <button (click)="toggleBulletList()" type="button">List</button>

  <button (click)="viewSourceCode()" type="button">
    {{ showSourceCode ? 'Editor' : '<>' }}
  </button>
</div>

<div
  class="editor editor{{locale}}"
  [style.display]="editorDisplayStyle"
  contenteditable="true"
  (input)="onContentChange($event)"
  #editor [innerHTML]="insertFirst"></div>

<!-- Zone pour afficher le code source -->
<textarea
  #textareaRef
  class="source-code"
  *ngIf="showSourceCode"
  [ngModel]="formControl.value"
  (ngModelChange)="formControl.setValue($event)">
</textarea>
