import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from "../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class SlidersService {
  private baseUrl!: string;
  private apiUrl!: string;
  private publicApiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService // Inject the authentication service
  ) {
    // Get the selected domain from the authentication service
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/sliders`;
      this.baseUrl = `${selectedDomain.domainName}/sliders`;
    } else {
      console.error('Selected domain is not set.');
    }
  }

  listPages(parent:any): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }


  getPage(id:any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/get/' + id);
  }


  // Method to update page positions
  updateNodes(nodes:any): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-ranks`, {'data' : nodes});
  }


  createOrUpdatePage(pageData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/createOrUpdatePage`, pageData);
  }

  uploadImage(imageData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/upload-image`, imageData);
  }

  getuploadUrl() {
    return `${this.baseUrl}/upload-image`;
  }
}
