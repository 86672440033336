<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">
    <nb-action>
      <button nbButton (click)="showfilter();">
        <nb-icon icon="funnel"></nb-icon>
        <span *ngIf="isMobile==false">Filters</span>
        <span *ngIf="nbFilters"> ({{nbFilters}})</span>
      </button>
    </nb-action>


    <nb-action>
      <button nbButton (click)="assignSearch();" status="success">
        <nb-icon icon="save-outline" style="color:#fff;"></nb-icon>
        <span *ngIf="isMobile==false">Save filters to</span>
      </button>
    </nb-action>

    <nb-action *ngIf="!isMobile">
      <strong class="mr-5">{{totalProperties}}</strong> properties
    </nb-action>


    <nb-action *ngIf="!owner_id">
      <nb-toggle status="basic" (click)="toggleSold()"><span *ngIf="!isMobile">Show inactive properties</span>
      </nb-toggle>
    </nb-action>


  </nb-actions>

  <nb-actions size="small" style="margin-left: auto">

    <nb-action class="action-right">
      <button nbButton status="warning" class="btn-ico-w" (click)="importProperty()">
        <nb-icon icon="plus-outline"></nb-icon>
        Import
      </button>
    </nb-action>

    <nb-action class="action-right">
      <button nbButton status="primary" class="btn-ico-w" (click)="addProperty()">
        <nb-icon icon="plus-outline"></nb-icon>
        Add property
      </button>
    </nb-action>
  </nb-actions>

</nb-layout-header>


<div [ngClass]="{'p-20': !isInDrawer}">


  <section class="table-container"   #tableContainer   (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"  (mouseup)="onMouseUp()">

    <ng-template #noResults>
      <nb-alert accent="basic" class="empty" *ngIf="!isLoadingResults">
        <div>
          <nb-icon icon="alert-circle-outline"></nb-icon>
          No result found.
        </div>
      </nb-alert>
    </ng-template>

    <table matSort mat-table [dataSource]="properties" class="mat-elevation-z8"
           *ngIf="properties.length > 0; else noResults" (matSortChange)="sortData($event)">


      <!-- Edit Column -->
      <ng-container matColumnDef="select" *ngIf="isInDrawer">
        <th mat-header-cell *matHeaderCellDef>Select</th>
        <td class="select-column" mat-cell *matCellDef="let property">
          <nb-checkbox status="basic" (click)="select(property)"></nb-checkbox>
        </td>
      </ng-container>


      <!-- is available Column -->
      <ng-container matColumnDef="is_available">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="is_available">Availability</th>
        <td mat-cell *matCellDef="let property">

          <nb-tag-list>
            <nb-tag status="basic" appearance="filled" text="Pending" *ngIf="property.is_available == 0"></nb-tag>
            <nb-tag status="success" appearance="filled" text="Available" *ngIf="property.is_available == 1"></nb-tag>
            <nb-tag status="danger" appearance="filled" text="Sold" *ngIf="property.is_available == 2"></nb-tag>
            <nb-tag status="basic" appearance="filled" text="Out of market" *ngIf="property.is_available == 3"></nb-tag>
          </nb-tag-list>

        </td>
      </ng-container>

      <!-- Photo Column -->
      <ng-container matColumnDef="photo" sticky>
        <th mat-header-cell *matHeaderCellDef>Photo</th>
        <td mat-cell *matCellDef="let element">
          <img src="{{element.photo?.full_url}}" *ngIf="element.photo?.full_url" width="90" class="imageCorner"/>

          <span *ngIf="!element.photo?.full_url">No photo</span>

        </td>
      </ng-container>


      <!-- reference Column -->
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef>Ref</th>
        <td mat-cell *matCellDef="let property">{{ property.reference}}</td>
      </ng-container>


      <!-- shared reference Column -->
      <ng-container matColumnDef="mls_reference">
        <th mat-header-cell *matHeaderCellDef>Shared Ref.</th>
        <td mat-cell *matCellDef="let property">
          <span>{{ property.mls_reference}}</span>

        </td>
      </ng-container>

      <!-- shared reference Column -->
      <ng-container matColumnDef="agency">
        <th mat-header-cell *matHeaderCellDef>Agency</th>
        <td mat-cell *matCellDef="let property">
          <app-contact-pop-hover [contact]="property.agency"></app-contact-pop-hover>
        </td>
      </ng-container>


      <!-- Price Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="price">Price</th>
        <td mat-cell *matCellDef="let property">{{ property.price | currencyFormat }}</td>
      </ng-container>


      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let property">{{ property.location?.name }}</td>
      </ng-container>


      <!-- bedrooms Column -->
      <ng-container matColumnDef="bedrooms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="bedrooms">Bedrooms</th>
        <td mat-cell *matCellDef="let property">

          <span *ngIf="property.bedrooms">{{ property.bedrooms }}</span>
          <nb-icon icon="close-outline" *ngIf="!property.bedrooms"></nb-icon>

        </td>
      </ng-container>


      <!-- bedrooms Column -->
      <ng-container matColumnDef="bathrooms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="bathrooms">Bathrooms</th>
        <td mat-cell *matCellDef="let property">

          <span *ngIf="property.bathrooms">{{ property.bathrooms }}</span>
          <nb-icon icon="close-outline" *ngIf="!property.bathrooms"></nb-icon>

        </td>
      </ng-container>


      <!-- Location Column -->
      <ng-container matColumnDef="categories">
        <th mat-header-cell *matHeaderCellDef>Categories</th>
        <td mat-cell *matCellDef="let property">
          <ng-container *ngIf="property.categories && property.categories.length > 0">
            <span *ngFor="let category of property.categories; let last = last">
              {{ category.name }}{{ !last ? ', ' : '' }}
            </span>
          </ng-container>
          <ng-container *ngIf="!property.categories || property.categories.length === 0">No Categories</ng-container>
        </td>


      </ng-container>


      <!-- M² Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="size">Size</th>
        <td mat-cell *matCellDef="let property">{{ property.size | sqmFormatPipe }}</td>
      </ng-container>


      <!-- M² Column -->
      <ng-container matColumnDef="lot_size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lot_size">Plot</th>
        <td mat-cell *matCellDef="let property">{{ property.lot_size | sqmFormatPipe }}</td>
      </ng-container>


      <!-- M² Column -->
      <ng-container matColumnDef="translations">
        <th mat-header-cell *matHeaderCellDef>Translations</th>
        <td mat-cell *matCellDef="let property" class="translation-column">
          <ng-container *ngFor="let locale of locales">
            <span *ngIf="hasTranslation(property, locale)">
              <img src="/assets/images/flags/{{locale}}.svg" width="24"/>
            </span>
            <span *ngIf="!hasTranslation(property, locale)">
              <img src="/assets/images/flags/{{locale}}.svg" width="24" style="opacity:0.3"/>
            </span>
          </ng-container>
        </td>
      </ng-container>


      <!-- Price Column -->
      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef>Last update</th>
        <td mat-cell *matCellDef="let property">{{ property.updated_at | customDate }}</td>
      </ng-container>


      <!-- Edit Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let property">
          <button nbButton outline (click)="openDetailPublicProperty(property.reference)" style="margin-right:5px;">
            <nb-icon icon="eye-outline"></nb-icon>
          </button>


          <button nbButton outline (click)="openDashboard(property.id)" style="margin-right:5px;">
            <nb-icon icon="home-outline"></nb-icon>
          </button>

          <button nbButton outline (click)="addProperty(property.id)">
            <nb-icon icon="edit-outline"></nb-icon>
          </button>

        </td>
      </ng-container>


      <ng-container matColumnDef="pagination-footer">
        <td mat-footer-cell *matFooterCellDef colspan="13">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </td>
      </ng-container>


      <ng-container matColumnDef="pagination">
        <th mat-header-cell *matHeaderCellDef colspan="15">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>



        </th>


      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['pagination']; sticky: true"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr mat-footer-row *matFooterRowDef="['pagination-footer']; sticky: true" class="footer-table"></tr>

    </table>


  </section>

</div>


