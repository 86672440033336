import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  ChangeDetectorRef, Optional
} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators, FormBuilder, FormArray} from '@angular/forms';
import {
  GetConfigurationService
} from "../../../../services/getConfiguration/get-configuration.service";
import {PropertiesService} from "../../services/properties.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {ContactsComponent} from "../../../contacts/pages/contacts/contacts.component";
import { NbToastrService, NbIconConfig } from '@nebular/theme';
import {ToastService} from "../../../../services/toast/toast.service";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";
import {ThreeDFormComponent} from "../../forms/three-d-form/three-d-form.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {SidebarFeaturesComponent} from "../sidebar-features/sidebar-features.component";
import {EventService} from "../../../../services/getConfiguration/event.service";
import {ModalFormContactComponent} from "../../../contacts/components/modal-form-contact/modal-form-contact.component";
import {ContactsService} from "../../../contacts/services/contacts.service";



export interface Location {
  name: string;
  id: string;
  area: string;
}

interface Translation {
  id: number;
  property_id: number;
  locale: string;
  title: string;
  description: string;
}


@Component({
  selector: 'app-modal',
  templateUrl: './modal-form-property.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFormPropertyComponent implements OnInit, AfterViewInit {

  @Input() propertyId: any;
  @Input() categories: any;
  @Input() locales!: string[];
  @Input() featureGroups: any;
  @Input() locations: any;
  @Input() videos: any;
  @Input() properties_status: any;
  @Input() owner_id: any;


  @Input() addressesCategories: any;
  @Input() genders: any;
  @Input() priorities: any;

  @Input() PHONE_TYPES: any;
  @Input() EMAIL_TYPES: any;
  @Input() SOCIALS: any;

  datas: any;
  areas!: any[];
  isLoaded = false;


  titleForm!: FormGroup;
  localeValues: { [key: string]: string } = {};


  formGroup = new FormGroup({
    is_available: new FormControl(0, [Validators.required]),
    reference: new FormControl('', [Validators.required]),
    shared_reference: new FormControl(''),
    price: new FormControl('', [Validators.pattern(/^\d*$/)]),
    description: new FormControl(''),
    categories: new FormControl('', [Validators.required]),
    location: new FormControl('', [Validators.required]),
    area: new FormControl(''),
    address: new FormControl(''),
    exclusivity: new FormControl<boolean>(false),

    bedrooms: new FormControl('', [Validators.pattern(/^\d*$/)]), // Champs pour le nombre de chambres
    bathrooms: new FormControl('', [Validators.pattern(/^\d*$/)]), // Champs pour le nombre de salles de bains
    size: new FormControl('', [Validators.pattern(/^\d*$/)]), // Champs pour la taille
    lot_size: new FormControl('', [Validators.pattern(/^\d*$/)]),

    owner_id: new FormControl(''),
    addresses_id_xml_feed: new FormControl(''),

    translations: new FormGroup({}),

  });


  constructor(
    private configurationService: GetConfigurationService,
    private propertiesService: PropertiesService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
    private toastrService: NbToastrService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private ContactsService: ContactsService,
  ) {

  }


  ngOnInit(): void {

    // Initialiser le formulaire avec la validation "required"
    this.titleForm = this.formBuilder.group({ });

    if (!this.propertyId) {
      this.isLoaded = true;
    }
  }

  loadOwner(): void {
    this.ContactsService.getContactDetail(this.owner_id).subscribe(
      (response) => {
        this.datas = [];
        this.datas.owner = response?.data;
        this.cdr.detectChanges();
      },
      (error) => {
        // Gérer les erreurs
      }
    );
  }

  ngAfterViewInit(): void {
    this.getProperty();
  }

  getProperty() {
    if (this.propertyId) {
      this.propertiesService.getPropertyDetails(this.propertyId).subscribe(
        (response) => {
          this.datas = response?.data;

          // Ajouter un champ pour chaque locale avec la validation "required"
          this.locales.forEach(locale => {
            const translationData: Translation | undefined = this.datas?.translations.find((translation: Translation) => translation.locale === locale);
            const fieldName = `title_${locale}`;
            const fieldNameDescription = `description_${locale}`;

            if (translationData) {

              this.titleForm.addControl(fieldName, this.formBuilder.control(translationData.title));
              this.titleForm.addControl(fieldNameDescription, this.formBuilder.control(translationData.description));
            } else {
              this.titleForm.addControl(fieldName, this.formBuilder.control(''));
              this.titleForm.addControl(fieldNameDescription, this.formBuilder.control(''));
            }
          });

          this.formGroup.addControl('translations', this.formBuilder.group({}));
          this.locales.forEach(locale => {
            const translationData: Translation | undefined = this.datas?.translations.find((translation: Translation) => translation.locale === locale);
            const fieldName = `title_${locale}`;
            const fieldNameDescription = `description_${locale}`;

            if (translationData) {
              (this.formGroup.get('translations') as FormGroup)?.addControl(fieldName, this.formBuilder.control(translationData.title));
              (this.formGroup.get('translations') as FormGroup)?.addControl(fieldNameDescription, this.formBuilder.control(translationData.description));
            } else {
              (this.formGroup.get('translations') as FormGroup)?.addControl(fieldName, this.formBuilder.control(''));
              (this.formGroup.get('translations') as FormGroup)?.addControl(fieldNameDescription, this.formBuilder.control(''));
            }
          });


          this.formGroup.controls['is_available'].setValue(this.datas?.is_available);
          this.formGroup.controls['is_available'].updateValueAndValidity();

          this.formGroup.controls['reference'].setValue(this.datas?.reference);
          this.formGroup.controls['reference'].updateValueAndValidity();

          this.formGroup.controls['shared_reference'].setValue(this.datas?.mls_reference);
          this.formGroup.controls['shared_reference'].updateValueAndValidity();


          this.formGroup.controls['address'].setValue(this.datas?.location_address);
          this.formGroup.controls['address'].updateValueAndValidity();


          this.formGroup.controls['price'].setValue(this.datas?.price);
          this.formGroup.controls['price'].updateValueAndValidity();



          this.formGroup.controls['exclusivity'].setValue(this.datas?.exclusivity);
          this.formGroup.controls['exclusivity'].updateValueAndValidity();


          this.formGroup.controls['categories'].setValue(this.datas?.categories);
          this.formGroup.controls['categories'].updateValueAndValidity();


          this.formGroup.controls['location'].setValue(this.datas?.location?.id);
          this.formGroup.controls['location'].updateValueAndValidity();

          this.formGroup.controls['area'].setValue(this.datas?.area?.id);
          this.formGroup.controls['area'].updateValueAndValidity();


          this.formGroup.controls['bedrooms'].setValue(this.datas?.bedrooms);
          this.formGroup.controls['bedrooms'].updateValueAndValidity();


          this.formGroup.controls['bathrooms'].setValue(this.datas?.bathrooms);
          this.formGroup.controls['bathrooms'].updateValueAndValidity();

          this.formGroup.controls['size'].setValue(this.datas?.size);
          this.formGroup.controls['size'].updateValueAndValidity();


          this.formGroup.controls['lot_size'].setValue(this.datas?.lot_size);
          this.formGroup.controls['lot_size'].updateValueAndValidity();


          this.formGroup.controls['owner_id'].setValue(this.datas?.owner?.id);
          this.formGroup.controls['owner_id'].updateValueAndValidity();

          this.formGroup.controls['addresses_id_xml_feed'].setValue(this.datas?.agency?.id);
          this.formGroup.controls['addresses_id_xml_feed'].updateValueAndValidity();


          this.isLoaded = true;
          this.cdr.detectChanges();

          this.formGroup.updateValueAndValidity();
        },
        (error) => {
          // Gérer les erreurs
        }
      );
    } else {

      this.propertiesService.getNewReference(this.propertyId).subscribe(
        (response) => {

          this.formGroup.controls['reference'].setValue(response?.data);
          this.formGroup.controls['reference'].updateValueAndValidity();

          this.isLoaded = true;
          this.cdr.detectChanges();

          this.formGroup.updateValueAndValidity();
        },
        (error) => {
          // Gérer les erreurs
        }
      );

      if(this.owner_id) {
        this.formGroup.get('owner_id')?.setValue(this.owner_id);
        this.formGroup.controls['owner_id'].updateValueAndValidity();
        this.loadOwner();
      }


      // Ajouter un champ pour chaque locale avec la validation "required"
      this.locales.forEach(locale => {
        const fieldName = `title_${locale}`;
        const fieldNameDescription = `description_${locale}`;
        (this.formGroup.get('translations') as FormGroup)?.addControl(fieldName, this.formBuilder.control(''));
        (this.formGroup.get('translations') as FormGroup)?.addControl(fieldNameDescription, this.formBuilder.control(''));
      });

    }
  }


  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }

  updatePhotosEventReciber(photos: any): void {
    this.datas.photos = photos;
  }

  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }

  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }


  submitForm(message:any) {
    this.markFormGroupTouched(this.formGroup);

    if(this.propertyId) {
      if (this.formGroup.valid) {
        const formData = this.formGroup.value;

        this.propertiesService.updateProperty(formData, this.propertyId).subscribe(
          (response) => {
            if(message) {
              this.toastService.showToast('Property updated successfully', 'Success!', 'success');
              this.drawerRef.close(response?.data);
            }
          },
          (error) => {
            console.error('Erreur lors de la création de la propriété', error);
          }
        );
      } else {
      }
    } else {

      if (this.formGroup.valid) {
        const formData = this.formGroup.value;

        this.propertiesService.createProperty(formData).subscribe(
          (response) => {
            this.toastService.showToast('Property created successfully', 'Success!', 'success');

            this.propertyId = response.data?.id;
            this.datas = response.data;
            this.cdr.detectChanges();

          },
          (error) => {
            this.toastService.showToast('Oups !', 'Error!', 'danger');
            this.cdr.detectChanges();
          }
        );
      } else {
      }
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }

  locationName!:string;
  updateLocation(controlName: string, newValue: any | null): void {
    const location = this.locations.find((user: Location) => user.id === newValue);
    if (location) {
      this.locationName = location?.name
      this.areas = location?.areas;
    } else {
      this.areas = [];
    }
    this.updateFormControlValue(controlName, newValue);
  }


  openOwners() {
    let modalRef = this._NzDrawerService.create({
      nzContent: ContactsComponent,
      nzWidth: '80vw',
      nzWrapClassName: 'nopadding',
      nzTitle: "Owners",

      nzContentParams: {
        showOnly: 'owners',
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((contact:any) => {
      if(contact) {
        this.formGroup?.get('owner_id')?.setValue(contact?.id);
        this.datas.owner = contact;
        this.cdr.detectChanges();
        this.toastService.showToast('Owner linked successfully', 'Success!', 'success');
        this.submitForm(false);
      }
    });
  }


  unlinkOwners() {

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Unlink the owner ?',
        text: 'Are you sure you want to unlink <strong>' + this.datas?.owner?.full_name + '</strong> as the owner of this property?',
        class: 'warning',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info:any) => {
      if(info) {
        this.toastService.showToast(this.datas?.owner?.full_name + ' unlinked successfully', 'Success!', 'success');

        this.datas.owner = null;

        this.formGroup.get('owner_id')?.setValue(null);
        this.formGroup.updateValueAndValidity()
        this.cdr.detectChanges();
        this.submitForm(false);
      }
    });

    return dialogRef;



  }



  openAgencies() {
    let modalRef = this._NzDrawerService.create({
      nzContent: ContactsComponent,
      nzWidth: '80vw',
      nzWrapClassName: 'nopadding',
      nzTitle: "Estate agencies",

      nzContentParams: {
        showOnly: 'estate_agencies',
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((contact:any) => {
      if(contact) {
        this.formGroup?.get('addresses_id_xml_feed')?.setValue(contact?.id);
        this.datas.agency = contact;
        this.cdr.detectChanges();
        this.toastService.showToast('Agency linked successfully', 'Success!', 'success');
        this.submitForm(false);
      }
    });
  }


  unlinkAgencies() {

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Unlink the agency ?',
        text: 'Are you sure you want to unlink <strong>' + this.datas?.agency?.full_name + '</strong> as the agency partner of this property?',
        class: 'warning',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info:any) => {
      if(info) {
        this.toastService.showToast(this.datas?.agency?.full_name + ' unlinked successfully', 'Success!', 'success');

        this.datas.agency = null;

        this.formGroup.get('addresses_id_xml_feed')?.setValue(null);
        this.formGroup.updateValueAndValidity()
        this.cdr.detectChanges();
        this.submitForm(false);
      }
    });

    return dialogRef;



  }

  add3d(event: Event, video=null) {

    event.stopPropagation();

    let modalRef = this._NzDrawerService.create({
      nzContent: ThreeDFormComponent,
      nzWidth: '500px',
      nzWrapClassName: 'nopadding',
      nzTitle: "Add 3D or video",

      nzContentParams: {
        propertyId: this.propertyId,
        video: video,
      }
    });

    modalRef.afterClose.subscribe((videos:any) => {
      if(videos) {
        this.datas.videos = videos;
        this.cdr.detectChanges();
      }
    });
  }


  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.datas?.videos, event.previousIndex, event.currentIndex);
    this.datas?.videos.forEach((video:any, idx:any) => {
      video.rank = idx + 1;
    });

    this.cdr.detectChanges();

    this.propertiesService.updateVideoPositions(this.datas?.videos)
      .subscribe(response => {

        this.toastService.showToast('Position updated successfully', 'Success!', 'success');

      }, error => {
        if (error.error && error.error.data) {
          // Parcourir les erreurs dans la propriété 'data'
          Object.keys(error.error.data).forEach(key => {
            const errorMessages: string[] = error.error.data[key];
            errorMessages.forEach((errorMessage: string) => {
              this.toastService.showToast(`${key}: ${errorMessage}`, 'Error!', 'danger');
            });
          });
        } else {
          this.toastService.showToast(`Error 57896`, 'Error!', 'danger');
        }
      });
  }

  openFeaturesSidebar(event: Event) {
    event.stopPropagation();

    let modalRef = this._NzDrawerService.create({
      nzContent: SidebarFeaturesComponent,
      nzWidth: '550px',
      nzWrapClassName: 'nopadding',
      nzTitle: "Link to features",

      nzContentParams: {
        propertyId: this.propertyId,
        checkedFeatures: this.datas?.features,
        featureGroups: this.featureGroups,
        locales: this.locales,
      }
    });

    modalRef.afterClose.subscribe(() => {
      this.getProperty();
      this.eventService.emitConfigurationUpdated();
      this.cdr.detectChanges();
    });
  }


  editOwner(id: any) {
    let width = '800px';

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormContactComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new contact",
      nzContentParams: {
        contactId: id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,


        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      if (closedId) {
        this.getProperty();
        this.eventService.emitConfigurationUpdated();
        this.cdr.detectChanges();
      }
    });
  }


  editAgency(id: any) {
    let width = '800px';

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormContactComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new contact",
      nzContentParams: {
        contactId: id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      if (closedId) {
        this.getProperty();
        this.eventService.emitConfigurationUpdated();
        this.cdr.detectChanges();
      }
    });
  }
}
