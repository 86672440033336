
<div class="input-group mb-3">
  <span class="input-group-text"><nb-icon icon="search-outline" style="color:#000!important;"></nb-icon></span>
  <div class="form-floating">
    <input [(ngModel)]="searchQuery" type="text" class="form-control" id="floatingInputGroup1" placeholder="Username">
    <label for="floatingInputGroup1">Search</label>
  </div>
</div>

<nb-card style="margin-bottom:5px!important;">
  <nb-card-header>
    <button nbButton class="btn-ico-w" style="float:right;" size="small" type="button" (click)="addFeatureGroup(null)" >
      <nb-icon icon="plus-outline" style="color:#000!important;"></nb-icon>
      Add group
    </button>

  </nb-card-header>

</nb-card>

<form [formGroup]="form">


  <nb-card *ngFor="let group of featureGroups" style="margin-bottom:5px!important;">
    <nb-card-header>

      {{ group.name }}
      <nb-icon class="pull-end" icon="edit-outline" style="color:#000!important; float:right" (click)="addFeatureGroup(group, null)"></nb-icon>

    </nb-card-header>
    <nb-card-body>

      <div class="empty" *ngIf="!group.features?.length">
        <nb-alert>No feature found</nb-alert>
      </div>

      <div *ngFor="let feature of group.features | filter: searchQuery">
        <!-- Ajoutez la condition pour filtrer les fonctionnalités -->
        <div class="form-check form-switch" >
          <input class="form-check-input" (change)="updateFeature()" id="feature{{feature?.id}}" type="checkbox" role="switch" [formControlName]="'feature_' + feature.id">

          <label class="form-check-label" >{{ feature.name }}
            <nb-icon class="pull-end" icon="edit-outline" style="color:#000!important; float:right" (click)="addFeature(group, feature)"></nb-icon>
          </label>
        </div>
      </div>


    </nb-card-body>

    <nb-card-footer class="text-end">
      <button nbButton size="small" class="btn-ico-w" (click)="addFeature(group)" >
        <nb-icon icon="plus-outline" style="color:#000!important; float:right"></nb-icon>
        <span>Add</span>
      </button>
    </nb-card-footer>
  </nb-card>


  <nb-card style="margin-bottom:5px!important;">
    <nb-card-header>
      <button nbButton class="btn-ico-w" style="float:right;" size="small" type="button" (click)="addFeatureGroup(null)" >
        <nb-icon icon="plus-outline" style="color:#000!important;"></nb-icon>
        Add group
      </button>

    </nb-card-header>

  </nb-card>
</form>
