import {ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {NbSidebarService, NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";
import {ClientSearchService} from "../../services/clientSearch.service";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EventService} from "../../../../services/getConfiguration/event.service";
import {ModalFormSearchComponent} from "../../components/modal-form-search/modal-form-search.component";
import {ModalFormPropertyComponent} from "../../../properties/components/modal-form-property/modal-form-property.component";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-client-search-properties-list',
  templateUrl: './client-search-properties-list.component.html',
  styleUrls: ['./client-search-properties-list.component.scss']
})

export class ClientSearchPropertiesListComponent  extends Share implements OnInit{
  @Input() isInDrawer:boolean = false;
  @Input() searchId!:any;

  currentPage = 1;
  pageSize = 10;
  totalProperties = 0;
  properties: any[] = [];

  featureGroups: any[] = [];
  locales: any[] = [];
  search!: any;
  categories!: any;
  locations!: any;
  properties_status!: any;
  genders!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;

  isLoadingResults:boolean = true;

  formGroup = new FormGroup({
    show_inactive: new FormControl(false)
  });

  displayedColumns: string[] = [
    'select',
    'pin',
    'is_available',
    'photo',
    'reference',
    'mls_reference',
    'agency',
    'price',
    'location',
    'categories',
    'bedrooms',
    'bathrooms',
    'size',
    'lot_size',
    'updated_at',
    'actions',
  ];

  constructor(
    private router: Router,
    private ClientSearchService: ClientSearchService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerRef: NzDrawerRef,
    private dialog: MatDialog,
    deviceService: DeviceDetectorService,
    public sidebarService: NbSidebarService
  ) {
    super('Child', deviceService);
    this.refreshConfiguration();
  }

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.categories = datas?.data?.categories;
      this.locations = datas?.data?.locations;
      this.properties_status = datas?.data?.properties_status;
      this.featureGroups = datas?.data?.featureGroups;
      this.locales = datas?.data?.locales;

      this.addressesCategories = datas?.data?.addressesCategories;
      this.genders = datas?.data?.genders;
      this.priorities = datas?.data?.priorities;

      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;

      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {

    if(!this.searchId) {
      this.searchId = this.route.snapshot.paramMap.get('id');
    }
    this.route.queryParams.subscribe(params => {
      const page = +params['page'] || 1;
      this.currentPage = page;
      this.loadProperties();
    });
  }

  loadProperties(): void {
    this.isLoadingResults = true;
    this.ClientSearchService.getProperties(this.searchId, this.currentPage, this.pageSize).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.search = response.data?.search;
        this.properties = response.data?.properties?.data;
        this.totalProperties = response.data?.properties.total;



      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );
  }


  onPageChanged(page: number): void {
    this.currentPage = page;
    this.loadProperties();
  }

  calculateTotalPages(): number {
    return Math.ceil(this.totalProperties / this.pageSize);
  }

  openProperties(id: any) {
    this.router.navigate(['admin', 'clients-search', id]);

  }


  addProperty(id?: any) {

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormPropertyComponent,
      nzWidth: '85vw',
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new property",
      nzContentParams: {
        propertyId: id,
        categories: this.categories,
        featureGroups: this.featureGroups,
        locations: this.locations,
        properties_status: this.properties_status,
        locales: this.locales,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,

        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,

      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadProperties();
    });
  }

  delete() {
    let width = '550px';
    if(this.isMobile) {
      width = '95vw';
    }

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: width,
      data: {
        title: 'Would you like to delete this properties from this search?',
        text : 'This action is irreversible and will permanently delete ' + this.count() + (this.count() === 1 ? ' property' : ' properties'),
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {

        this.deleteProperties();
      }
    });
  }

  selectedProperties: Set<any> = new Set<any>();

  select(searchId: any, propertyId: any): void {
    const key = `${searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties = storedProperties ? JSON.parse(storedProperties) : {};

    if (selectedProperties[key]) {
      // Inversez l'état de la propriété
      selectedProperties[key][propertyId] = !selectedProperties[key][propertyId];

      // Filtrer les propriétés cochées uniquement
      selectedProperties[key] = Object.entries(selectedProperties[key])
        .filter(([_, value]) => value === true)
        .reduce((obj, [prop, _]) => ({ ...obj, [prop]: true }), {});

      // Si toutes les propriétés sont décochées, supprimez l'entrée pour cette searchId
      if (Object.keys(selectedProperties[key]).length === 0) {
        delete selectedProperties[key];
      }
    } else {
      // Si l'entrée pour cette searchId n'existe pas, créez-la avec la propriété cochée
      selectedProperties[key] = { [propertyId]: true };
    }

    this.isBulkCheckboxChecked = false;
    console.log(selectedProperties);
    localStorage.setItem('selectedProperties', JSON.stringify(selectedProperties));
    this.cdr.detectChanges();
  }



  isSelected(searchId: any, propertyId: any): boolean {
    const key = `${searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties: Record<string, Record<string, boolean>> = storedProperties ? JSON.parse(storedProperties) : {};

    return selectedProperties[key] && selectedProperties[key][propertyId];
  }


  isDeleteButtonDisabled(): boolean {
    const key = `${this.searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties = (storedProperties ? JSON.parse(storedProperties) : {}) as Record<string, Record<string, boolean>>;

    return !selectedProperties[key] || Object.keys(selectedProperties[key]).length === 0;
  }


  count(): any {
    const key = `${this.searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties = (storedProperties ? JSON.parse(storedProperties) : {}) as Record<string, Record<string, boolean>>;

    return Object.keys(selectedProperties[key]).length;
  }

  listProperties(): any {
    const key = `${this.searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties = (storedProperties ? JSON.parse(storedProperties) : {}) as Record<string, Record<string, boolean>>;

    return Object.keys(selectedProperties[key]);
  }

  deleteProperties() {
    const key = `${this.searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties = (storedProperties ? JSON.parse(storedProperties) : {}) as Record<string, Record<string, boolean>>;

    const propertyIdsToDelete = Object.keys(selectedProperties[key]);

    if (propertyIdsToDelete.length === 0) {
      // Aucune propriété sélectionnée, vous pouvez gérer cela en conséquence
      return;
    }

    this.isLoadingResults = true;

    this.ClientSearchService.deleteProperties(this.searchId, propertyIdsToDelete).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.search = response.data?.search;
        this.properties = response.data?.properties?.data;
        this.totalProperties = response.data?.properties.total;
        this.currentPage = 1;



        // Effacer les propriétés sélectionnées du localStorage après suppression
        delete selectedProperties[key];
        localStorage.setItem('selectedProperties', JSON.stringify(selectedProperties));


        this.router.navigate([], {
          queryParams: {page: this.currentPage},
          queryParamsHandling: 'merge',
        });
      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );
  }

  editSearch(id: any) {
    let width = '550px';
    if(this.isMobile) {
      width = '95vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormSearchComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding form-search',
      nzTitle: "Client search",
      nzContentParams: {
        searchIs: id,

        categories: this.categories,
        featureGroups: this.featureGroups,
        locations: this.locations,
        properties_status: this.properties_status,
        locales: this.locales,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,

        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadProperties();
    });
  }


  pin() {
    const key = `${this.searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties = (storedProperties ? JSON.parse(storedProperties) : {}) as Record<string, Record<string, boolean>>;

    const propertyIdsToDelete = Object.keys(selectedProperties[key]);

    if (propertyIdsToDelete.length === 0) {
      // Aucune propriété sélectionnée, vous pouvez gérer cela en conséquence
      return;
    }

    this.isLoadingResults = true;

    this.ClientSearchService.pinProperties(this.searchId, propertyIdsToDelete).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.search = response.data?.search;
        this.properties = response.data?.properties?.data;
        this.totalProperties = response.data?.properties.total;
        this.currentPage = 1;

        this.router.navigate([], {
          queryParams: {page: this.currentPage},
          queryParamsHandling: 'merge',
        });

        delete selectedProperties[key];
        localStorage.setItem('selectedProperties', JSON.stringify(selectedProperties));
      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );
  }

  unPin(id:any) {


    this.isLoadingResults = true;

    this.ClientSearchService.unPinProperties(this.searchId, id).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.search = response.data?.search;
        this.properties = response.data?.properties?.data;
        this.totalProperties = response.data?.properties.total;
        this.currentPage = 1;

      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );
  }

  isBulkCheckboxChecked: boolean = false;

  selectBulk(): void {
    const key = `${this.searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties = storedProperties ? JSON.parse(storedProperties) : {};
    const areAllSelected = this.areAllPropertiesSelected();

    this.isBulkCheckboxChecked = !this.isBulkCheckboxChecked;


    // Si toutes les propriétés sont déjà sélectionnées, décochez-les toutes
    if (areAllSelected) {
      this.properties.forEach(property => {
        delete selectedProperties[key][property.id];
        if (Object.keys(selectedProperties[key]).length === 0) {
          delete selectedProperties[key];
        }
      });
    } else {
      // Sinon, cochez-les toutes
      this.properties.forEach(property => {
        selectedProperties[key] = {
          ...selectedProperties[key],
          [property.id]: true
        };
      });
    }

    // Mettez à jour le stockage local avec la nouvelle liste des propriétés sélectionnées
    localStorage.setItem('selectedProperties', JSON.stringify(selectedProperties));

    // Rafraîchissez la liste des propriétés
  }

  areAllPropertiesSelected(): boolean {
    const key = `${this.searchId}`;
    const storedProperties = localStorage.getItem('selectedProperties');
    const selectedProperties = storedProperties ? JSON.parse(storedProperties) : {};

    // Vérifiez si toutes les propriétés actuelles sont sélectionnées
    return this.properties.every(property => selectedProperties[key]?.[property.id]);
  }

  openDashboard(id?: any) {
    this.router.navigate(['admin', 'properties', 'dashboard', id]);

  }

  openDetailPublicProperty(id?: any) {
    window.open(`/properties/${id}`, '_blank');
  }

}
