import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {ContactsService} from "../../../contacts/services/contacts.service";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {LocationService} from "../../services/location.service";
import {NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";

@Component({
  selector: 'app-modal-form-location',
  templateUrl: './modal-form-location.component.html',
  styleUrls: ['./modal-form-location.component.scss']
})
export class ModalFormLocationComponent implements OnInit, AfterViewInit {
  @Input() location: any;
  @Input() parent_id: any;
  isLoaded = false;

  defaultLatitude = 48.8566;
  defaultLongitude = 2.3522;

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    alternative_names: new FormControl(''),
    id: new FormControl(''),
    parent_id: new FormControl(''),
  });


  constructor(
    private configurationService: GetConfigurationService,
    private propertiesService: ContactsService,
    private cdr: ChangeDetectorRef,
    private NzDrawerRef: NzDrawerRef,
    private LocationService: LocationService,
    private toastrService: NbToastrService,
    private toastService: ToastService,
  ) {

  }

  ngOnInit(): void {
    this.isLoaded = true;


    if(this.location) {
      this.formGroup.controls['id'].setValue(this.location?.id);
      this.formGroup.controls['id'].updateValueAndValidity();

      this.formGroup.controls['name'].setValue(this.location?.name);
      this.formGroup.controls['name'].updateValueAndValidity();

      this.formGroup.controls['alternative_names'].setValue(this.location?.alternative_names);
      this.formGroup.controls['alternative_names'].updateValueAndValidity();


      this.formGroup.controls['parent_id'].setValue(this.location?.parent_id);
      this.formGroup.controls['parent_id'].updateValueAndValidity();

    }

    if(this.parent_id) {
      this.formGroup.controls['parent_id'].setValue(this.parent_id);
      this.formGroup.controls['parent_id'].updateValueAndValidity();
    }

  }


  ngAfterViewInit(): void {
  }



  submitForm() {
    this.markFormGroupTouched(this.formGroup);

    const formData = this.formGroup.value;

    this.LocationService.createOrUpdate(formData).subscribe(
      (response) => {
        this.toastService.showToast('Location registered successfully', 'Success!', 'success');
        this.NzDrawerRef.close(response?.data);
      },
      (error) => {
        console.error('Erreur lors de la création de la propriété', error);
      }
    );

  }

  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }


  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }

  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }

  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }

  delete() {
    this.markFormGroupTouched(this.formGroup);


    this.LocationService.delete(this.formGroup?.get('id')?.value).subscribe(
      (response) => {
        this.toastService.showToast('Location deleted successfully', 'Success!', 'success');
        this.NzDrawerRef.close('delete');
      },
      (error) => {

        if (error.error && error.error.message) {

          this.toastService.showToast(error.error.message, 'Error!', 'danger');
          //this.NzDrawerRef.close(error.error.message);

        } else {
        }

      }
    );
  }


}
