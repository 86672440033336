<form class="row" (submit)="submitForm(true)" *ngIf="formGroup">

  <div class="col-12 col-md-6">




    <app-property-form
      [locales]="locales"
      [formGroup]="formGroup"
      [titleForm]="titleForm"
      [isLoaded]="isLoaded"
      [categories]="categories"
      [locations]="locations"
      [properties_status]="properties_status"
      [areas]="areas"
      [propertyId]="propertyId"
    ></app-property-form>

  </div>


  <div class="col-12 col-md-6" [ngClass]="{ 'disabled-section': (!propertyId || formGroup.invalid) }" >



    <nb-accordion multi>

      <nb-accordion-item [expanded]="datas?.owner">
        <nb-accordion-item-header>
          <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
          Owner <span *ngIf="datas?.owner?.full_name" style="margin-left:5px;">({{datas?.owner?.full_name}})</span>
        </nb-accordion-item-header>
        <nb-accordion-item-body >
          <div class="row" *ngIf="!datas?.owner">
            <div class="col-12">
              <button  nbButton class="btn-ico-w" size="small" type="button" (click)="openOwners()">
                <nb-icon icon="person-add-outline" style="color:#000!important;"></nb-icon>
                Link to owner
              </button>
            </div>
          </div>

          <div class="row" *ngIf="datas?.owner">
            <div class="col-12">
              <nb-card>
                <nb-card-body>
                  <nb-user size="giant"
                           name="{{datas?.owner?.full_name}}">
                  </nb-user>

                  <nb-list class="mt-2 min-list">

                    <nb-list-item *ngIf="datas?.owner?.full_address">
                      <strong>Address:</strong> {{datas?.owner?.full_address}}
                    </nb-list-item>


                    <nb-list-item *ngFor="let communication of datas?.owner?.communications">
                      <ng-container *ngIf="communication.communication_type === 'phone'">
                        <strong>Phone:</strong> {{ communication.value }} ({{ communication.communication_place }})
                      </ng-container>
                      <ng-container *ngIf="communication.communication_type === 'email'">
                        <strong>Email:</strong> {{ communication.value }} ({{ communication.communication_place }})
                      </ng-container>
                      <ng-container *ngIf="communication.communication_type === 'social'">
                        <strong>Social:</strong> <a [href]="communication.value" target="_blank">{{ communication.communication_place }}</a>
                      </ng-container>
                    </nb-list-item>



                  </nb-list>

                </nb-card-body>

                <nb-card-footer>


                  <div>
                    <nb-button-group multiple size="medium" class="btn-grp-action" >
                      <button nbButton fullWidth  class="btn-ico-w" size="small" type="button" (click)="editOwner(datas?.owner?.id)">
                        <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
                        Edit
                      </button>
                      <button nbButton fullWidth class="danger" class="btn-ico-w" size="small" type="button" (click)="openOwners()">
                        <nb-icon icon="refresh-outline" style="color:#000!important;"></nb-icon>
                        Change
                      </button>
                      <button nbButton fullWidth class="danger" size="small" type="button" (click)="unlinkOwners()">
                        <nb-icon icon="trash-outline" style="color:#ffffff!important;"></nb-icon>
                      </button>
                    </nb-button-group>

                  </div>


                </nb-card-footer>
              </nb-card>
            </div>

          </div>

        </nb-accordion-item-body>

      </nb-accordion-item>


      <nb-accordion-item [expanded]="datas?.agency">
        <nb-accordion-item-header>
          <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
          Agency

          <ng-container *ngIf="datas?.agency">
          <span *ngIf="datas?.agency?.full_name" style="margin-left:5px;">({{datas?.agency?.full_name}})</span> | {{formGroup.get('shared_reference')?.value}}
          </ng-container>
        </nb-accordion-item-header>
        <nb-accordion-item-body >
          <div class="row" *ngIf="!datas?.agency">
            <div class="col-12">
              <button [disabled]="!propertyId  || formGroup.invalid" nbButton class="btn-ico-w" size="small" type="button" (click)="openAgencies()">
                <nb-icon icon="person-add-outline" style="color:#000!important;"></nb-icon>
                Link to Agency
              </button>
            </div>
          </div>

          <div class="row" *ngIf="datas?.agency">
            <div class="col-12">
              <nb-card>
                <nb-card-body>
                  <nb-user size="giant"
                           name="{{datas?.agency?.full_name}}">
                  </nb-user>

                  <nb-list class="mt-2 min-list">

                    <nb-list-item *ngIf="datas?.agency?.full_address">
                      <strong>Address:</strong> {{datas?.agency?.full_address}}
                    </nb-list-item>


                    <nb-list-item *ngFor="let communication of datas?.agency?.communications">
                      <ng-container *ngIf="communication.communication_type === 'phone'">
                        <strong>Phone:</strong> {{ communication.value }} ({{ communication.communication_place }})
                      </ng-container>
                      <ng-container *ngIf="communication.communication_type === 'email'">
                        <strong>Email:</strong> {{ communication.value }} ({{ communication.communication_place }})
                      </ng-container>
                      <ng-container *ngIf="communication.communication_type === 'social'">
                        <strong>Social:</strong> <a [href]="communication.value" target="_blank">{{ communication.communication_place }}</a>
                      </ng-container>
                    </nb-list-item>





                  </nb-list>



                </nb-card-body>

                <nb-card-footer>


                  <div>
                    <nb-button-group multiple size="medium" class="btn-grp-action" >
                      <button nbButton fullWidth  class="btn-ico-w" size="small" type="button" (click)="editAgency(datas?.agency?.id)">
                        <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
                        Edit
                      </button>
                      <button nbButton fullWidth class="danger" class="btn-ico-w" size="small" type="button" (click)="openAgencies()">
                        <nb-icon icon="refresh-outline" style="color:#000!important;"></nb-icon>
                        Change
                      </button>
                      <button nbButton fullWidth class="danger" size="small" type="button" (click)="unlinkAgencies()">
                        <nb-icon icon="trash-outline" style="color:#ffffff!important;"></nb-icon>
                      </button>
                    </nb-button-group>

                  </div>


                </nb-card-footer>



                <nb-card-footer>
                <app-card>

                  <app-card-body>


                    <app-input-text [isLoaded]="isLoaded"
                                    [control]="getFormControl('shared_reference')"
                                    [name]="'shared_reference'"
                                    [placeholder]="'Enter a value'"
                                    [label]="'Shared reference'"
                                    [type]="'text'"
                                    [help]="'If the property is from another agent'"
                                    (valueChange)="onFormControlChange('shared_reference', $event)"
                    ></app-input-text>
                  </app-card-body>
                </app-card>
                </nb-card-footer>

              </nb-card>
            </div>

          </div>

        </nb-accordion-item-body>

      </nb-accordion-item>


      <nb-accordion-item [expanded]="false">
        <nb-accordion-item-header>
          <nb-icon icon="toggle-right-outline" style="color:#000!important;"></nb-icon>
          Features <span>({{datas?.features?.length}})</span>


          <button [disabled]="!propertyId || formGroup.invalid" nbButton class="btn-ico-w" size="small" type="button" (click)="openFeaturesSidebar($event)" style="margin-left:10px;">
            <nb-icon icon="plus-outline" style="color:#000!important;"></nb-icon>
            Add
          </button>

        </nb-accordion-item-header>
        <nb-accordion-item-body >

          <nb-tag size="tiny" style="margin-right:3px; margin-bottom:3px;" *ngFor="let feature of datas?.features" status="basic" appearance="filled" [text]="feature?.name"></nb-tag>
        </nb-accordion-item-body>

      </nb-accordion-item>

      <nb-accordion-item [expanded]="false">
        <nb-accordion-item-header>
          <nb-icon icon="video-outline" style="color:#000!important;"></nb-icon>
          3D / videos <span>({{datas?.videos?.length}})</span>

          <button [disabled]="!propertyId || formGroup.invalid" nbButton class="btn-ico-w" size="small" type="button" (click)="add3d($event, null)" style="margin-left:10px;">
            <nb-icon icon="plus-outline" style="color:#000!important;"></nb-icon>
            Add
          </button>

        </nb-accordion-item-header>
        <nb-accordion-item-body class="body-p-0">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col" class="text-end">Actions</th>
              </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">


              <tr *ngIf="!datas?.videos?.length" class="empty">
                <td colspan="3"> <nb-alert>No video found</nb-alert></td>
              </tr>

              <tr *ngFor="let video of datas?.videos" cdkDrag cdkDragLockAxis="y">
                <th class="col-xs">
                  <div class="drag-handle">
                    <ng-container [ngTemplateOutlet]="dragHandleTmpl">
                    </ng-container>
                  </div>
                </th>
                <td class="col-md"><nb-icon icon="video-outline" style="color:#000!important;"></nb-icon> {{  video.title }}</td>
                <td class="col-md text-end">
                  <button nbButton class="btn-ico-w" size="tiny" type="button" (click)="add3d($event, video)">
                    <nb-icon icon="edit-outline" style="color:#000!important;"></nb-icon>
                    Edit
                  </button>
                </td>

              </tr>
              </tbody>
            </table>
          <ng-template #dragHandleTmpl>
            <svg class="bi bi-list handle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                    clip-rule="evenodd" />
            </svg>
          </ng-template>

        </nb-accordion-item-body>

      </nb-accordion-item>



      <nb-accordion-item [expanded]="false">
        <nb-accordion-item-header>
          <nb-icon icon="image-outline" style="color:#000!important;"></nb-icon>
          Photos
          <span>({{datas?.photos?.length}})</span>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <app-images-upload
            [propertyId]="propertyId"
            [photos]="datas?.photos"
            (valueChange)="updatePhotosEventReciber($event)"

          ></app-images-upload>


        </nb-accordion-item-body>

      </nb-accordion-item>

    </nb-accordion>



  </div>


</form>

