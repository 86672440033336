import {ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {NbSidebarService, NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {ContactsComponent} from "../../../contacts/pages/contacts/contacts.component";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {ModalFormContactComponent} from "../../../contacts/components/modal-form-contact/modal-form-contact.component";
import {RegistrationsService} from "../../services/registrations.service";
import {PropertiesComponent} from "../../../properties/pages/properties/properties.component";
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-modal-registration-form',
  templateUrl: './modal-registration-form.component.html',
  styleUrls: ['./modal-registration-form.component.scss']
})
export class ModalRegistrationFormComponent extends Share implements OnInit {

  @Input() isLoading: boolean = false;
  @Input() searchIs: any;
  @Input() contact: any;
  @Input() related: any;
  @Input() property: any;
  @Input() categories: any;
  @Input() locales!: string[];
  @Input() featureGroups: any;
  @Input() locations: any;
  @Input() videos: any;
  @Input() properties_status: any;
  @Input() registrationStatus: any;
  @Input() address_id: any;
  @Input() agency_id: any;


  @Input() addressesCategories: any;
  @Input() genders: any;
  @Input() registrations_types: any;
  @Input() priorities: any;

  @Input() PHONE_TYPES: any;
  @Input() EMAIL_TYPES: any;
  @Input() SOCIALS: any;

  public datas!: any;

  properties: any[] = [];


  formGroup = new FormGroup({
    id: new FormControl('', []),
    address_id: new FormControl('', [Validators.required]),
    related_address_id: new FormControl('', [Validators.required]),


    status: new FormControl('', [Validators.required]),
    registration_type: new FormControl('', [Validators.required]),
    comment: new FormControl('', []),

    propertiesArray: this.fb.array([]),

  });


  constructor(
    private drawer: NzDrawerRef,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public drawerRef: NzDrawerRef,
    private toastrService: NbToastrService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private RegistrationsService: RegistrationsService,
    private fb: FormBuilder,
    deviceService: DeviceDetectorService,
    public sidebarService: NbSidebarService
  ) {
    super('Child', deviceService);
  }


  ngOnInit(): void {

    if(this.related?.id) {
      this.formGroup?.get('related_address_id')?.setValue(this.related?.id);
      this.formGroup?.updateValueAndValidity();
    }

    if(this.contact?.id) {
      this.formGroup?.get('address_id')?.setValue(this.contact?.id);
      this.formGroup?.updateValueAndValidity();
    }

    if(this.property?.id) {
      const propertiesArray = this.formGroup.get('propertiesArray') as FormArray;
        propertiesArray.push(this.fb.control(this.property));

      this.formGroup.setControl('propertiesArray', this.buildContactsArray());

      this.properties.push(this.property);

      this.formGroup.setControl('propertiesArray', this.buildContactsArray());


    }



    if (this.searchIs) {
      this.getDetail();
    }

  }


  openOwners() {

    let width = '80vw';
    if(this.isMobile) {
      width = '95vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ContactsComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Contacts",

      nzContentParams: {
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((contact: any) => {
      if (contact) {
        this.formGroup?.get('address_id')?.setValue(contact?.id);
        this.contact = contact;
        this.cdr.detectChanges();
        this.toastService.showToast('Owner linked successfully', 'Success!', 'success');
      }
    });
  }


  unlinkOwners() {

    let width = '500px';
    if(this.isMobile) {
      width = '95vw';
    }

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: width,
      data: {
        title: 'Unlink the owner ?',
        text: 'Are you sure you want to unlink <strong>' + this.contact?.full_name + '</strong> as the owner of this property?',
        class: 'warning',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.toastService.showToast(this.contact?.full_name + ' unlinked successfully', 'Success!', 'success');

        this.contact = null;

        this.formGroup.get('address_id')?.setValue(null);
        this.formGroup.updateValueAndValidity()
        this.cdr.detectChanges();
      }
    });

    return dialogRef;


  }


  editOwner(id: any) {


    let width = '800px';
    if(this.isMobile) {
      width = '95vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormContactComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Contact",
      nzContentParams: {
        contactId: id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,


        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      if (closedId) {
        this.cdr.detectChanges();
      }
    });
  }


  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }


  submitForm(message: any) {
    this.markFormGroupTouched(this.formGroup);

    const formData = this.formGroup.value;

    this.RegistrationsService.createContact(formData).subscribe(
      (response) => {
        if (message) {
          this.toastService.showToast('Registration registered successfully', 'Success!', 'success');
          this.drawerRef.close(response?.data);
        }
      },
      (error) => {
        let errorMessage = 'An error occurred while registering the contact';
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        this.toastService.showToast(errorMessage, 'Error!', 'danger');
      }
    );
  }


  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }


  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }


  onFormControlChangeTranslations(controlName: string, newValue: any | null): void {
    this.updateFormControlValueTranslation(controlName, newValue);
  }

  updateFormControlValueTranslation(controlName: string, newValue: any | null): void {
    const control = this.formGroup?.get('translations.' + controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }


  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }


  getDetail() {

    this.isLoading = true;
    this.RegistrationsService.getContactDetail(this.searchIs).subscribe(
      (response) => {
        this.isLoading = false;
        this.datas = response?.data;
        this.contact = response?.data?.address;
        this.related = response?.data?.related_address;

        this.formGroup.get('address_id')?.setValue(response?.data?.address_id);
        this.formGroup.get('related_address_id')?.setValue(response?.data?.related_address_id);



        this.properties = response?.data?.properties;
        const propertiesArray = this.formGroup.get('propertiesArray') as FormArray;
        this.properties.forEach(property => {
          propertiesArray.push(this.fb.control(property.id));
        });

        let status = null;
        if (response?.data?.status == 'pending') {
          status = "1";
        }


        if (response?.data?.status == 'accepted') {
          status = "2";
        }


        if (response?.data?.status == 'rejected') {
          status = "3";
        }

        this.formGroup.get('status')?.setValue(status);

        this.formGroup.get('id')?.setValue(response?.data?.id);
        this.formGroup.get('registration_type')?.setValue(response?.data?.registration_type);
        this.formGroup.get('comment')?.setValue(response?.data?.comment);


        this.formGroup.updateValueAndValidity();


      },
      (error) => {
        console.error('Erreur lors de la création de la propriété', error);
      }
    );

  }

  cancel() {

    let width = '500px';
    if(this.isMobile) {
      width = '95vw';
    }

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: width,
      data: {
        title: 'Would you like to delete this event?',
        text: 'This action is irreversible and will permanently delete the event.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.RegistrationsService.delete(this.formGroup?.get('id')?.value).subscribe(
          (response) => {
            this.drawerRef.close(true);
          }
        );


      }
    });
  }


  openRelated() {

    let width = '80vw';
    if(this.isMobile) {
      width = '90vw';
    }



    let modalRef = this._NzDrawerService.create({
      nzContent: ContactsComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Agencies",

      nzContentParams: {
        showOnly: 'estate_agencies',
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((related: any) => {
      if (related) {
        this.formGroup?.get('related_address_id')?.setValue(related?.id);
        this.related = related;
        this.cdr.detectChanges();
        this.toastService.showToast('Owner linked successfully', 'Success!', 'success');
      }
    });
  }


  unlinkRelated() {
    let width = '500px';
    if(this.isMobile) {
      width = '95vw';
    }


    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: width,
      data: {
        title: 'Unlink the owner ?',
        text: 'Are you sure you want to unlink <strong>' + this.related?.full_name + '</strong> as the owner of this property?',
        class: 'warning',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.toastService.showToast(this.related?.full_name + ' unlinked successfully', 'Success!', 'success');

        this.related = null;

        this.formGroup.get('related_address_id')?.setValue(null);
        this.formGroup.updateValueAndValidity()
        this.cdr.detectChanges();
      }
    });

    return dialogRef;


  }


  editRelated(id: any) {
    let width = '800px';
    if(this.isMobile) {
      width = '95vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormContactComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Contact",
      nzContentParams: {
        contactId: id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,


        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      if (closedId) {
        this.cdr.detectChanges();
      }
    });
  }


  openProperties() {

    let width = '80vw';
    if(this.isMobile) {
      width = '95vw';
    }


    let modalRef = this._NzDrawerService.create({
      nzContent: PropertiesComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Agencies",

      nzContentParams: {
        agency_id: this.related?.id ?? null,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((related: any) => {
      if (related) {
        const existingProperty = this.properties.find(property => property.id === related.id);

        if (!existingProperty) {
          this.properties.push(related);

          this.formGroup.setControl('propertiesArray', this.buildContactsArray());

          this.cdr.detectChanges();
          this.toastService.showToast('Property linked successfully', 'Success!', 'success');
        }

      }
    });
  }

  buildContactsArray(): FormArray {
    return this.fb.array(this.properties.map(property => this.fb.control(property?.id)));
  }

  removeProperty(contact: any): void {
    const index = this.properties.indexOf(contact);
    if (index !== -1) {
      this.properties.splice(index, 1);
      this.formGroup.setControl('propertiesArray', this.buildContactsArray());
    }
  }
}
