<nb-card>
  <nb-card-header>
    <span style="margin-right:10px;">Attendees</span>

    <button nbButton class="btn-ico-w" size="small" type="button" (click)="linkToContact($event)">
      <nb-icon icon="person-add-outline" style="color:#000!important;"></nb-icon>
      Add contact
    </button>
  </nb-card-header>
  <nb-card-body>

    <table class="table table-person">
      <thead>
      <tr>
        <th scope="col">Contact</th>
        <th scope="col">Type</th>
        <th scope="col">Status</th>
        <th scope="col">Action</th>
      </tr>
      </thead>
      <tbody>

      <tr *ngIf="!contacts.length">
        <td colspan="4" >
          <div class="empty empty-td text-center">
            No contact
          </div>
        </td>

      </tr>

      <tr *ngFor="let contact of contacts">
        <td>
          <app-contact-pop-hover [contact]="contact"></app-contact-pop-hover>
        </td>

        <td>
          <ng-container *ngIf="contact.categories && contact.categories.length > 0">
            <div *ngFor="let category of contact.categories; let last = last" style="font-size:0.8rem;">
              {{ category.human_name }}
            </div>
          </ng-container>
        </td>
        <td>

          <nb-tag-list size="small">
            <nb-tag status="basic" appearance="filled" size="small" text="Contact" *ngIf="contact?.priority == 0"></nb-tag>
            <nb-tag status="basic" appearance="filled" size="small" text="Prospect" *ngIf="contact?.priority == 1"></nb-tag>
            <nb-tag status="warning" appearance="filled" size="small" text="Visitor" *ngIf="contact?.priority == 2"></nb-tag>
            <nb-tag status="success" appearance="filled" size="small" text="Negotiating" *ngIf="contact?.priority == 3"></nb-tag>
            <nb-tag status="danger" appearance="filled" size="small" text="Finalized" *ngIf="contact?.priority == 4"></nb-tag>
          </nb-tag-list>

        </td>
        <td class="text-end">
          <nb-button-group multiple size="tiny" class="btn-grp-action" >
            <button nbButton fullWidth  class="btn-ico-w" size="small" type="button" (click)="editContact(contact?.id)">
              <nb-icon icon="person-outline" style="color:#000!important;"></nb-icon>
              Edit
            </button>

            <button nbButton fullWidth class="danger" size="small" type="button" (click)="removeContact(contact)">
              <nb-icon icon="trash-outline" style="color:#ffffff!important;"></nb-icon>
            </button>
          </nb-button-group>

        </td>
      </tr>

      </tbody>
    </table>



  </nb-card-body>
</nb-card>
