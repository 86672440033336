import {Component, ElementRef, Input, OnInit, Optional, Renderer2, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PageService} from "../../services/pages.service";
import {ToastService} from "../../../../services/toast/toast.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import * as pako from 'pako';
import {WidgetChooseComponent} from "../widget-choose/widget-choose.component";
import {WidgetsService} from "../../services/widgets.service";
import {WidgetFormComponent} from "../widget-form/widget-form.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";

@Component({
  selector: 'app-pages-form',
  templateUrl: './pages-form.component.html',
  styleUrls: ['./pages-form.component.scss']
})
export class PagesFormComponent implements OnInit {

  @Input() contentTypes: any;
  @Input() pageId: any;
  @Input() parent: any;
  @Input() widgets_classes: any;
  @Input() locales: any;
  @Input() widgets: any;
  @Input() template: any;
  @Input() isArticle: any;
  pageFormGroup!: FormGroup;
  @ViewChild('contentTextarea') contentTextarea!: ElementRef;
  @ViewChild('contentTextarea2') contentTextarea2!: ElementRef;

  public editor!: any;

  constructor(
    private pageService: PageService,
    private widgetsService: WidgetsService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
    private dialog: MatDialog,
    private configurationService: GetConfigurationService,

  ) {

    this.loadConfiguration();
  }

  isLoaded: boolean = false;
  public options: Object = {
    toolbarButtons: {
      // Key represents the more button from the toolbar.
      moreText: {
        // List of buttons used in the  group.
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

        // Alignment of the group in the toolbar.
        align: 'left',

        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
        buttonsVisible: 3
      },


      moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
        align: 'left',
        buttonsVisible: 3
      },

      moreRich: {
        buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
        align: 'left',
        buttonsVisible: 3
      },

      moreMisc: {
        buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'codeBeautifier', 'help'],
        align: 'right',
        buttonsVisible: 2
      }
    },

    toolbarButtonsXS: [['undo', 'redo'], ['bold', 'italic', 'underline']],


    // Additional upload params.
    imageUploadParams: {id: 'my_editor'},

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],


    imageUploadRemoteUrls:false,
    events: {
      "image.beforeUpload": function(files: any) {
        var editor: any = this;
        if (files.length) {
          // Create a File Reader.
          var reader = new FileReader();
          // Set the reader to insert images when they are loaded.
          reader.onload = function(e) {
            var result = e?.target?.result;
            editor.image.insert(result, null, null, editor.image.get());
          };
          // Read image as base64.
          reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      }
    }
  };



  titleFormArray!: FormArray;

  image_url!: any;
  big_image_url!: any;

  ngOnInit(): void {
    const titleFormArrayControls = this.locales.map((locale: any) => this.createTitleFormGroup(locale));
    this.titleFormArray = this.formBuilder.array(titleFormArrayControls);

    this.locales.forEach((locale: any) => {

      if(!this.locale) {
        this.locale = locale;
      }

      this.titleFormArray = this.formBuilder.array(
        this.locales.map((locale: any) => this.createTitleFormGroup(locale))
      );
    });

    this.pageFormGroup = this.formBuilder.group({
      id: [null],
      parent_id: [null],
      image: [''],
      big_image: [''],

      image_updated: [false], // Indicateur pour savoir si l'image a été mise à jour
      big_image_updated: [false], // Indicateur pour savoir si la grande image a été mise à jour
      isHomePage: [false], // Indicateur pour savoir si la grande image a été mise à jour
      containArticles: [false], // Indicateur pour savoir si la grande image a été mise à jour


      titleForm: this.titleFormArray,



    });


    /*
    this.titleForm.valueChanges.subscribe(() => {
      this.adjustTextareaHeight();
    });

     */

    this.loadPage();
  }



  createTitleFormGroup(locale: any): FormGroup {
    return this.formBuilder.group({
      id: [''],
      title: ['', Validators.required],
      content: [''],
      nav_title: [''],
      description: [''],
      meta_title: [''],
      meta_description: [''],
      meta_keywords: [''],
      slug: [''],
      path: [''],
      locale: [locale]
    });
  }


  get titleForms(): FormGroup[] {
    return this.titleFormArray.controls as FormGroup[];
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight + 2) + 'px';
  }




  public page!: any;
  dropdownPages!: any[];
  dropdownOptions!: any[];
  selectedParentId: any;
  IS_NEWS_SYSTEM: any;


  private loadConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.IS_NEWS_SYSTEM = datas?.data?.IS_NEWS_SYSTEM;

    });
  };

  loadPage() {
    if(this.pageId) {
      this.pageService.getPage(this.pageId).subscribe(datas => {
        this.page = datas?.page;
        this.activeWidgets = datas?.page?.widgets;
        this.dropdownPages = datas?.dropdownPages;
        this.dropdownOptions = this.generateDropdownOptions(this.dropdownPages); // Remplacez "yourPageData" par vos propres données

        if (this.page) {

          // Parcourez chaque traduction de la page
          this.page.translations.forEach((translation: any) => {
            // Trouvez l'index de la traduction dans le tableau des langues
            const index = this.locales.findIndex((locale: any) => locale === translation.locale);
            if (index !== -1) {
              // Si la traduction est trouvée, patchez les valeurs dans le FormGroup correspondant
              this.titleFormArray.at(index).patchValue(translation);
            }
          });


          this.pageFormGroup.get('id')?.setValue(this.page?.id)
          this.pageFormGroup.get('parent_id')?.setValue(this.page?.parent_id)
          this.pageFormGroup.get('isHomePage')?.setValue(this.page?.isHomePage)
          this.pageFormGroup.get('containArticles')?.setValue(this.page?.containArticles)
          //this.pageFormGroup.get('image')?.setValue(this.page?.image)
          //this.pageFormGroup.get('big_image')?.setValue(this.page?.big_image)

          this.image_url = this.page?.image_url;
          this.big_image_url = this.page?.big_image_url;


          const defaultParentId = this.pageFormGroup.value.parent_id;
          if (defaultParentId) {
            this.selectedParentId = defaultParentId;
          }

          this.isLoaded = true;

        }
      });
    } else {

      this.pageService.listPages(this.pageId).subscribe(datas => {
        this.dropdownPages = datas?.dropdownPages;
        this.dropdownOptions = this.generateDropdownOptions(this.dropdownPages); // Remplacez "yourPageData" par vos propres données

        this.pageFormGroup.get('parent_id')?.setValue(this.parent);


        const defaultParentId = this.pageFormGroup.value.parent_id;
        if (defaultParentId) {
          this.selectedParentId = defaultParentId;
        }

        this.isLoaded = true;
      });
    }
  }


  selectedImageFile: File | undefined;
  selectedBigImageFile: File | undefined;

  handleFileInput(event: any, fieldName: string): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          const result = e.target.result;
          if (typeof result === 'string') { // Vérification du type
            if (fieldName === 'image' || fieldName === 'big_image') {
              // Convertir l'image en format jpg
              this.convertToJpg(result, fieldName);
            }
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }


  convertToJpg(base64Data: string, fieldName: string): void {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/jpeg'); // Convertir l'image en jpg
        if (fieldName === 'image') {
          // Stockez temporairement l'image sélectionnée
          this.selectedImageFile = this.dataURItoBlob(dataURL);
          // Mettez à jour le chemin de l'image principale dans le formulaire
          this.pageFormGroup.get('image')?.setValue(dataURL);
          this.pageFormGroup.get('image_updated')?.setValue(true); // Marquez la grande image comme mise à jour
        } else if (fieldName === 'big_image') {
          // Stockez temporairement la grande image sélectionnée
          this.selectedBigImageFile = this.dataURItoBlob(dataURL);
          // Mettez à jour le chemin de la grande image dans le formulaire
          this.pageFormGroup.get('big_image')?.setValue(dataURL);
          this.pageFormGroup.get('big_image_updated')?.setValue(true); // Marquez la grande image comme mise à jour
        }
      }
    };
    img.src = base64Data;
  }

  dataURItoBlob(dataURI: string): File {
    // Diviser la chaîne de données en deux parties : le type MIME et les données réelles
    const [type, byteString] = dataURI.split(',');

    // Convertir la chaîne base64 en tableau de nombres entiers non signés
    const binary = atob(byteString);

    // Créer un tableau tampon pour stocker les octets binaires
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }

    // Créer un objet Blob à partir des données binaires
    const blob = new Blob([array], {type: type});

    // Créer un objet File à partir du Blob
    const fileName = 'image.jpg'; // Nom de fichier par défaut
    return new File([blob], fileName, {type: type});
  }


  generateDropdownOptions(pages: any[], level = 0): any[] {
    const options = [];
    for (const page of pages) {
      console.log(page.title)
      options.push({
        id: page.id,
        title: '-'.repeat(level) + ' ' + page.title
      });
      if (page.children && page.children.length > 0) {
        console.log(page.title)
        options.push(...this.generateDropdownOptions(page.children, level + 1));
      }
    }
    return options;
  }

  isFormValidForLocale(formGroup: FormGroup): boolean {
    return formGroup.valid; // Vous pouvez ajouter des conditions supplémentaires si nécessaire
  }

  getTitleFormGroup(index: any): FormGroup {
    return this.titleFormArray.at(index) as FormGroup;
  }

  getTitleFormControl(locale: string, fieldName: string): FormControl {
    return this.titleFormArray.controls.find(control => control.get('locale')?.value === locale)?.get(fieldName) as FormControl;
  }


  onSubmit(): void {
    if (this.pageFormGroup.valid) {
      const pageData = this.pageFormGroup.value;

      this.pageService.createOrUpdatePage(pageData).subscribe(
        response => {
          this.pageFormGroup.get('image_updated')?.setValue(false); // Marquez la grande image comme mise à jour
          this.pageFormGroup.get('big_image_updated')?.setValue(false); // Marquez la grande image comme mise à jour


          this.toastService.showToast('Page updated successfully', 'Success!', 'success');
          this.drawerRef.close();

          console.log('Page créée ou mise à jour avec succès', response);
        },
        error => {
          // Gérer les erreurs
          console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
        }
      );
    } else {
      // Le formulaire n'est pas valide, affichez un message d'erreur ou prenez d'autres mesures nécessaires
      console.error('Le formulaire est invalide. Veuillez vérifier les champs et réessayer.');
    }
  }

  public locale!:any;
  selectTab($event: any): void {
    this.locale = $event.tabId;
  }

  activeWidgets!:any[];

  openWidgets() {
    let width = '480px'


    let modalRef = this._NzDrawerService.create({
      nzContent: WidgetChooseComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Choose widget",
      nzContentParams: {
        widgets : this.widgets,
        template : this.template,
      }
    });

    modalRef.afterClose.subscribe((formData: any) => {
      const widgetData = {
        page_id: this.pageId,
        widget_id: formData?.widgetId,
        isHtml: formData?.isHtml,
      };

      this.widgetsService.create(widgetData).subscribe(datas => {
        this.activeWidgets = datas?.widgets
      });

    });
  }

  openWidget(widget: any) {


    let width = '800px'
    if(widget?.isHtml) {
      let width = '100vw'
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: WidgetFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Edit widget",
      nzContentParams: {
        widget : widget,
        widgets_classes : this.widgets_classes,
        dropdownOptions : this.dropdownOptions,
        dropdownPages : this.dropdownPages,
        locales : this.locales,
      }
    });

    modalRef.afterClose.subscribe((widget_id: any) => {

      this.loadPage();

    });
  }



  up(widget: any) {
    const currentIndex = this.activeWidgets.indexOf(widget);
    if (currentIndex > 0) {
      const previousWidget = this.activeWidgets[currentIndex - 1];
      // Swap ranks
      const tempRank = previousWidget.rank;
      previousWidget.rank = widget.rank;
      widget.rank = tempRank;
      // Swap positions in array
      this.activeWidgets[currentIndex - 1] = widget;
      this.activeWidgets[currentIndex] = previousWidget;
      this.updateWidgetRank();
    }
  }

  down(widget: any) {
    const currentIndex = this.activeWidgets.indexOf(widget);
    if (currentIndex < this.activeWidgets.length - 1) {
      const nextWidget = this.activeWidgets[currentIndex + 1];
      // Swap ranks
      const tempRank = nextWidget.rank;
      nextWidget.rank = widget.rank;
      widget.rank = tempRank;
      // Swap positions in array
      this.activeWidgets[currentIndex + 1] = widget;
      this.activeWidgets[currentIndex] = nextWidget;

      this.updateWidgetRank();
    }
  }

  updateWidgetRank() {
    this.widgetsService.updateRank(this.page?.id, this.activeWidgets).subscribe(
      (response) => {
        console.log('Rang du widget mis à jour avec succès :', response);
      },
      (error) => {
        console.error('Erreur lors de la mise à jour du rang du widget :', error);
      }
    );
  }


  cancel() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Would you like to delete this contact?',
        text: 'This action is irreversible and will permanently delete the event.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.pageService.delete(this.pageId).subscribe(
          (response) => {
            this.drawerRef.close(true);
          }
        );
      }
    });
  }

  translate() {
    const pageData = this.pageFormGroup.value;

    this.pageService.translate(pageData).subscribe(
      response => {

        // Pour chaque traduction reçue
        response.forEach((translation: any) => {
          // Trouvez l'index de la langue dans le tableau des langues supportées
          const index = this.locales.findIndex((locale: any) => locale === translation.locale);
          if (index !== -1) {
            // Si la langue est trouvée, patchez les valeurs dans le FormGroup correspondant
            this.titleFormArray.at(index).patchValue(translation);
          }
        });

        this.toastService.showToast('Page translated successfully', 'Success!', 'success');

      },
      error => {
        // Gérer les erreurs
        console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
      }
    );
  }
}
