<div *ngIf="datas">

  <div class="container"  style="padding-top:0px!important; max-width:940px;">

      <nb-card  style="margin-top:0px!important;"  id="element-to-capture">
        <nb-card-header>
          <h1>
            <div class="price text-end">{{ datas?.price | currencyFormat }}</div>
            <span *ngFor="let category of datas?.categories">{{category?.name}}</span>
            <span *ngIf="datas?.bedrooms"> {{datas?.bedrooms}} bedrooms</span>
            <div *ngIf="datas?.location"> <strong style="margin-right:5px;">{{datas?.location?.name}}</strong> <span *ngIf="datas?.area?.name">({{ datas?.area?.name }})</span></div>
          </h1>

          <nb-tag-list>
            <nb-tag status="basic" appearance="filled" text="Pending" *ngIf="datas?.is_available == 0"></nb-tag>
            <nb-tag status="success" appearance="filled" text="Available" *ngIf="datas?.is_available == 1"></nb-tag>
            <nb-tag status="danger" appearance="filled" text="Sold" *ngIf="datas?.is_available == 2"></nb-tag>
            <nb-tag status="basic" appearance="filled" text="Out of market" *ngIf="datas?.is_available == 3"></nb-tag>
          </nb-tag-list>

        </nb-card-header>
        <nb-card-body>


          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let photo of datas?.photos; let i = index">
              <img src="{{photo?.full_url}}" class="carousel-image" (click)="openGallery(i)" />
            </ng-template>
          </owl-carousel-o>
          <app-gallery *ngIf="showGallery" (galleryClosed)="closeGallery()" [photos]="datas?.photos"></app-gallery>


        </nb-card-body>


        <nb-card-body  *ngFor="let video of datas?.videos">
            <p *ngIf="video?.description && (video?.description != 'null')">{{video?.description}}</p>
            <iframe frameborder="0" width="100%" height="500" [src]="getSafeUrl(video?.src)" style="border: none;"></iframe>
        </nb-card-body>

        <nb-card-body>

          <nb-card style="margin-top:10px;">
            <nb-card-header>
              Property detail
            </nb-card-header>
            <div class="row">

              <div class="col-md-4">
                <nb-list>
                  <nb-list-item >
                    Ref. {{ datas?.reference }}
                  </nb-list-item>
                </nb-list>
              </div>


              <div class="col-md-4">
                <nb-list>
                  <nb-list-item *ngIf="datas?.bedrooms">
                    <i class="fa-solid fa-bed" style="margin-right: 5px;"></i> {{ datas?.bedrooms }} Bed.
                  </nb-list-item>
                </nb-list>
              </div>


              <div class="col-md-4">
                <nb-list>
                  <nb-list-item *ngIf="datas?.bathrooms">
                    <i class="fa-solid fa-bath" style="margin-right: 5px;"></i> {{ datas?.bathrooms }} Bath.
                  </nb-list-item>
                </nb-list>
              </div>
              <div class="col-md-4">
                <nb-list>
                  <nb-list-item *ngIf="datas?.size">
                    <i class="fa-solid fa-home" style="margin-right: 5px;"></i> {{ datas?.size }} m².
                  </nb-list-item>
                </nb-list>
              </div>
              <div class="col-md-4">
                <nb-list>
                  <nb-list-item *ngIf="datas?.lot_size">
                    <i class="fa-solid fa-tree" style="margin-right: 5px;"></i> {{ datas?.lot_size }} m².
                  </nb-list-item>
                </nb-list>
              </div>

            </div>
          </nb-card>

          <div class="row">
            <div class="col-8">
              <h2 *ngIf="datas?.title">{{datas?.title}}</h2>
              <div class="text-justify" [innerHTML]="datas?.description"></div>
            </div>
            <div class="col-4">

              <nb-card size="medium">
                <nb-card-header>
                  Features
                </nb-card-header>
                <nb-list>
                  <nb-list-item  *ngFor="let feature of datas?.features">
                    <nb-icon icon="checkmark-outline" pack="eva"></nb-icon> {{ feature?.name }}
                  </nb-list-item>
                </nb-list>
              </nb-card>

            </div>
          </div>






        </nb-card-body>

      </nb-card>





  </div>



</div>
