<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">


    <nb-action>
      <h1 class="title-page" *ngIf="category?.name">{{category.name}}</h1>
      <h1 class="title-page" *ngIf="!category?.name">Contacts</h1>
    </nb-action>

    <nb-action>
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input type="text" nbInput [(ngModel)]="searchTerm" (ngModelChange)="onSearchTermChange()">
      </nb-form-field>


    </nb-action>


    <nb-action>
      <strong style="margin-right:5px;">{{totalcontacts}}</strong> contacts
    </nb-action>


    <nb-action>
      <nb-select placeholder="Select status" (selectedChange)="onSelectChange($event)">
        <nb-option value="">All</nb-option>
        <nb-option *ngFor="let priority of priorities" [value]="priority.value">{{ priority.name }}</nb-option>
      </nb-select>
    </nb-action>


  </nb-actions>

  <nb-actions size="small" style="margin-left: auto">

    <nb-action class="action-right">
      <button nbButton status="primary" class="btn-ico-w" (click)="addContact()">
        <nb-icon icon="plus-outline"></nb-icon>
        Add new contact
      </button>
    </nb-action>
  </nb-actions>

</nb-layout-header>


<div [ngClass]="{'p-20': !isInDrawer}">

  <section class="table-container">


    <ng-template #noResults>
      <nb-alert accent="basic" class="empty" *ngIf="!isLoadingResults">
        <div>
        <nb-icon icon="alert-circle-outline"></nb-icon>
        No result found.</div>
      </nb-alert>
    </ng-template>

      <table matSort mat-table  (matSortChange)="sortData($event)" [dataSource]="contacts" class="mat-elevation-z8" *ngIf="contacts.length > 0; else noResults">

      <!-- Edit Column -->
      <ng-container matColumnDef="select" *ngIf="isInDrawer && !showShowCheckbox" >
        <th mat-header-cell *matHeaderCellDef>Select</th>
        <td class="select-column" mat-cell *matCellDef="let property">
          <nb-checkbox status="basic" (click)="select(property)" ></nb-checkbox>
        </td>
      </ng-container>


      <!-- is available Column -->
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header="priority">Status</th>
        <td mat-cell *matCellDef="let property">

          <nb-tag-list>
            <nb-tag status="basic" appearance="filled" text="Contact" *ngIf="property?.priority == 0"></nb-tag>
            <nb-tag status="basic" appearance="filled" text="Prospect" *ngIf="property?.priority == 1"></nb-tag>
            <nb-tag status="warning" appearance="filled" text="Visitor" *ngIf="property?.priority == 2"></nb-tag>
            <nb-tag status="success" appearance="filled" text="Negotiating" *ngIf="property?.priority == 3"></nb-tag>
            <nb-tag status="danger" appearance="filled" text="Finalized" *ngIf="property?.priority == 4"></nb-tag>
          </nb-tag-list>

        </td>
      </ng-container>

      <!-- reference Column -->
      <ng-container matColumnDef="full_name" >
        <th mat-header-cell *matHeaderCellDef >Name</th>
        <td mat-cell *matCellDef="let property">
          <app-contact-pop-hover [contact]="property"></app-contact-pop-hover>

        </td>
      </ng-container>

      <!-- reference Column -->
      <ng-container matColumnDef="xml_feed_url">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header="xml_feed_url">Xml</th>
        <td mat-cell *matCellDef="let property">
          <div *ngIf="property.xml_feed_url">
              <nb-icon icon="checkmark-square-2-outline"></nb-icon>
          </div>

        </td>
      </ng-container>


      <!-- Location Column -->
      <ng-container matColumnDef="categories">
        <th mat-header-cell *matHeaderCellDef>Categories</th>
        <td mat-cell *matCellDef="let property">
          <ng-container *ngIf="property.categories && property.categories.length > 0">
            <span *ngFor="let category of property.categories; let last = last">
              {{ category.human_name }}{{ !last ? ', ' : '' }}
            </span>
          </ng-container>
          <ng-container *ngIf="!property.categories || property.categories.length === 0">No Categories</ng-container>
        </td>
      </ng-container>


      <!-- Price Column -->
      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header="updated_at">Last update</th>
        <td mat-cell *matCellDef="let property">{{ property.updated_at | customDate }}</td>
      </ng-container>


      <!-- Edit Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef  class="text-end">Actions</th>
        <td mat-cell *matCellDef="let property" class="text-end">
          <nb-button-group>


            <button *ngIf="!isInDrawer" nbButtonToggle outline (click)="openDashboard(property.id)" (auxclick)="openDashboardNewTab(property.id)">
              <nb-icon icon="home-outline"></nb-icon>
            </button>

            <button nbButtonToggle outline (click)="addContact(property.id)">
              <nb-icon icon="edit-outline"></nb-icon>
            </button>

          </nb-button-group>



        </td>
      </ng-container>


      <ng-container matColumnDef="pagination-footer">
        <td mat-footer-cell *matFooterCellDef colspan="11">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </td>
      </ng-container>


      <ng-container matColumnDef="pagination">
        <th mat-header-cell *matHeaderCellDef colspan="11">
          <app-pagination
            [isInDrawer]="isInDrawer"
            [currentPage]="currentPage"
            [totalPages]="calculateTotalPages()"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['pagination']; sticky: true"> </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr mat-footer-row *matFooterRowDef="['pagination-footer']; sticky: true" class="footer-table"></tr>

    </table>

  </section>

</div>


