import {Component, Input, OnInit, Optional, ViewChild} from '@angular/core';

import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {ModalFormContactComponent} from "../../../contacts/components/modal-form-contact/modal-form-contact.component";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {debounceTime, switchMap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Subject} from "rxjs";
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {RegistrationsService} from "../../services/registrations.service";
import {ModalRegistrationFormComponent} from "../../components/modal-registration-form/modal-registration-form.component";
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";
import {NbSidebarService} from "@nebular/theme";

@Component({
  selector: 'app-registrations',
  templateUrl: './registrations.component.html',
  styleUrls: ['./registrations.component.scss']
})
export class RegistrationsComponent extends Share implements OnInit {
  @Input() isInDrawer:boolean = false;
  @Input() contact!:any;
  @Input() agency!:any;
  @Input() property!:any;

  displayedColumns: string[] = ['status', 'full_name', 'registration_type', 'related_address_id', 'properties_count', 'updated_at', 'actions'];

  isLoadingResults = true;

  contacts: any[] = [];
  showOnly!: any;
  genders!: any;
  registrations_types!: any;
  registrationStatus!: any;
  priorities!: any;
  addressesCategories!: any;
  PHONE_TYPES!: any;
  SOCIALS!: any;
  EMAIL_TYPES!: any;
  locations!: any;

  private searchTermChanged: Subject<string> = new Subject<string>();

  formGroup = new FormGroup({
    status: new FormControl('', [Validators.required]),
  });

  constructor(
    private RegistrationsService: RegistrationsService,
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public _NzDrawerRef: NzDrawerRef,
    private configurationService: GetConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    deviceService: DeviceDetectorService,
    public sidebarService: NbSidebarService
  ) {
    super('Child', deviceService);
    this.configurationService.getConfiguration().subscribe(datas => {
      this.priorities = datas?.data?.priorities;
      this.genders = datas?.data?.genders;
      this.registrations_types = datas?.data?.registrations_types;
      this.registrationStatus = datas?.data?.registrationStatus;
      this.locations = datas?.data?.locations;
      this.PHONE_TYPES = datas?.data?.PHONE_TYPES;
      this.EMAIL_TYPES = datas?.data?.EMAIL_TYPES;
      this.SOCIALS = datas?.data?.SOCIALS;
    });
  }


  previousSlug: string = '';
  slug: string = '';
  category!:any;

  ngOnInit(): void {


    this.searchTermChanged.pipe(debounceTime(300)).subscribe(() => {
      this.loadRegistrations();
    });


    if (this._NzDrawerRef) {
      this.displayedColumns.unshift('select');
      this.isInDrawer = true;
    }


    this.slug = this.showOnly;
    this.loadRegistrations();
    this.previousSlug = this.slug;

    this.configurationService.getConfiguration().subscribe(datas => {
      this.addressesCategories = datas?.data?.addressesCategories;
      this.category = this.addressesCategories.find((category: any) => category.key === this.slug);
    });
  }



  addContact(id?: any) {

    let width = '900px';
    if(this.isInDrawer) {
      width = '900px';
    }

    if(this.isMobile) {
      width = '95vw';
    }


    let modalRef = this._NzDrawerService.create({
      nzContent: ModalRegistrationFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create registration",
      nzContentParams: {
        searchIs: id,

        contact: this.contact ?? null,
        property: this.property ?? null,
        related: this.agency ?? null,
        locations: this.locations,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        registrations_types: this.registrations_types,
        registrationStatus: this.registrationStatus,
        priorities: this.priorities,

        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      if (closedId) {
        this.loadRegistrations();
      }
    });
  }

  currentPage = 1;
  pageSize = 10;
  totalcontacts = 0;
  onPageChanged(page: number): void {
    this.currentPage = page;
    this.loadRegistrations();
  }

  calculateTotalPages(): number {
    return Math.ceil(this.totalcontacts / this.pageSize);
  }

  loadRegistrations(): void {

    this.isLoadingResults = true;
    this.RegistrationsService.list(
      this.currentPage,
      this.pageSize,
      this.slug,
      this.searchTerm,
      this.formGroup.get('status')?.value,
      this.contact?.id || null,
      this.property?.id || null,

    ).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.contacts = response.data?.data;
        this.totalcontacts = response.data?.total;
      },
      (error) => {
        console.error('Error fetching contacts:', error);
      }
    );
  }


  select(id: any) {
    this._NzDrawerRef.close(id);
  }

  searchTerm: string = '';

  onSearchTermChange() {
    // Déclenche le changement après 300 ms
    this.searchTermChanged.next(this.searchTerm);
  }

  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }

  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }

  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }


  onSelectChange(event: any) {

    this.formGroup.get('status')?.setValue(event);
    this.formGroup.get('status')?.updateValueAndValidity();
    this.loadRegistrations();
  }

  openDashboard(id: any) {
    this.router.navigate(['admin', 'contacts', 'detail', id]);
  }

  openDashboardNewTab(id: any) {
    window.open(`/admin/contacts/detail/${id}`, '_blank');

  }
}
