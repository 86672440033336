import {
  Component,
  Input,
  QueryList,
  ViewEncapsulation,
  forwardRef,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {AngularEditorConfig} from "@kolkov/angular-editor";
import { Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-editor-widget',
  templateUrl: './editor-widget.component.html',
  styleUrls: ['./editor-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorWidgetComponent),
      multi: true
    }
  ]
})
export class EditorWidgetComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() options: any;
  @Input() formControl: any;
  @Input() locale: any;
  private dynamicStyleElement: HTMLLinkElement | HTMLStyleElement | null = null;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: 'p',
    sanitize: false,
    toolbarPosition: 'top'
  };



  editorContent = '';  // Variable pour stocker le contenu de l'éditeur
  originalContent = ''; // Variable pour stocker le contenu initial de l'éditeur
  showSourceCode = false;  // Variable pour contrôler la visibilité du code source
  editorDisplayStyle = ''; // Variable pour contrôler le style de l'éditeur

  @ViewChild('editor', { static: false }) editor!: ElementRef | undefined;

  constructor(private renderer: Renderer2) {}

  format(command: string): void {
    switch (command) {
      case 'bold':
      case 'italic':
      case 'underline':
        document.execCommand(command, false, '');
        break;
      case 'h1':
      case 'h2':
      case 'h3':
        this.insertHeading(command);
        break;
      default:
        break;
    }
  }

  insertHeading(headingType: string): void {
    const selection = window.getSelection();
    if (selection) {
      const selectedText = selection.toString(); // Conserver le texte sélectionné
      const range = selection.getRangeAt(0);

      const heading = document.createElement(headingType);
      heading.textContent = selectedText; // Réinsérer le texte sélectionné dans l'en-tête

      range.deleteContents();

      // Si le parent de la sélection est un élément en-tête, insérer l'en-tête après celui-ci
      if (selection.anchorNode?.parentElement?.tagName.toLowerCase().match(/^h\d$/)) {
        const parentHeader = selection.anchorNode.parentElement;
        const parentContainer = parentHeader.parentNode;
        parentContainer?.insertBefore(heading, parentHeader.nextSibling); // Insérer le nouvel en-tête après l'en-tête existant
        parentContainer?.removeChild(parentHeader); // Supprimer l'ancien en-tête
      } else { // Sinon, insérer l'en-tête dans le document
        range.insertNode(heading);
      }

      this.updateTextareaContent(); // Mettre à jour le contenu du textarea

    }
  }

  @ViewChildren(EditorWidgetComponent) editorWidgets!: QueryList<EditorWidgetComponent>;

  toggleBulletList(): void {
    document.execCommand('insertUnorderedList', false, ''); // Commande pour insérer une liste à puces
  }

  updateTextareaContent(): void {
    const editorContent = document.querySelector('.editor' + this.locale)?.innerHTML;
    this.formControl.setValue(editorContent || ''); // Mettre à jour la valeur du FormControl
    this.editorContent = editorContent || '';

    // Itérer sur la liste des composants EditorWidgetComponent
    this.editorWidgets.forEach(editorWidget => {
      editorWidget.updateTextareaContent(); // Appeler la méthode updateTextareaContent de chaque composant
    });
  }

  deleteFromDocument(selection: Selection): void {
    selection.deleteFromDocument();
  }

  onEnterKeyPress(event: any): void {
    const selection = window.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const paragraph = document.createElement('p');
      paragraph.innerHTML = '<br>'; // Insérer une balise de saut de ligne pour un contenu vide
      range.deleteContents();
      range.insertNode(paragraph);
      range.setStartAfter(paragraph);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
      event.preventDefault();
    }
  }


  onContentChange(event: Event): void {
    const target = event.target as HTMLElement;
    this.editorContent = target.innerHTML;
    this.onChange(this.editorContent); // Mettre à jour la valeur du formControl
  }

  ngAfterViewInit(): void {
    if (this.editor && this.editor.nativeElement) {
      this.originalContent = this.editor.nativeElement.innerHTML; // Stocker le contenu initial
      this.renderer.listen(this.editor.nativeElement, 'input', (event) => {
        this.onContentChange(event);
      });
    } else {
      console.error('Editor native element is undefined');
    }
  }

  viewSourceCode(): void {
    this.showSourceCode = !this.showSourceCode;
    if (!this.showSourceCode) {
      // Si on sort du mode "View Source Code", on restaure le contenu original
      if (this.editor && this.editor.nativeElement) {
        this.editor.nativeElement.innerHTML = this.originalContent;
        this.editorDisplayStyle = ''; // Afficher l'éditeur
      }
    } else {
      // Si on passe en mode "View Source Code", on stocke le contenu actuel de l'éditeur
      if (this.editor && this.editor.nativeElement) {
        this.originalContent = this.editor.nativeElement.innerHTML;
        this.editorDisplayStyle = 'none'; // Masquer l'éditeur
      }
    }
  }


  insertFirst = '';
  ngOnInit(): void {

    this.insertFirst = this.formControl.value;
    this.editorContent = this.formControl.value;
  }

  // Fonctions de rappel pour le contrôle de la valeur
  onChange: any = () => {};
  onTouched: any = () => {};


  writeValue(value: any): void {
    if (this.editor) {
      this.editor.nativeElement.innerHTML = value;
    }

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Gérer l'état de désactivation si nécessaire
  }

  ngOnDestroy(): void {
    // Supprimez les styles ajoutés dynamiquement lorsque le composant est détruit
    this.removeDynamicStyles();
  }

  private removeDynamicStyles(): void {
    // Recherchez l'élément de style dynamique dans le DOM et supprimez-le s'il existe
    if (this.dynamicStyleElement) {
      this.dynamicStyleElement.remove();
    }
  }
}
