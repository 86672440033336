

<nb-card>
  <nb-card-header>
    <span style="margin-right:10px;">Property</span>

    <button *ngIf="!property" nbButton class="btn-ico-w" size="small" type="button" (click)="linkToContact($event)">
      <nb-icon icon="person-add-outline" style="color:#000!important;"></nb-icon>
      Link to property
    </button>
  </nb-card-header>
  <nb-card-body>


    <nb-card *ngIf="property">
      <nb-card-header>
        Ref : {{property.reference}}
        <nb-tag-list style="float:right">
          <nb-tag status="basic" appearance="filled" text="Pending" *ngIf="property.is_available == 0"></nb-tag>
          <nb-tag status="success" appearance="filled" text="Available" *ngIf="property.is_available == 1"></nb-tag>
          <nb-tag status="danger" appearance="filled" text="Sold" *ngIf="property.is_available == 2"></nb-tag>
          <nb-tag status="basic" appearance="filled" text="Out of market" *ngIf="property.is_available == 3"></nb-tag>
        </nb-tag-list>

      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-4"><img src="{{property.photo?.full_url}}" width="100%" class="imageCorner"/></div>
          <div class="col-8">

            <div>
              <ng-container *ngIf="property.categories && property.categories.length > 0">
                <strong>Categories:</strong>
            <span *ngFor="let category of property.categories; let last = last">
              {{ category.name }}{{ !last ? ', ' : '' }}
            </span>
              </ng-container>
              <ng-container *ngIf="!property.categories || property.categories.length === 0">No Categories
              </ng-container>
            </div>


            <div>
              <span *ngIf="property.price"><strong>Price: </strong>{{ property.price | currencyFormat }}</span>
            </div>

            <div>
              <span *ngIf="property.bathrooms"><strong>Location:</strong> {{ property.location?.name }}</span>
            </div>


            <div>
              <span *ngIf="property.bathrooms"><strong>Bed.:</strong> {{ property.bathrooms }}</span>
            </div>

            <div>
              <span *ngIf="property.bathrooms"><strong>Bath.:</strong> {{ property.bathrooms }}</span>
            </div>


          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <nb-button-group multiple size="medium" class="btn-grp-action" >
          <button nbButton fullWidth  class="btn-ico-w" size="small" type="button" (click)="editOwner(property.id)">
            <nb-icon icon="edit-outline" style="color:#000!important;"></nb-icon>
            Edit
          </button>
          <button nbButton fullWidth class="danger" class="btn-ico-w" size="small" type="button"  (click)="linkToContact($event)">
            <nb-icon icon="refresh-outline" style="color:#000!important;"></nb-icon>
            Change
          </button>
          <button nbButton fullWidth class="danger" size="small" type="button"  (click)="linkToContact($event)" >
            <nb-icon icon="trash-outline" style="color:#ffffff!important;"></nb-icon>
          </button>
        </nb-button-group>
      </nb-card-footer>
    </nb-card>


    <div *ngIf="!property" class="empty empty-td text-center">
      No property
    </div>
  </nb-card-body>

</nb-card>


