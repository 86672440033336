
<mat-form-field class="full-width-select" *ngIf="!isKeyValue">
  <mat-label>{{label}} <small *ngIf="isRequiredControl">*</small></mat-label>
  <mat-select [(ngModel)]="inputValue" (selectionChange)="onInputChange()">
    <mat-option *ngFor="let option of options" [value]="option.id || option.value" [style.border-left]="option.color ? 'solid 5px ' + option.color : ''">
      <span></span> {{option.name}}
    </mat-option>
  </mat-select>
</mat-form-field>



<mat-form-field class="full-width-select" *ngIf="isKeyValue && !isString && options && options.length > 0">
  <mat-label>{{label}} <small *ngIf="isRequiredControl">*</small></mat-label>
  <mat-select [(ngModel)]="inputValue" (selectionChange)="onInputChange()">
    <mat-option *ngFor="let option of options[0] | keyvalue" [value]="convertToNumber(option.key)">
      {{option.value}}
    </mat-option>
  </mat-select>
</mat-form-field>




<mat-form-field class="full-width-select" *ngIf="isKeyValue && isString && options && options.length > 0">
  <mat-label>{{label}} <small *ngIf="isRequiredControl">*</small></mat-label>
  <mat-select [(ngModel)]="inputValue" (selectionChange)="onInputChange()">
    <mat-option *ngFor="let option of options[0] | keyvalue" [value]="option.key">
      {{option.value}}
    </mat-option>
  </mat-select>
</mat-form-field>
