import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {PropertiesService} from "../../services/properties.service";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";
@Component({
  selector: 'app-three-d-form',
  templateUrl: './three-d-form.component.html',
  styleUrls: ['./three-d-form.component.scss']
})
export class ThreeDFormComponent   implements OnInit{
  @Input() propertyId: any;
  @Input() video: any;
  formGroup!: FormGroup;
  isLoaded:boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private propertiesService: PropertiesService,
    private drawer: NzDrawerRef,
    private toastrService: NbToastrService,
    private toastService: ToastService,

  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      url: ['', Validators.required],
      description: ['']
    });

    if(this.video) {
      this.formGroup.get('title')?.setValue(this.video?.title);
      this.formGroup.get('url')?.setValue(this.video?.src);
      this.formGroup.get('description')?.setValue(this.video?.description);
    }


  }

  onSubmit() {
    const formValue = this.formGroup.getRawValue();
    if (this.video?.id && this.video?.id) {
      formValue.id = this.video?.id;
    }

    this.propertiesService.addVideo(formValue, this.propertyId).subscribe(
      response => {

        this.drawer.close(response.data);
      },
      error => {
        if (error.error && error.error.data) {
          // Parcourir les erreurs dans la propriété 'data'
          Object.keys(error.error.data).forEach(key => {
            const errorMessages: string[] = error.error.data[key];
            errorMessages.forEach((errorMessage: string) => {
              this.toastService.showToast(`${key}: ${errorMessage}`, 'Error!', 'danger');
            });
          });
        } else {
          this.toastService.showToast(`Error 57896`, 'Error!', 'danger');
        }
      }
    );
  }

  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }

  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }

  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }
}
