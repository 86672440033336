<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">
    <nb-action>
      <h1 class="title-page">Before / After</h1>
    </nb-action>
  </nb-actions>

  <nb-actions size="small" style="margin-left: auto">

    <nb-action class="action-right">
      <button nbButton status="primary" class="btn-ico-w" (click)="addPage(null)">
        <nb-icon icon="plus-outline"></nb-icon>
        Add new work
      </button>
    </nb-action>
  </nb-actions>

</nb-layout-header>


<div class="p-20">

  <nz-breadcrumb class="breadcrumbs">
    <nz-breadcrumb-item>
      <a [routerLink]="['/admin/']">Dashboard</a>
    </nz-breadcrumb-item>


    <nz-breadcrumb-item>
      <a [routerLink]="['/admin/sliders']">Sliders</a>
    </nz-breadcrumb-item>



  </nz-breadcrumb>


  <section class="table-container">

    <table class="table" *ngFor="let item of pages;" >

      <thead>
      <tr>
        <th>{{ item.page.title}}</th>
        <th class="text-end">Action</th>
      </tr>
      </thead>

      <tbody >
      <ng-container *ngFor="let afterBefore of item.page.afterBefore; let i = index">
        <tr   >
          <td>
            <span class="text-dark" style="text-decoration: none; display: flex; align-items: center; justify-content: space-between;">
              <div style="display: flex; align-items: center; flex: 1;">
                <div style="flex: 1;">
                  <i style="font-size:0.9rem; margin:0 0 0 5px; padding:0; line-height:1rem;font-weight:600;">{{ afterBefore.title }}</i>
                </div>
              </div>

            </span>

          </td>
          <td class="text-end">
            <button nbButton size="small" (click)="addPage(afterBefore.id)" style="margin-right:5px;">
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </section>

</div>
