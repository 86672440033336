import {ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import {FormGroup, FormControl, AbstractControl, Validators} from "@angular/forms";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {ContactsComponent} from "../../../contacts/pages/contacts/contacts.component";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {NbSidebarService, NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";
import {ModalFormContactComponent} from "../../../contacts/components/modal-form-contact/modal-form-contact.component";
import {ClientSearchService} from "../../services/clientSearch.service";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {Share} from "../../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-modal-form-search',
  templateUrl: './modal-form-search.component.html',
  styleUrls: ['./modal-form-search.component.scss']
})
export class ModalFormSearchComponent extends Share implements OnInit{

  @Input() formGroup!: FormGroup;
  @Input() isLoading: boolean = false;
  @Input() searchIs: any;
  @Input() contact: any;
  @Input() categories: any;
  @Input() locales!: string[];
  @Input() featureGroups: any;
  @Input() locations: any;
  @Input() videos: any;
  @Input() properties_status: any;
  @Input() owner_id: any;


  @Input() addressesCategories: any;
  @Input() genders: any;
  @Input() priorities: any;

  @Input() PHONE_TYPES: any;
  @Input() EMAIL_TYPES: any;
  @Input() SOCIALS: any;

  public datas!:any;

  constructor(
    private drawer: NzDrawerRef,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public drawerRef: NzDrawerRef,
    private toastrService: NbToastrService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private clientSearchService: ClientSearchService,
    private configurationService: GetConfigurationService,
    deviceService: DeviceDetectorService,
    public sidebarService: NbSidebarService
  ) {
    super('Child', deviceService);
  }



  ngOnInit(): void {

    if(this.formGroup) {
      this.formGroup.addControl('comment', new FormControl(''));
      this.formGroup.addControl('address_id', new FormControl(''));
      this.formGroup.get('address_id')?.setValue(this.contact?.id);
    }

    if(this.searchIs) {
      this.getDetail();
    }

  }


  openOwners() {

    let width = '80vw';
    if(this.isMobile) {
      width = '95vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ContactsComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Clients",

      nzContentParams: {
        showOnly: 'clients',
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,
        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((contact:any) => {
      if(contact) {
        this.formGroup?.get('owner_id')?.setValue(contact?.id);
        this.contact.owner = contact;
        this.cdr.detectChanges();
        this.toastService.showToast('Owner linked successfully', 'Success!', 'success');
      }
    });
  }


  unlinkOwners() {
    let width = '80vw';
    if(this.isMobile) {
      width = '80px';
    }

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: width,
      data: {
        title: 'Unlink the owner ?',
        text: 'Are you sure you want to unlink <strong>' + this.contact?.owner?.full_name + '</strong> as the owner of this property?',
        class: 'warning',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info:any) => {
      if(info) {
        this.toastService.showToast(this.contact?.owner?.full_name + ' unlinked successfully', 'Success!', 'success');

        this.contact.owner = null;

        this.formGroup.get('owner_id')?.setValue(null);
        this.formGroup.updateValueAndValidity()
        this.cdr.detectChanges();
      }
    });

    return dialogRef;



  }


  editOwner(id: any) {
    let width = '800px';
    if(this.isMobile) {
      width = '80px';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: ModalFormContactComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Contact",
      nzContentParams: {
        contactId: id,
        addressesCategories: this.addressesCategories,
        genders: this.genders,
        priorities: this.priorities,


        PHONE_TYPES: this.PHONE_TYPES,
        SOCIALS: this.SOCIALS,
        EMAIL_TYPES: this.EMAIL_TYPES,
      }
    });

    modalRef.afterClose.subscribe((closedId: any) => {
      if (closedId) {
        this.cdr.detectChanges();
      }
    });
  }


  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }



  submitForm(message:any) {
    this.markFormGroupTouched(this.formGroup);

    const formData = this.formGroup.value;

    this.clientSearchService.createOrUpdate(formData).subscribe(
      (response) => {
        if(message) {

          this.toastService.showToast('Search registered successfully', 'Success!', 'success');
          this.drawerRef.close(response?.data);
        }
      },
      (error) => {
        console.error('Erreur lors de la création de la propriété', error);
      }
    );

  }


  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }


  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }


  onFormControlChangeTranslations(controlName: string, newValue: any | null): void {
    this.updateFormControlValueTranslation(controlName, newValue);
  }
  updateFormControlValueTranslation(controlName: string, newValue: any | null): void {
    const control = this.formGroup?.get('translations.' + controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }


  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }


  getDetail() {


    this.formGroup = new FormGroup({
      show_inactive: new FormControl(false),
      reference: new FormControl(''),
      id: new FormControl(''),
      address_id: new FormControl(''),

      categories: new FormControl(''),
      location: new FormControl(''),
      area: new FormControl(''),


      min_bedrooms: new FormControl('', [Validators.pattern(/^\d*$/)]),
      max_bedrooms: new FormControl('', [Validators.pattern(/^\d*$/)]),

      min_bathrooms: new FormControl('', [Validators.pattern(/^\d*$/)]),
      max_bathrooms: new FormControl('', [Validators.pattern(/^\d*$/)]),


      min_size: new FormControl('', [Validators.pattern(/^\d*$/)]),
      max_size: new FormControl('', [Validators.pattern(/^\d*$/)]),

      min_lot_size: new FormControl('', [Validators.pattern(/^\d*$/)]),
      max_lot_size: new FormControl('', [Validators.pattern(/^\d*$/)]),

      min_price: new FormControl('', [Validators.pattern(/^\d*$/)]),
      max_price: new FormControl('', [Validators.pattern(/^\d*$/)]),

      comment: new FormControl(''),

    });

    this.isLoading = true;
    this.clientSearchService.getDetail(this.searchIs).subscribe(
      (response) => {
        this.isLoading = false;
        this.datas = response?.data;
        this.contact = response?.data?.address;

        this.formGroup.patchValue({
          'id' : this.datas?.id,
          'address_id' : this.datas?.address_id,
          'reference' : this.datas?.search_criteria?.reference,
          'min_bedrooms' : this.datas?.search_criteria?.min_bedrooms,
          'max_bedrooms' : this.datas?.search_criteria?.max_bedrooms,
          'min_bathrooms' : this.datas?.search_criteria?.min_bathrooms,
          'max_bathrooms' : this.datas?.search_criteria?.max_bathrooms,
          'min_size' : this.datas?.search_criteria?.min_size,
          'max_size' : this.datas?.search_criteria?.max_size,
          'min_lot_size' : this.datas?.search_criteria?.min_lot_size,
          'max_lot_size' : this.datas?.search_criteria?.max_lot_size,
          'min_price' : this.datas?.search_criteria?.min_price,
          'max_price' : this.datas?.search_criteria?.max_price,
          'comment' : this.datas?.description,
          'categories' : this.datas?.search_criteria?.categories,
          'location' : this.datas?.search_criteria?.location,
          'area' : this.datas?.search_criteria?.area,
        });

      },
      (error) => {
        console.error('Erreur lors de la création de la propriété', error);
      }
    );

  }

  cancel() {

    let width = '500px';
    if(this.isMobile) {
      width = '60px';
    }

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Would you like to delete this?',
        text: 'This action is irreversible and will permanently.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.clientSearchService.delete(this.formGroup?.get('id')?.value).subscribe(
          (response) => {
            this.drawerRef.close(true);
          }
        );
      }
    });
  }
}
