<div  *ngIf="isLoaded">
  <ng-container *ngIf="isFormControl(control)">
    <div class="mb-4">
      <label class="mb-2" [for]="name" *ngIf="label">{{ label }} <small *ngIf="isRequiredControl">*</small></label>

      <textarea
        style="min-height:500px;"
        class="form-control"
        *ngIf="control"
        [ngClass]="{'is-invalid': (!control?.valid && control?.dirty)}"
        [formControl]="control"
        [required]="isRequiredControl"
        (input)="onInputChange()"
        autoresize
      ></textarea>

      </div>
  </ng-container>
</div>

