import { Injectable } from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from "../../../services/auth/auth.service";
import {TreeNode} from "../interfaces/pagesDatas";

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {
  private baseUrl!: string;
  private apiUrl!: string;
  private publicApiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService // Inject the authentication service
  ) {
    // Get the selected domain from the authentication service
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/widgets`;
    } else {
      console.error('Selected domain is not set.');
    }
  }


  create(widgetData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/create`, widgetData);
  }


  update(widgetData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/update/` + widgetData?.id, widgetData);
  }


  updateRank(pageId: any, widgetData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/updateRank/` + pageId, {'widgetRanks' : widgetData });
  }

  delete(widgetId: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/deleteWidget/` + widgetId, {});
  }

  uploadFile(formData: FormData, id:any): Observable<any> {
    // Créez une requête HTTP pour l'envoi du formulaire contenant le fichier
    const uploadReq = new HttpRequest('POST', `${this.apiUrl}/upload/` + id , formData, {
      reportProgress: true, // Activer le suivi de la progression de l'envoi
    });

    // Utilisez la méthode request de HttpClient pour envoyer la requête
    return this.http.request(uploadReq);
  }



  // Method to update page positions
  translate(pageData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/translate`, pageData);
  }


}
