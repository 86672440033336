import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
  selector: 'app-editor-translatable',
  templateUrl: './editor-translatable.component.html',
  styleUrls: ['./editor-translatable.component.scss']
})
export class EditorTranslatableComponent implements OnInit, AfterViewInit{

  private _control: AbstractControl<any, any> | null | undefined;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() isLoaded: boolean = false;
  @Input() type: string = '';
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() locale!: any;

  constructor(private element: ElementRef) {}

  @Input()
  set control(value: AbstractControl<any, any> | null | undefined) {
    this._control = value;
    this.onInputChange(); // Appel de la méthode lors du changement du contrôle
  }

  get control(): AbstractControl<any, any> | null | undefined {
    return this._control;
  }

  isFormControl(control: AbstractControl<any, any> | null | undefined): control is FormControl {
    return control instanceof FormControl;
  }

  get isRequiredControl(): boolean {
    return !!this.control &&
      !!this.control.validator &&
      !!this.control.validator({} as FormControl)?.['required'];
  }

  onInputChange(): void {
    if (this.control instanceof FormControl) {
      let text = this.control.value;
      text = text.replace(/<br\s*[\/]?>/gi, '\n'); // Remplace <br> par des sauts de ligne
      this.control.setValue(text); // Mettre à jour la valeur du contrôle
      this.control.markAsDirty();
      this.control.markAsTouched();
      this.valueChange.emit(this.control.value);
    }
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustTextareaHeight(this.element.nativeElement);
    }, 500);
  }

  ngOnInit(): void {
    this.adjustTextareaHeight(this.element.nativeElement);
  }

  @HostListener('input', ['$event.target'])
  onInput(textarea: HTMLTextAreaElement): void {
    this.adjustTextareaHeight(textarea);
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }



  @HostListener('click', ['$event.target'])
  onClick(textarea: HTMLTextAreaElement): void {
    this.adjustTextareaHeight(textarea);
  }
}
