<form [formGroup]="pageFormGroup" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
  <nb-card>
    <nb-card-body>


      <div class="row">
        <div class="col-12 col-md-12">

          <div class="mb-3" style="margin-top: 20px;">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="key" id="key" placeholder="Key">
              <label for="key">Key*</label>
            </div>
          </div>


          <div class="mb-3" style="margin-top: 20px;">
            <div class="form-check">
              <input (change)="onTranslationChange($event)" class="form-check-input" type="checkbox"
                     formControlName="hasTranslation" id="hasTranslation">
              <label for="hasTranslation" class="form-check-label">Is translatable</label>
            </div>
          </div>


          <div class="mb-3" style="margin-top: 20px;" *ngIf="!pageFormGroup?.get('hasTranslation')?.value">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="uniqueValue" id="uniqueValue"
                     placeholder="Value">
              <label for="key">Value*</label>
            </div>
          </div>

          <nb-tabset *ngIf="pageFormGroup?.get('hasTranslation')?.value">
            <nb-tab *ngFor="let locale of locales; let i = index" tabTitle="{{ locale }}" tabId="{{ locale }}"
                    style="padding:20px 0 20px 0!important;">
              <ng-template nbTabTitle>
                <img src="/assets/images/flags/{{ locale }}.svg" width="24"/>
                <nb-icon *ngIf="!isFormValidForLocale(getTitleFormGroup(i))"
                         [icon]="isFormValidForLocale(getTitleFormGroup(i)) ? 'checkmark-circle-2-outline' : 'alert-triangle-outline'"
                         [ngClass]="{'red-color': !isFormValidForLocale(getTitleFormGroup(i)), 'green-color': isFormValidForLocale(getTitleFormGroup(i))}">
                </nb-icon>
              </ng-template>


              <nb-card [formGroup]="getTitleFormGroup(i)">
                <nb-card-body>
                  <nb-tabset>
                    <nb-tab style="padding:0!important;">
                      <ng-template nbTabTitle>
                        Setting translations
                      </ng-template>


                      <div>

                        <div class="mb-3" style="margin-top:30px;">
                          <label for="{{ locale + '.value' }}">Value</label>
                          <textarea rows="1" #contentTextarea class="form-control auto-resize"
                                    id="{{ locale + '.value' }}"
                                    [formControl]="getTitleFormControl(locale, 'value')"
                                    (input)="adjustTextareaHeight(contentTextarea)"
                                    placeholder="Value"></textarea>

                        </div>


                      </div>


                    </nb-tab>


                  </nb-tabset>


                </nb-card-body>


              </nb-card>

            </nb-tab>
          </nb-tabset>


          <nb-card>
            <nb-card-footer>

              <div class="row">

                <div class="col-6">
                  <button nbButton status="danger" class="btn-ico-w" type="button" (click)="delete()" *ngIf="setting?.id && !setting?.isSystem">
                    <nb-icon icon="trash-2-outline"></nb-icon>
                    <span>Delete</span>
                  </button>
                </div>
                <div class="col-6" style="text-align:right">

                  <button nbButton status="primary" class="btn-ico-w" [disabled]="!pageFormGroup.valid">
                    <nb-icon icon="save-outline"></nb-icon>
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </nb-card-footer>
          </nb-card>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</form>
