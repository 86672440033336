<form [formGroup]="pageFormGroup" (ngSubmit)="onSubmit()" *ngIf="isLoaded" enctype="multipart/form-data">
  <nb-card>
    <nb-card-body>





      <div class="row">
        <div class="col-12 col-md-8">

          <div> <!-- Vérifiez si la case à cocher n'est pas cochée -->
            <select class="form-select mb-3" formControlName="link_to"  [(ngModel)]="selectedParentId">
              <option value="">Option empty</option>
              <option *ngFor="let option of dropdownOptions" [value]="option.id">{{ option.title }}</option>
            </select>
          </div>

          <nb-tabset (changeTab)="selectTab($event)">
            <nb-tab *ngFor="let locale of locales; let i = index" tabTitle="{{ locale }}" tabId="{{ locale }}"
                    style="padding:20px 0 20px 0!important;">
              <ng-template nbTabTitle>
                <img src="/assets/images/flags/{{ locale }}.svg" width="24"/>
                <nb-icon *ngIf="!isFormValidForLocale(getTitleFormGroup(i))"
                         [icon]="isFormValidForLocale(getTitleFormGroup(i)) ? 'checkmark-circle-2-outline' : 'alert-triangle-outline'"
                         [ngClass]="{'red-color': !isFormValidForLocale(getTitleFormGroup(i)), 'green-color': isFormValidForLocale(getTitleFormGroup(i))}">
                </nb-icon>
              </ng-template>


              <nb-card [formGroup]="getTitleFormGroup(i)">
                <nb-card-body>
                  <nb-tabset>
                    <nb-tab style="padding:0!important;">
                      <ng-template nbTabTitle>
                        Main detail
                      </ng-template>


                      <div>

                        <div class="mb-3" style="margin-top: 20px;">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="{{ locale + '.title' }}"
                                   [formControl]="getTitleFormControl(locale, 'title')" placeholder="Title">
                            <label for="{{ locale + '.title' }}">Title*</label>
                          </div>
                        </div>

                        <div class="mb-3">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="{{ locale + '.subTitle' }}"
                                   [formControl]="getTitleFormControl(locale, 'subTitle')" placeholder="Nav title">
                            <label for="{{ locale + '.subTitle' }}">Sub title*</label>
                          </div>
                        </div>




                      </div>


                    </nb-tab>

                  </nb-tabset>


                </nb-card-body>


              </nb-card>

            </nb-tab>
          </nb-tabset>


          <nb-card>
            <nb-card-footer style="text-align:right;">
              <button nbButton status="primary" class="btn-ico-w" [disabled]="!pageFormGroup.valid">
                <nb-icon icon="save-outline"></nb-icon>
                <span>Save</span>
              </button>
            </nb-card-footer>
          </nb-card>
        </div>


        <div class="col-12 col-md-4">


          <nb-card>
            <nb-card-header>
              Images
            </nb-card-header>

            <nb-card-body>
              <div class="row mb-3">
                <div class="col-12 col-md-12">
                  <nb-card class="mb-3">
                    <nb-card-header>
                      Main image
                    </nb-card-header>
                    <nb-card-body class="p-0">
                      <div class="upload-container">
                        <input type="file" class="fileInput" id="fileInput" (change)="handleFileInput($event, 'image')">
                        <label for="fileInput" class="upload-label">
                          <img [src]="pageFormGroup?.get('image')?.value || image_url || '/assets/images/image.png'"
                               alt="Upload Image">
                        </label>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </div>

              </div>


            </nb-card-body>
          </nb-card>


        </div>

      </div>


    </nb-card-body>
  </nb-card>
</form>
