import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {PageService} from "../services/pages.service";

import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalFormPropertyComponent} from "../../properties/components/modal-form-property/modal-form-property.component";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {Share} from "../../../class/share";
import {DeviceDetectorService} from "ngx-device-detector";
import {PagesFormComponent} from "../components/pages-form/pages-form.component";
import {GetConfigurationService} from "../../../services/getConfiguration/get-configuration.service";
import {AuthService} from "../../../services/auth/auth.service"; // Assurez-vous de spécifier le chemin correct vers votre interface TreeNode


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})


export class PagesComponent  extends Share  implements OnInit {
  page!: any;
  pages!: any;
  contentTypes!: any;
  locales!: any;
  widgets!: any;
  template!: any;
  containArticles!: any;
  widgets_classes!: any;
  id: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private pageService: PageService,
    private authService: AuthService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    deviceService: DeviceDetectorService,
  ) {
    super('Child', deviceService);
    this.refreshConfiguration();
  }

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.contentTypes = datas?.data?.contentTypes;
      this.locales = datas?.data?.locales;
      this.widgets = datas?.data?.widgets;
      this.widgets_classes = datas?.data?.widgets_classes;
      this.template = datas?.data?.template;

      this.cdr.detectChanges();
    });
  }



  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.loadPages();

  }

  loadPages() {
    this.pageService.listPages(this.id).subscribe(datas => {
      this.page = datas?.page;
      this.pages = datas?.pages;
    });
  }

  drop(event: CdkDragDrop<any[]>) {

    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);

    console.log(this.pages)

    this.pageService.updateNodes(this.pages).subscribe(
      () => {
        console.log('Nodes updated successfully.');
      },
      (error) => {
        console.error('Error updating nodes:', error);
      }
    );


  }


  // Déclarez une propriété pour stocker les éléments étendus
  expandedItems: Set<any> = new Set();

// Méthode pour vérifier si un élément est étendu
  isExpanded(item: any): boolean {
    return this.expandedItems.has(item);
  }



  onNestedItemDropped(event: any): void {
    // Manipuler les données déposées depuis le composant enfant ici
    console.log('Item dropped in nested component:', event);
  }

  addPage(id:any) {


    let width = '85vw'
    if(this.isMobile) {
      width = '100vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: PagesFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: "Create a new page",
      nzContentParams: {

        contentTypes:this.contentTypes,
        locales : this.locales,
        widgets_classes : this.widgets_classes,
        parent : this.id,
        pageId : id,
        widgets : this.widgets,
        template : this.template,
        isArticle : this.page?.containArticles,

      }
    });

    modalRef.afterClose.subscribe((property: any) => {
      this.loadPages();
    });
  }


  navigateToPage(pageId: string) {
    const url = `/admin/pages/${pageId}`;

    this.router.navigateByUrl('/admin/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(url);
    });
    return false;
  }
}
