import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from "@angular/forms";
import { Editor, Toolbar } from 'ngx-editor';
import { Subscription } from 'rxjs';
import { toHTML } from 'ngx-editor';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html'
})
export class HtmlEditorComponent implements OnInit, OnDestroy {
  editor!: Editor;
  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear'],
    ['code'],
  ];
  colorPresets = ['red', '#FF0000', 'rgb(255, 0, 0)'];

  editorContent = '';  // Variable pour stocker le contenu de l'éditeur

  format(command: string): void {
    document.execCommand(command, false, '');
  }

  onContentChange(event: Event): void {
    const target = event.target as HTMLElement;
    this.editorContent = target.innerHTML;
  }

  @Input() control: AbstractControl | null = null;
  @Input() name: string = '';
  @Input() help: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  inputValue: string = '';
  originalValue: string = '';

  private controlSubscription: Subscription | undefined;

  onInputChange() {
    if (this.control instanceof FormControl) {
      if (this.inputValue == '<p></p>') {
        this.inputValue = '';
      }


      this.control.setValue(this.inputValue);
      this.control.updateValueAndValidity();
      this.control.markAsDirty();
      this.control.markAsTouched();


      this.valueChange.emit(this.control.value);
    }

    this.shouldShowError();
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.originalValue = this.inputValue;

    if (this.control instanceof FormControl) {
      this.inputValue = this.control.value;
      this.controlSubscription = this.control.valueChanges.subscribe(newValue => {
        this.inputValue = newValue;
      });
    }
  }

  shouldShowError(): boolean {
    return !!(
      this.control &&
      this.control.invalid &&
      this.inputValue !== this.originalValue
    );
  }

  getErrorMessage(): string {
    if (this.control && this.control.errors) {
      if (this.control.errors['required']) {
        return 'This field is required.';
      } else if (this.control.errors['minlength']) {
        return `Minimum length is ${this.control.errors['minlength'].requiredLength} characters.`;
      }
    }
    return 'Invalid input.';
  }

  get isRequiredControl(): boolean {
    return !!this.control &&
      !!this.control.validator &&
      !!this.control.validator({} as FormControl)?.['required'];
  }

  ngOnDestroy(): void {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
    this.editor.destroy();
  }

  autoGrowTextZone(e:any) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 25)+"px";
  }
}
