import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from "rxjs/operators";
import {throwError} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class ContactRequestsService {
  private baseUrl!: string;
  private apiUrl!: string;
  private publicApiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.baseUrl = `${selectedDomain.domainName}/api`;
      this.apiUrl = `${this.baseUrl}/contact_requests`;
    } else {
      console.error('Selected domain is not set.');
    }
  }

  getProperties(page: number, pageSize: number): Observable<any> {

    return this.http.post(this.apiUrl + '?page=' + page.toString() + '&pageSize=' + pageSize.toString(), {  });
  }


}
