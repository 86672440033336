<form [formGroup]="titleForm" (ngSubmit)="onSubmit()">

  <nb-card>
    <nb-card-body>
      <div class="input-group  has-validation mb-3" *ngFor="let locale of locales">
    <span class="input-group-text is-invalid text-center" style="min-width:50px!important;">
      <img src="/assets/images/flags/{{locale}}.svg" width="30"/>
    </span>
        <div class="form-floating is-invalid">
          <input type="text" class="form-control" id="floatingInputGroup1" [formControlName]="'title_' + locale"
                 placeholder="Title">
          <label for="floatingInputGroup1">Title</label>
        </div>

        <div class="invalid-feedback"
             *ngIf="titleForm.get('title_' + locale)?.invalid && titleForm.get('title_' + locale)?.touched">
          Field required
        </div>
      </div>

    </nb-card-body>

    <nb-card-footer  style="text-align:right;">
      <button *ngIf="group?.id" style="float:left;" nbButton status="danger" size="small" class="btn-ico-w" (click)="confirmDeleteFeature()" type="button" >
        <nb-icon icon="trash-2-outline"></nb-icon>
        <span >Delete</span>
      </button>

      <button nbButton status="primary" size="small" class="btn-ico-w" [disabled]="!titleForm.valid">
        <nb-icon icon="save-outline"></nb-icon>
        <span *ngIf="!group?.id">Add group</span>
        <span *ngIf="group?.id">Update group</span>
      </button>
    </nb-card-footer>
  </nb-card>
</form>
