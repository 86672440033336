import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from "../auth/auth.service";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GetConfigurationService {
  private apiUrl = ''; // URL dynamique basée sur le domaine sélectionné
  private cachedData: any = null; // Variable pour stocker les données en cache

  constructor(
    private http: HttpClient,
    private authService: AuthService

  ) {
    // Récupérez le domaine sélectionné lors de l'initialisation du service
    const selectedDomain = this.authService.getSelectedDomain();

    console.log({selectedDomain})

    // Vérifiez si le domaine sélectionné existe et contient un domaine
    if (selectedDomain && selectedDomain.domainName) {
      // Construisez l'URL avec le domaine sélectionné
      this.apiUrl = `${selectedDomain.domainName}/api/getConfiguration`;
    } else {
      console.error('Selected domain is not set.');
    }
  }

  private loadData() {
    return this.http.get(this.apiUrl).pipe(
      catchError(error => {
        console.error('Error loading configuration:', error);
        return [];
      })
    );
  }


  getConfiguration(): Observable<any> {
    if (this.cachedData) {
      // Si les données sont en cache, renvoyez-les directement sous forme d'observable
      return new Observable(observer => {
        observer.next(this.cachedData);
        observer.complete();
      });
    } else {
      // Si les données ne sont pas en cache, chargez-les depuis l'API
      return this.loadData().pipe(
        map((data:any) => {
          // Mettre en cache les données chargées
          this.cachedData = data;
          return data;
        })
      );
    }
  }

  // Méthode pour vider le cache si nécessaire
  clearCache() {
    this.cachedData = null;
  }

  updatePositionsLocations(positions: any[]): Observable<any> {
    const apiUrl = `${this.apiUrl}/settings/locations/updateRank`;

    return this.http.post<any>(apiUrl, { positions });
  }
}
