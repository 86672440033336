import {Component, ElementRef, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

import {Observable} from "rxjs";


import {map, startWith} from 'rxjs/operators';


export interface User {
  name: string;
  id: string;
}

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html'
})

export class InputAutocompleteComponent implements OnChanges {
  @Input() control: AbstractControl | null = null;

  @Input() options!: User[];

  @Input() name: string = '';
  @Input() help: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() after: string = '';
  @Input() icon: string = '';
  @Input() type: string = '';
  @Output() valueChange: EventEmitter<any[]> = new EventEmitter<any[]>(); // Émettre un tableau de chaînes

  myControl = new FormControl();
  isOptionSelected: boolean = false;
  filteredOptions!: Observable<User[]>;
  isLoaded = false;


  ngOnChanges(): void {
    if (this.options) {
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map(value => (typeof value === "string" ? value : value.name)),
        map(name => (name ? this._filter(name) : this.options.slice()))
      );

      setTimeout(() => {
        const selectedOption = this.options.find(option => option.id === this.control?.value);
        if (selectedOption) {
          // Check if the value has changed before setting it
          if (this.myControl.value !== selectedOption) {
            this.myControl.setValue(selectedOption);
            this.myControl.markAsDirty();
            this.myControl.markAsTouched();
            this.isLoaded = true;
          }
        }
      }, 500);
    }
  }



  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map(value => (typeof value === "string" ? value : value.name)),
      map(name => (name ? this._filter(name) : this.options.slice()))
    );

    this.myControl.valueChanges.subscribe(newValue => {
      if (this.control instanceof FormControl) {
        this.control.setValue(newValue?.id);
        this.control.markAsDirty();
        this.control.markAsTouched();
        this.valueChange.emit(this.control.value);
      }
    });


  }

  clearValue(): void {
    this.isOptionSelected = false;
    this.myControl.setValue('');
  }


  displayFn(user?: User): string {
    return user ? user.name : '';
  }


  returnFn(user?: User): string | undefined {
    return user ? user.id : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(
      option => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getErrorMessage(): string {
    if (this.control && this.control.errors) {
      if (this.control.errors['required']) {
        return 'This field is required.';
      } else if (this.control.errors['minlength']) {
        return `Minimum length is ${this.control.errors['minlength'].requiredLength} characters.`;
      }
    }
    return 'Invalid input.';
  }

  get isRequiredControl(): boolean {
    return !!this.control &&
      !!this.control.validator &&
      !!this.control.validator({} as FormControl)?.['required'];

  }

}
